import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { QuoteButtonContainer } from './QuoteButton.style';
import TargetedQuoteButton from './TargetedQuoteButton';
import GeneralQuoteButton from './GeneralQuoteButton';
import { addQuinStreetScript } from '../../utils/quoteutils';

/**
 * QuoteButton component.
 *
 * This component would show a quote button container using the display text defined
 * and validating the company attribute to execute the Media Alpha targeted or general quote flow.
 */
const QuoteButton = ({ type, quoteType, displayText, company }) => {
  const [error, setError] = useState(false);
  const [show, setShowModal] = useState(false);
  const quoteLink = useRef();

  /*
    Loads companies information.
    Loads Media Alpha script to execute integration.
  */
  useEffect(() => {
    /** MediaAlpha */

    // eslint-disable-next-line no-undef
    const script = document.createElement('script');
    script.src = 'https://res.cloudinary.com/moneygeek/raw/upload/v1605630850/libraries/serve_p4mqe6.js';
    script.crossOrigin = 'anonymous';
    script.async = true;
    // eslint-disable-next-line no-undef
    document.head.appendChild(script);

    /** QuinStreet */
    addQuinStreetScript();
  }, []);

  // Validates if any information is loaded from API.
  if (company && company.company) {
    return (
      <TargetedQuoteButton
        type={type}
        quoteType={quoteType}
        reference={quoteLink}
        buttonText={displayText}
        companyData={company}
        show={show}
        setShowModal={setShowModal} />
    );
  } else if (!company) {
    return (
      <GeneralQuoteButton
        type={type}
        quoteType={quoteType}
        buttonText={displayText}
        error={error}
        setError={setError}
        show={show}
        setShowModal={setShowModal} />
    );
  } else {
    return (<QuoteButtonContainer />);
  }
};

QuoteButton.propTypes = {

  /**
   * Insurance type to enable MediaAlpha integration.
   */
  type: PropTypes.oneOf(['auto', 'home', 'renters']),

  /**
   * Quote type for Google Analytics event.
   */
  quoteType: PropTypes.string,

  /**
   * Display text for button content.
   */
  displayText: PropTypes.string,

  /**
   * Company information to execute Media Alpha targeted quote flow.
   */
  company: PropTypes.object
};

QuoteButton.defaultProps = {
  type: 'auto',
  displayText: 'get online quote'
};

export default QuoteButton;
