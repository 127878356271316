import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { BestRankingContainer, SingleRankingContainer } from './BestRanking.style';
import BestRankingTitle from './BestRankingTitle';
import BestRankingCompany from './BestRankingCompany';
import Spacing from '../Spacing/Spacing';
import { apiRequest } from '../../utils/utils';

const transformCustomData = (data) => (
  data.map(item => ({
    company: {
      name: item.company,
      url: {
        primary: item.url
      }
    },
    amBest: {
      stability: item.stability
    },
    jdPower: {
      claims: item.claims,
      satisfaction: item.satisfaction,
      affordability: item.affordability
    },
    moneyGeek: {
      score: item.score
    }
  }))
);

const getDisplayArray = (data, collapse) => (
  { ...new Array(data.length).fill(collapse === true ? 'none' : 'flex') }
);

const getCompaniesRanking = (data, maxItems, display, handleClick) => (
  data.sort((a, b) => b.moneyGeek.score - a.moneyGeek.score).slice(0, maxItems).map((row, index) =>
    <SingleRankingContainer key={index}>
      <BestRankingTitle
        companyPosition={index + 1}
        companyName={row.company.name}
        companyScore={parseFloat(row.moneyGeek.score)}
        index={index}
        displayStatus={display[index] ? display[index] : 'none'}
        click={handleClick}
      />
      <BestRankingCompany
        userSatisfaction={parseInt(row.jdPower.satisfaction, 10)}
        claimsRatings={parseInt(row.jdPower.claims, 10)}
        affordability={parseInt(row.jdPower.affordability, 10)}
        financialStability={row.amBest.stability}
        displayStatus={display[index] ? display[index] : 'none'}
        companyData={{ company: row.company.name, insuranceUrl: row.company.url && row.company.url.primary }}
      />
    </SingleRankingContainer>
  )
);

/**
 * BestRanking component.
 *
 * This component would show a best ranking container using a dataset loaded from a spreadsheet.
 */
const BestRanking = ({ state, year, data, collapsed, maxItems, margin }) => {
  const [rankingData, setRankingData] = useState(data ? transformCustomData(data) : []);
  const [collapse, setCollapse] = useState(collapsed);
  const [display, setDisplay] = useState({});
  const requestParams = {
    year: year,
    type: 'auto-insurance-state-other'
  };

  /*
    Loads information from the spreadsheet and sets the state.
    Loads Media Alpha script to execute integration.
  */
  useEffect(() => {
    // eslint-disable-next-line no-undef
    const script = document.createElement('script');
    script.src = 'https://res.cloudinary.com/moneygeek/raw/upload/v1605630850/libraries/serve_p4mqe6.js';
    script.crossOrigin = 'anonymous';
    script.async = true;
    // eslint-disable-next-line no-undef
    document.head.appendChild(script);
    if (!data) {
      axios(apiRequest('get', `autostates/${state}`, requestParams)).then(request => {
        setRankingData(request.data.ratings);

        /*
          Creates an object that controls the collapse state for all the containers.
          This object is dinamically created using the sheet data loaded previously.
        */
        setDisplay(getDisplayArray(request.data.ratings, collapse));
      });
    } else {
      setDisplay(getDisplayArray(rankingData, collapse));
    }
  }, []);

  // Validates if any information is loaded from API or custom data parameter.
  if (rankingData.length === 0) {
    return (
      <Spacing margin={margin}>
        <BestRankingContainer>
          <h2>No information available</h2>
        </BestRankingContainer>
      </Spacing>
    );
  }

  const handleCollapseClick = (containerID) => {
    if (containerID === 'All') {
      setDisplay(getDisplayArray(rankingData, !collapse));
      setCollapse(!collapse);
    } else {
      setDisplay(prev => ({
        ...prev,
        [containerID]: prev[containerID] === 'none' ? 'flex' : 'none'
      }));
    }
  };

  return (
    <Spacing margin={margin}>
      <BestRankingContainer>
        <span className="containersCollapse" onClick={() => handleCollapseClick('All')}>
          {collapse === true ? 'Expand All Rankings +' : 'Collapse All Rankings -'}
        </span>
        <br />
        {getCompaniesRanking(rankingData, maxItems, display, handleCollapseClick)}
      </BestRankingContainer>
    </Spacing>
  );
};

BestRanking.propTypes = {

  /**
   * All states available to load component information.
   * Full state names are used in component: Alabama, Alaska, California.
   */
  state: PropTypes.string,

  /**
   * Four digit year to load component information.
   */
  year: PropTypes.string,

  /**
   * Optional data that fills the component when it's used on a Template page. Overrides state and year parameters.
   */
  data: PropTypes.oneOfType([PropTypes.object, PropTypes.arrayOf(PropTypes.object)]),

  /**
   * Initial collapse definition applied to all inner elements.
   */
  collapsed: PropTypes.oneOf([true, false]),

  /**
   * Custom max number of items to show.
   */
  maxItems: PropTypes.number,

  /**
   * Bottom margin space defined for the component (pixels).
   */
  margin: PropTypes.object
};

BestRanking.defaultProps = {
  collapsed: true,
  maxItems: 10
};

export default BestRanking;
