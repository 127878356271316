module.exports = [{
      plugin: require('../../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"extensions":[".md",".mdx"],"remarkPlugins":[[null,{"type":"yaml","marker":"-"}],null],"rehypePlugins":[null,null],"gatsbyRemarkPlugins":[],"defaultLayouts":{"default":"/vercel/path0/node_modules/gatsby-theme-docz/src/base/Layout.js"},"lessBabel":false,"mediaTypes":["text/markdown","text/x-markdown"],"root":"/vercel/path0/.docz"},
    },{
      plugin: require('../../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../node_modules/gatsby-plugin-react-helmet-async/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../node_modules/gatsby-theme-docz/gatsby-browser.js'),
      options: {"plugins":[],"themeConfig":{},"src":"./","gatsbyRoot":null,"themesDir":"src","mdxExtensions":[".md",".mdx"],"docgenConfig":{},"menu":[],"mdPlugins":[],"hastPlugins":[],"ignore":[],"typescript":false,"ts":false,"propsParser":true,"props-parser":true,"debug":false,"native":false,"openBrowser":null,"o":null,"open":null,"open-browser":null,"root":"/vercel/path0/.docz","base":"/","source":"./","gatsby-root":null,"files":["**/*.{md,markdown,mdx}","**/**/*.{md,markdown,mdx}"],"public":"/public","dest":".docz/dist","d":".docz/dist","editBranch":"master","eb":"master","edit-branch":"master","config":"","title":"Money Geek UI Components","description":"A set of base react based components for MoneyGeek","host":"localhost","port":3000,"p":3000,"separator":"-","paths":{"root":"/vercel/path0","templates":"/vercel/path0/node_modules/docz-core/dist/templates","docz":"/vercel/path0/.docz","cache":"/vercel/path0/.docz/.cache","app":"/vercel/path0/.docz/app","appPackageJson":"/vercel/path0/package.json","appTsConfig":"/vercel/path0/tsconfig.json","gatsbyConfig":"/vercel/path0/gatsby-config.js","gatsbyBrowser":"/vercel/path0/gatsby-browser.js","gatsbyNode":"/vercel/path0/gatsby-node.js","gatsbySSR":"/vercel/path0/gatsby-ssr.js","importsJs":"/vercel/path0/.docz/app/imports.js","rootJs":"/vercel/path0/.docz/app/root.jsx","indexJs":"/vercel/path0/.docz/app/index.jsx","indexHtml":"/vercel/path0/.docz/app/index.html","db":"/vercel/path0/.docz/app/db.json"}},
    }]
