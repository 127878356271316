import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { ExpandCollapseContainer, SingleContainer } from './ExpandCollapse.style';
import Icon from '../Icon/Icon';

const handleCollapseClick = (containerID, modifyDisplay) => {
  modifyDisplay(prev => {
    const display = prev.map((item, index) => index === containerID ? item === 'none' ? 'block' : 'none' : item);
    return display;
  });
};

/**
 * ExpandCollapse component.
 *
 * This component would show a container using the children elements that are inside of it.
 * All children elements can be expanded or collapsed when necessary.
 */
const ExpandCollapse = ({ type, collapsed, children }) => {
  const [display, setDisplay] = useState(new Array(children.length).fill(collapsed === true ? 'none' : 'block'));
  if (type === 'regular') {
    return (
      <ExpandCollapseContainer initiallyCollapsed={collapsed}>
        {React.Children.map(children, (child, index) => {
          return (
            <SingleContainer className="container" displayStatus={display[index]}>
              <div className="titleContent" onClick={() => handleCollapseClick(index, setDisplay)}>
                <span>{child.props.title}</span>
                <Icon name="chevron" size="30px" />
              </div>
              <div className="contentRow">
                {child.props.children}
              </div>
            </SingleContainer>);
        })}
      </ExpandCollapseContainer>
    );
  } else if (type === 'Q/A') {
    return (
      <ExpandCollapseContainer initiallyCollapsed={collapsed}>
        {React.Children.map(children, (child, index) => {
          return (
            <SingleContainer className="container" displayStatus={display[index]}
              itemScope itemProp="mainEntity" itemType="https://schema.org/Question">
              <div className="titleContent" onClick={() => handleCollapseClick(index, setDisplay)}>
                <span itemProp="name">{child.props.title}</span>
                <Icon name="chevron" size="30px" />
              </div>
              <div className="contentRow" itemScope itemProp="acceptedAnswer" itemType="https://schema.org/Answer">
                <span itemProp="text">{child.props.children}</span>
              </div>
            </SingleContainer>);
        })}
      </ExpandCollapseContainer>
    );
  } else {
    return <></>;
  }
};

ExpandCollapse.propTypes = {

  /**
   * Initial collapse definition applied to all children elements.
   */
  collapsed: PropTypes.oneOf([true, false]),

  /**
   * Inner element of the expand/collapse container.
   */
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.object), PropTypes.object])
};

ExpandCollapse.defaultProps = {
  type: 'regular',
  collapsed: true
};

export default ExpandCollapse;
