import React from 'react';
import PropTypes from 'prop-types';
import { TitleContainer } from './BestRanking.style';

const BestRankingTitle = ({ companyPosition, companyName, companyScore, index, displayStatus, click }) => (
  <TitleContainer>
    <div className="companyPosition">
      <p>{companyPosition}</p>
    </div>
    <div className="titleContent">
      <div className="contentRow">
        <span className="companyName">{companyName}</span>
        <span className="companyScore">Score: <span>{companyScore}</span></span>
      </div>
      <div className="containerCollapse" onClick={() => click(index)}>
        {(displayStatus === 'none' ? '+' : '-')}
      </div>
    </div>
  </TitleContainer>
);

BestRankingTitle.propTypes = {
  companyPosition: PropTypes.number,
  companyName: PropTypes.string,
  companyScore: PropTypes.number,
  index: PropTypes.number,
  displayStatus: PropTypes.string,
  click: PropTypes.func
};

export default BestRankingTitle;
