import styled from 'styled-components';
import { colors, typography, sizes } from './../../theme_config/theme';

export const CrossLinkingContainer = styled.div`
  color: ${colors.text};
  font-family: ${typography.fontFamilyBase};
  font-size: 17px;
  line-height: 28px;

  h2 {
    font-family: ${typography.fontFamilyHeaderBold};
    font-size: 40px;
    line-height: 52px;
    margin: 0 0 20px;
  }

  div.links {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: -10px;
  }

  a {
    color: ${colors.brandingElements};
    font-weight: bold;
    text-align: center;
    text-decoration: none;
    min-width: 22px;
    margin: 0 14px 10px 0;
    border: 1px solid transparent;
    border-radius: 3px;
    padding: 1px 5px;
  }

  a:hover {
    color: ${colors.brandingElementsHover};
    border-color: ${colors.brandingElementsHover};
  }

  .extendedContent{
    width:100%;
    min-width:700px;
    display: flex;
    justify-content:space-between;
  }
  
  .extendedContent > div{
    display:flex;
    flex-direction:column;
    min-width:235px;
  }

  .extendedContentFirstColumn{
    align-items: flex-start;
    flex:1;
  }

  .extendedContentSecondColumn{
    align-items: center;
    flex: 1.15;
    border-style: solid;
    border-width: 0 1px 0 1px;
    border-color: ${colors.formElementsBorder};
  }

  .extendedContentThirdColumn{
    align-items: flex-end;
    flex:1;
  }

  .BreakLine{
    margin:0px 10px;
  }

  .extendedStateInfo{
    width:215px;
    display: flex;
    flex-direction:column;

    a{
      font-weight: normal;
      font-size: 16px;

      text-align: initial;
      text-decoration: none;
      min-width: 22px;
      margin: 0;
      border: none;
      padding: 0px;
    }

    a:hover {
      color: ${colors.brandingElementsHover};
      border-color: initial;
    }
  }

  .extendedStateInfoContainer{
    display:flex;
    flex-direction:column;
    align-items:flex-start;
    margin-top:10px;
    margin-bottom:10px;
  }

  .extendedStateTitle{
    font-size: 18px !important;
    font-weight:bold !important;
  }

  .no-url {
    pointer-events: none;
  }

  .extendedStateDescription{
    margin-top: 0px;
    color: #000000;
    font-size: 15px;
    line-height:20px;
  }

  .extendedStateTopCities{
    margin-top: 3px;
    font-size: 14px !important;

    /* stylelint-disable-next-line no-descending-specificity */
    a{
      font-size: 14px;
    }
    color: ${colors.formElementsBorder};

    line-height:20px;
  }

  .extendedContentDesktop{
    display:initial;
  }

  .extendedContentTablet{
    display:none;
  }

  .extendedContentMobile{
    display:none;
  }

  @media (max-width: ${sizes.tabletEnd}) {
    h2 {
      font-size: 24px;
      line-height: 30px;
      margin-bottom: 10px;
    }

    div.links {
      margin-bottom: 7px;
    }

    a {
      min-width: unset;
      width: 4%;
      padding: 2px;
      margin: 0 3% 10px 0;
    }

    a:nth-child(13n) {
      margin-right: 0;
    }

    .extendedContent a{
        width: auto;
    }

    .extendedContent > div{
      min-width:225px;
    }

    .extendedContentFirstColumn{
      flex:0.9;
    }

    .extendedContentSecondColumn,
    .extendedContentThirdColumn{
      align-items: center;
      flex:1;
    }
  }

  @media (max-width: ${sizes.palmEnd}) {
    div.links {
      margin-bottom: 5px;
    }

    a {
      width: 8%;
      padding: 4px 3px;
      margin: 0 6% 10px 0 !important;
    }

    a:nth-child(6n) {
      margin-right: 0 !important;
    }
    
    .extendedContentDesktop{
      display:none;
    }

    .extendedContentTablet{
      display:none;
    }

    .extendedContentMobile{
      display:initial;
    }

    .extendedContent{
      height:auto;
      flex-direction: column;
      min-width:unset;
    }

    .extendedStateInfo{
      border-style:none !important;
      width:100%;

      a {
        min-width: unset;
        width: initial;
        padding: initial;
        margin: initial !important;
      }
    
      .extendedStateInfoContainer{
        margin:0 !important; 
      }
    }
  }
`;
