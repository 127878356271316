import styled from 'styled-components';
import { colors, typography, sizes } from './../../theme_config/theme';

export const ProsConsContainer = styled.div.attrs({ className: 'ProsCons' })`
  display: flex;
  color: ${colors.text};
  font-size: 17px;
  line-height: 28px;
  border: 1px solid ${colors.formElementsBorder};
  border-radius: 5px;

  @media (max-width: ${sizes.tabletEnd}) {
    font-size: 14px;
    line-height: 24px;
  }

  @media (max-width: ${sizes.palmEnd}) {
    display: block;
  }
`;

export const SingleContainer = styled.div`
  background-color: ${props => props.color ? props.color + '20' : 'transparent'};
  display: grid;
  width: 50%;
  padding: 35px 8%;

  h4 {
    color: ${props => props.color ? props.color : props.type === 'pros' ? colors.brandingElements : colors.error};
    text-transform: uppercase;
    font-family: ${typography.fontFamilyHeaderBold};
    font-size: 24px;
    line-height: 30px;
    margin: 0 0 18px;
  }

  div.item {
    display: flex;
    align-items: center;
    margin-bottom: 10px;

    svg {
      width: 12%;
      margin-right: 10px;
    }

    span {
      width: 88%;
    }
  }

  div.item:last-child {
    margin-bottom: 0;
  }

  @media (max-width: ${sizes.tabletEnd}) {
    padding: 30px 8%;

    h4 {
      font-size: 18px;
      line-height: 24px;
      margin-bottom: 10px;
    }

    div.item {
      margin-bottom: 5px;
    }
  }

  @media (max-width: ${sizes.palmEnd}) {
    width: auto;
    padding: 25px 40px;
  }
`;
