// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---components-badge-badge-mdx": () => import("./../../../../components/Badge/Badge.mdx" /* webpackChunkName: "component---components-badge-badge-mdx" */),
  "component---components-best-ranking-best-ranking-mdx": () => import("./../../../../components/BestRanking/BestRanking.mdx" /* webpackChunkName: "component---components-best-ranking-best-ranking-mdx" */),
  "component---components-box-box-mdx": () => import("./../../../../components/Box/Box.mdx" /* webpackChunkName: "component---components-box-box-mdx" */),
  "component---components-break-line-break-line-mdx": () => import("./../../../../components/BreakLine/BreakLine.mdx" /* webpackChunkName: "component---components-break-line-break-line-mdx" */),
  "component---components-button-button-mdx": () => import("./../../../../components/Button/Button.mdx" /* webpackChunkName: "component---components-button-button-mdx" */),
  "component---components-column-range-chart-column-range-chart-mdx": () => import("./../../../../components/ColumnRangeChart/ColumnRangeChart.mdx" /* webpackChunkName: "component---components-column-range-chart-column-range-chart-mdx" */),
  "component---components-columns-columns-mdx": () => import("./../../../../components/Columns/Columns.mdx" /* webpackChunkName: "component---components-columns-columns-mdx" */),
  "component---components-company-cross-linking-company-cross-linking-mdx": () => import("./../../../../components/CompanyCrossLinking/CompanyCrossLinking.mdx" /* webpackChunkName: "component---components-company-cross-linking-company-cross-linking-mdx" */),
  "component---components-company-logo-company-logo-mdx": () => import("./../../../../components/CompanyLogo/CompanyLogo.mdx" /* webpackChunkName: "component---components-company-logo-company-logo-mdx" */),
  "component---components-expand-collapse-expand-collapse-mdx": () => import("./../../../../components/ExpandCollapse/ExpandCollapse.mdx" /* webpackChunkName: "component---components-expand-collapse-expand-collapse-mdx" */),
  "component---components-icon-icon-mdx": () => import("./../../../../components/Icon/Icon.mdx" /* webpackChunkName: "component---components-icon-icon-mdx" */),
  "component---components-list-ranking-list-ranking-mdx": () => import("./../../../../components/ListRanking/ListRanking.mdx" /* webpackChunkName: "component---components-list-ranking-list-ranking-mdx" */),
  "component---components-ordered-list-ordered-list-mdx": () => import("./../../../../components/OrderedList/OrderedList.mdx" /* webpackChunkName: "component---components-ordered-list-ordered-list-mdx" */),
  "component---components-playground-content-validator-mdx": () => import("./../../../../components/Playground/ContentValidator.mdx" /* webpackChunkName: "component---components-playground-content-validator-mdx" */),
  "component---components-playground-playground-mdx": () => import("./../../../../components/Playground/Playground.mdx" /* webpackChunkName: "component---components-playground-playground-mdx" */),
  "component---components-premium-ranking-premium-ranking-mdx": () => import("./../../../../components/PremiumRanking/PremiumRanking.mdx" /* webpackChunkName: "component---components-premium-ranking-premium-ranking-mdx" */),
  "component---components-pros-cons-pros-cons-mdx": () => import("./../../../../components/ProsCons/ProsCons.mdx" /* webpackChunkName: "component---components-pros-cons-pros-cons-mdx" */),
  "component---components-quote-button-quote-button-mdx": () => import("./../../../../components/QuoteButton/QuoteButton.mdx" /* webpackChunkName: "component---components-quote-button-quote-button-mdx" */),
  "component---components-quote-quote-mdx": () => import("./../../../../components/Quote/Quote.mdx" /* webpackChunkName: "component---components-quote-quote-mdx" */),
  "component---components-quotes-widget-quotes-widget-mdx": () => import("./../../../../components/QuotesWidget/QuotesWidget.mdx" /* webpackChunkName: "component---components-quotes-widget-quotes-widget-mdx" */),
  "component---components-star-ranking-star-ranking-mdx": () => import("./../../../../components/StarRanking/StarRanking.mdx" /* webpackChunkName: "component---components-star-ranking-star-ranking-mdx" */),
  "component---components-state-cross-linking-state-cross-linking-mdx": () => import("./../../../../components/StateCrossLinking/StateCrossLinking.mdx" /* webpackChunkName: "component---components-state-cross-linking-state-cross-linking-mdx" */),
  "component---components-static-premium-table-static-premium-table-mdx": () => import("./../../../../components/StaticPremiumTable/StaticPremiumTable.mdx" /* webpackChunkName: "component---components-static-premium-table-static-premium-table-mdx" */),
  "component---components-static-table-static-table-mdx": () => import("./../../../../components/StaticTable/StaticTable.mdx" /* webpackChunkName: "component---components-static-table-static-table-mdx" */),
  "component---readme-md": () => import("./../../../../README.md" /* webpackChunkName: "component---readme-md" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */)
}

