const data = [
  {
    company: 'State Farm',
    address: '123 Main St',
    score: '3.6',
    ratings: 17
  },
  {
    company: 'Geico',
    address: '123 Main St',
    score: '5',
    ratings: 24
  },
  {
    company: 'Todd\'s Terrific Insurance',
    address: '123 Main St',
    score: '3.3',
    ratings: 12
  },
  {
    company: 'Dan\'s Incredible Insurance',
    address: '123 Main St',
    score: '3.6',
    ratings: 35
  },
  {
    company: 'Nationwide',
    address: '123 Main St',
    score: '4.3',
    ratings: 21
  },
  {
    company: 'Allstate',
    address: '123 Main St',
    score: '5',
    ratings: 30
  }
];

export default data;
