import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { PremiumRankingRow } from './PremiumRanking.style';
import { apiRequest, getShowRowsConfiguration } from '../../utils/utils';

const reportTexts = {
  students: 'for students',
  seniors: 'for seniors',
  average: 'on average',
  badCredit: 'for drivers with poor credit',
  speeding: 'for distracted drivers',
  dui: 'for drivers with a DUI',
  liability: 'for liability-only car insurance'
};

/**
 *  PremiumRanking component.
 *
 * This is the body of the component that would show an HTML tables loaded through a dataset
 * @param {string} category the type of report the publisher wants to gather data from.
 * @param {string} state the state of which the report should be filtered.
 * @param {string} year the year of which the report should be filtered.
 */
const PremiumRankingBody = ({ category, state, year, data, reviewPages, showMonthlyValue, showDescription, rows }) => {
  let premiumState = state;
  let premiumYear = year;
  let premiumCategory = category;

  let [apiData, setApiData] = useState([]);
  const requestParams = {
    year: year
  };

  if (data) {
    apiData = data.companies;
    premiumState = data.region;
    premiumYear = data.year;
    premiumCategory = data.category;
  } else {
    useEffect(() => {
      axios(apiRequest('get', `auto-state/${state}/premiums`, requestParams)).then(request => {
        setApiData(request.data[category].filter(item => item.premium && item.premium.value));
      });
    }, []);
  }

  const premiumItems = getPremiumItems(
    apiData,
    reviewPages,
    premiumCategory,
    premiumState,
    premiumYear,
    showMonthlyValue,
    showDescription
  );
  const showMore = getShowRowsConfiguration(rows, premiumItems);


  // Validates if any information is loaded from API.
  const dataValidation = apiData.length > 0 ? true : false;

  return (<>
    {dataValidation ? showMore.items : <h2>No information available</h2>}
    {dataValidation && showMore.button}
  </>);
};

const getPremiumItems = (data, reviewPages, category, state, year, showMonthlyValue, showDescription) => {
  const items = [];
  const columnClass = showMonthlyValue ? 'col-hf' : 'col-lg';
  const isShowDescription = showDescription === 'true';

  data.sort((a, b) => a.premium.value - b.premium.value).forEach((item, index) => {
    const isIndexEven = index % 2 == 0;
    const companyClass = isIndexEven ? columnClass : `dark-background ${columnClass}`;
    const smallContainerClass = isIndexEven ? '' : 'dark-background';
    const currency = new Intl.NumberFormat('en-US', { style: 'currency', currency: item.premium.currency });
    // Formats currency to remove additional cents
    const formattedCurrency = currency.format(item.premium.value).split('.')[0];
    const monthlyValue = Math.round(item.premium.value / 12);
    const formattedMonthly = currency.format(monthlyValue).split('.')[0];

    const reviewPage = reviewPages && reviewPages[item.company.id];
    const fullCompanyURL = `https://www.moneygeek.com${reviewPage}`;
    const companyColumn = reviewPage ? <a href={fullCompanyURL}>{item.company.name}</a> : item.company.name;

    items.push(
      <PremiumRankingRow key={index}>
        <div className={`company-body ${companyClass}`}>
          <div className="table full-height-per">
            <div className="vertical-center">
              <div className={`company-content company-name mg-top-16 ${isShowDescription ? '' : 'mg-bottom-16'}`}>
                {index + 1}. {companyColumn}
              </div>
              {isShowDescription && getCompanyDescription(item.company.name, state, year, index, category)}
            </div>
          </div>
        </div>
        <div className={`amount-body col-sm ${smallContainerClass}`}>
          <div className="table price-amount full-height-per full-width-per bold">
            <div className="vertical-center">
              {formattedCurrency}
              {!showMonthlyValue && <div className="price-description">per year</div>}
            </div>
          </div>
        </div>
        {showMonthlyValue && <div className={`amount-body col-sm ${smallContainerClass}`}>
          <div className="table price-amount full-height-per full-width-per bold">
            <div className="vertical-center">
              {formattedMonthly}
            </div>
          </div>
        </div>}
      </PremiumRankingRow>
    );
  });

  return items;
};

const getCompanyDescription = (company, state, year, index, category) => (
  <div className="company-content company-description">
    In {year}, {company} ranked #{index + 1} in {state} for the cheapest
     car insurance {reportTexts[category]}.
  </div>
);

PremiumRankingBody.propTypes = {

  /**
   * The category the publisher wants the data from.
   */
  category: PropTypes.string,

  /**
   * All states available to load component information.
   * Full state names are used in component: Alabama, Alaska, California.
   */
  state: PropTypes.string,

  /**
   * Four digit year to load component information.
   */
  year: PropTypes.string,

  /**
   * Optional data that fills the component when it's used on a Template page. Overrides state and year parameters.
   */
  data: PropTypes.oneOfType([PropTypes.object, PropTypes.arrayOf(PropTypes.object)]),

  /**
   * Optional list used to transform the company label into an anchor for the company review page.
   */
  reviewPages: PropTypes.oneOfType([PropTypes.object, PropTypes.arrayOf(PropTypes.object)]),

  /**
   * Optional parameter that specify if monthly column will appear for each element
   */
  showMonthlyValue: PropTypes.bool,

  /**
   * Optional parameter that specify if a description is going to appear for each element
   */
  showDescription: PropTypes.oneOf(['true', 'false']),

  /**
   * Rows configuration to display on the component: number and text.
   */
  rows: PropTypes.object
};

export default PremiumRankingBody;
