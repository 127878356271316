import styled from 'styled-components';
import { normalize } from 'styled-normalize';
import { colors, typography, sizes } from './../../theme_config/theme';
import '../../fonts/_fonts.css';

export const CompactQuotesWidget = styled.div`
  ${normalize}
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${colors.brandingElements};
  background-image: url("https://res.cloudinary.com/moneygeek/image/upload/v1579500551/bzr65ug0nqivduutyymz.svg");
  background-position: center center;
  background-repeat: repeat;
  background-size: 100px auto;
  border-radius: 5px;
  text-align: center;
  font-size: 16px;
  max-width: 300px;
  padding-bottom: 2px;
  overflow: hidden;

  div.title {
    background-color: #1F1B50;
    width: 100%;

    h4 {
      background-color: ${colors.branding};
      text-align: center;
      margin: 0;
      color: white;
      padding: 20px 22px;
      font-family: ${typography.fontFamilyHeaderBold};
      font-size: 20px;
      text-transform: uppercase;
    }
  }

  div.container {
    padding: 15px 12px;
  }

  img {
    display: inline-block;
    padding-top: 8px;
  }

  p {
    font-family: ${typography.fontFamilyBase};
    font-weight: 300;
    color: white;
    line-height: 1.2;
    text-align: center;
    margin: 0 5px;
    font-size: 100%;
  }

  form {
    display: block;
    align-items: center;
    width: 100%;
    margin-top: 8%;

    div > button {
      font-size: 100%;
      width: 100%;
      padding: 12px;
    }
  }

  .field-zip {
    margin: 0;
    min-width: 162px;
    position: relative;
    border-style: none;
    &::after {
      background-color: #e1e1e5;
      color: green;
      content: "";
      display: block;
      height: calc(100% - 30px);
      position: absolute;
      right: 56px;
      top: 15px;
      width: 1px;
      z-index: 1;
    }

    input {
      height: 45px;
      width: 100%;
      background-image: 
      url("https://res.cloudinary.com/moneygeek/image/upload/v1582232089/assets/widget-location-pin_yhsttw.svg");
      background-position: right 12px center;
      background-repeat: no-repeat;
      background-size: 20px;
      margin: 0;
      font-style: italic;
      box-sizing: border-box;
      display: block;
      font-size: 100%;
      border-radius: 5px;
      border-width: 1px;
      border-style: solid;
      border-color: rgb(222, 226, 231);
      padding: 10px 46px 10px 15px;
      transition: border-color 0s ease 0s;
    }
  }

  input[type="submit"] {
    margin: 0;
    width: 100%;
  }
  
  .error {
    color: white;
    width: auto;
    text-align: center;
  }
  .compact-image {
    width: 100%;
  }

  @media (max-width: ${sizes.palmEnd}) {
    max-width: 100%;
    width: 100%;

    div.title > h4 {
      font-size: 120%;
      padding: 10px 38px;
    }

    div.container {
      padding: 3% 14% 8%;
    }

    img {
      margin-top: 10px;
    }

    p, .field-zip > input {
      font-size: 90%;
    }

    .field-zip > input {
      height: 40px;
    }

    form {
      margin-top: 5%;

      div > button {
        font-size: 125%;
        padding: 10px;
      }
    }
  }
`;

export const FullWidthQuotesWidget = styled.div`
  ${normalize}
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: white;
  border-radius: 5px;
  text-align: center;
  font-size: 16px;
  padding: 15px 0 20px 0;
  border: thin solid ${colors.formElementsBorder};
  overflow: hidden;

  @media (max-width: ${'1100px'}) {
    flex-direction: column;
  }

  @media (max-width: ${sizes.tabletEnd}) {
    padding: 10px 0;
  }

  .full-width {
    display: flex;
    flex-direction: row;

    @media (max-width: ${'1100px'}) {
      flex-direction: column;
      align-items: center;
    }
  }

  .text-container {
    padding-left: 28px;

    @media (max-width: ${'1100px'}) {
      padding: 8px;
    }
  }

  h4 {
    text-align: left;
    margin: 0 0 10px;
    text-transform: none;
    color: ${colors.brandingElements};
    font-family: ${typography.fontFamilyHeaderLight};
    font-weight: 300;
    font-size: 30px;
    line-height: 30px;

    @media (max-width: ${'1100px'}) {
      text-align: center;
      font-size: 26px;
    }
  }

  img:not(.mav-partner__image) {
    display: inline-block;
    margin: 0 0 0 20px;

    @media (max-width: ${'1100px'}) {
      margin: 0;
    }
  }

  p {
    font-family: ${typography.fontFamilyBase};
    font-weight: 300;
    color: ${colors.text};
    line-height: 1.4;
    width: 76%;
    text-align: left;
    font-size: 13px;
    margin: 0;

    @media (max-width: ${'1100px'}) {
      width: auto;
      text-align: center;
    }
  }

  form {
    display: flex;
    align-items: center;

    @media (max-width: ${'1100px'}) {
      text-align: center;
    }

    @media (max-width: ${'532px'}) {
      display: block;
      width: 100%;
    }
  }

  form > div > button {
    @media (max-width: ${'768px'}) {
      width: 100%;
    }
  }

  .field-zip {
    margin: 0;
    min-width: 162px;
    position: relative;
    border-style: none;
    &::after {
      background-color: #e1e1e5;
      color: green;
      content: "";
      display: block;
      height: calc(100% - 30px);
      position: absolute;
      right: 56px;
      top: 15px;
      width: 1px;
      z-index: 1;
    }

    input {
      width: 100%;
      background-image: 
      url("https://res.cloudinary.com/moneygeek/image/upload/v1582232089/assets/widget-location-pin_yhsttw.svg");
      background-position: right 12px center;
      background-repeat: no-repeat;
      background-size: 20px;
      margin: 0;
      font-style: italic;
      box-sizing: border-box;
      display: block;
      font-size: 16px;
      border-radius: 5px;
      border-width: 1px;
      border-style: solid;
      border-color: rgb(222, 226, 231);
      padding: 10px 46px 10px 15px;
      transition: border-color 0s ease 0s;

      @media (max-width: ${'1100px'}) {
        margin: 0 auto;
        width:100%;
      }
    }
  }

  input[type="submit"] {
    margin: 0;
    width: 100%;
  }

  .error {
    color: #FA8072;
    width: 100%;
    text-align: center;
  }

  .full-width-image {
    width: 66px;
    height: 100%;
  }
`;

export const ColoredQuotesWidget = styled.div`
  ${normalize}
  background-color: ${colors.brandingLight};
  border-radius: 5px;
  text-align: center;
  border-bottom: 6px solid ${colors.brandingElements};

  div.text-container {
    color: ${colors.text};
    padding: 40px 22% 0;
    margin-bottom: 40px;

    span.title {
      font-family: ${typography.fontFamilyHeaderBold};
      font-size: 26px;
      line-height: 30px;
    }

    p {
      font-size: 17px;
      line-height: 28px;
      margin: 10px 0 0;
    }
  }

  form {
    display: flex;
    justify-content: center;
    height: 80px;
    margin-bottom: ${props => props.error ? '55px' : '30px'};

    div:nth-child(2) {
      margin: 0 20px;

      button {
        width: 200px;
        height: 50px;
        font-size: 17px;
        line-height: 22px;
      }
    }
  }

  .field-zip {
    margin: 0;
    padding: 0;
    min-width: 162px;
    position: relative;
    border-style: none;
    &::after {
      background-color: #e1e1e5;
      color: green;
      content: "";
      display: block;
      height: calc(100% - 30px);
      position: absolute;
      right: 56px;
      top: 15px;
      width: 1px;
      z-index: 1;
    }

    input {
      width: 222px;
      height: 50px;
      background-image: 
      url("https://res.cloudinary.com/moneygeek/image/upload/v1582232089/assets/widget-location-pin_yhsttw.svg");
      background-position: right 12px center;
      background-repeat: no-repeat;
      background-size: 20px;
      margin: 0;
      font-style: italic;
      box-sizing: border-box;
      display: block;
      font-size: 16px;
      border-radius: 5px;
      border-width: 1px;
      border-style: solid;
      border-color: rgb(222, 226, 231);
      padding: 10px 46px 10px 15px;
      transition: border-color 0s ease 0s;
    }
  }

  input[type="submit"] {
    margin: 0;
    width: 100%;
  }

  .error {
    color: #FA8072;
    width: 100%;
    text-align: center;
    font-size: 16px;
    margin: 5px 0 0;
  }

  img.badge {
    width: 185px;
    display: block;
    margin: -110px 0 -35px 0;
  }

  @media (max-width: ${sizes.tabletEnd}) {
    div.text-container {
      padding: 40px 5% 0;
      margin-bottom: 20px;
    }

    form {
      display: block;
      height: ${props => props.error ? '145px' : '110px'};
      margin-bottom: 60px;

      div:nth-child(2) {
        margin: auto;

        button {
          width: 222px;
        }
      }
    }

    .field-zip > input {
      margin: auto;
    }

    .error {
      margin: 10px 0;
    }
  }

  @media (max-width: ${sizes.palmEnd}) {
    div.text-container {
      span.title {
        font-size: 18px;
        line-height: 24px;
      }
  
      p {
        font-size: 14px;
        line-height: 24px;
      }
    }

    img.badge {
      width: 110px;
      margin: -60px 0 -21px 0;
    }
  }
`;
