/* eslint-disable no-undef */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Highcharts from 'highcharts';
import axios from 'axios';
import HighchartsMore from 'highcharts/highcharts-more';
import HighchartsReact from 'highcharts-react-official';
import { apiRequest } from '../../utils/utils';
import { colors, typography } from '../../theme_config/theme';
import ColumnRangeContainer from './ColumnRangeChart.style';

if (typeof Highcharts === 'object') {
  // eslint-disable-next-line new-cap
  HighchartsMore(Highcharts);
}

/**
 * ColumnRangeChart component.
 *
 * This component should show a ColumnRangeChart chart with some custom attributes
 *
 * @param {*} title
 * @param {*} horizontal
 * @param {*} background
 *
 */
const ColumnRangeChart = ({ title, horizontal, background, id, state, year }) => {
  const [charts, setCharts] = useState([]);
  let series = [];
  let medians = [];
  let medianNames = [];
  let names = [];
  let medianData = [];
  let data = [];
  let backgroundData = [];
  let maxY = 0;
  let minY = null;

  useEffect(() => {
    const requestParams = {
      year: year,
      type: 'auto-insurance-state-chart'
    };

    axios(apiRequest('get', `autostates/${state}`, requestParams)).then(resp => {
      setCharts(resp.data.charts);
    });
  }, []);

  /*
    Pushes the series from the Chart to a series array
    Pushes the median data from the Chart to a median array
  */
  charts.map(chart => {
    if (chart.id === id) {
      series.push(chart.series);
      medians.push(chart.median);
    }
  });

  if (series.length > 0) {
    /*
    Pushes the name for each serie to a names array
    Pushes the data for each serie to a data array
  */
    series[0].map(serie => {
      names.push(serie.name);
      data.push(serie.data[0]);

      /*
    Defines the maxY and minY to load Y axis labels and background bars.
    */
      serie.data[0].map(number => {
        if (number > maxY) {
          maxY = number;
        }
        if (minY == null || number < minY) {
          minY = number;
        }
      });
    });

    data.map(() => {
      backgroundData.push([minY - 400, maxY + 400]);
    });

    /*
    Pushes the name for each median to a medianNames array
    Pushes the data for each medianData to a medianData array
  */
    medians[0].map(e => {
      medianNames.push(e.name);
      medianData.push(e.data[0]);
    });
  } else {
    return (
      <p>No data was found with that ID</p>
    );
  }

  let options = {
    chart: {
      type: 'columnrange',
      inverted: horizontal,
      style: {
        fontFamily: typography.fontFamilyHeaderBold,
      }
    },
    credits: {
      enabled: false
    },
    title: {
      text: title,
      style: {
        textTransform: 'uppercase'
      }
    },
    legend: {
      itemStyle: {
        fontSize: '14px'
      },
    },
    xAxis: {
      categories: names,
      labels: {
        style: {
          fontSize: '16px'
        }
      }
    },
    yAxis: {
      title: null,
      min: minY - 500,
      max: maxY + 500,
      labels: {
        format: '${value:,.0f}'
      }
    },

    plotOptions: {
      series: {
        states: {
          inactive: {
            opacity: 0.8
          }
        },
        pointWidth: 24,
      },
      columnrange: {
        dataLabels: {
          enabled: false,
          format: '${y:,.0f}'
        }
      }
    },
    tooltip: {
      followPointer: true,
      hideDelay: 10,
      valuePrefix: '$',
      style: {
        fontSize: '16px'
      },
      headerFormat: '<span style="font-size: 14px;">{point.key}</span><br/>'
    },

    series: [background ? {
      enableMouseTracking: false,
      grouping: false,
      showInLegend: false,
      color: colors.backgroundRow,
      data: backgroundData
    } : {
      showInLegend: false,
      enableMouseTracking: false
    },
    {
      name: '25th - 75th Percentile',
      grouping: false,
      color: colors.brandingElements,
      data: data
    },
    {
      tooltip: {
        useHTML: true,
        headerFormat: '<small>{point.key}:</small><table>',
        pointFormat: '<tr><td style="text-align: right"><b> {point.y}</b></td></tr>',
        footerFormat: '</table>'
      },
      name: 'Median',
      stickyTracking: false,
      type: 'scatter',
      data: medianData,
      color: '#ffbf00',
      marker: {
        symbol: 'circle'
      }
    }]
  };


  return (
    <ColumnRangeContainer>
      <HighchartsReact highcharts={Highcharts} options={options} />
    </ColumnRangeContainer>
  );
};


ColumnRangeChart.propTypes = {

  /**
  * Title for the Chart
  *
  */
  title: PropTypes.string,

  /**
  * One of the US states
  *
  */
  state: PropTypes.string,

  /**
  * The year associated with the chart
  *
  */
  year: PropTypes.number,

  /**
  * The id from the chart data. For examples purposes 'premium-ranges-by-age' and
  *  'premium-ranges-by-category' can be used.
  *
  */
  id: PropTypes.string,

  /**
  * If true, the chart will render horizontally
  *
  */
  horizontal: PropTypes.bool,

  /**
  * If false, the bar background will not be shown.
  *
  */
  background: PropTypes.bool,

};

ColumnRangeChart.defaultProps = {
  title: 'Default bar chart title',
  horizontal: false,
  background: false
};


export default ColumnRangeChart;
