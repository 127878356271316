import React, { useState } from 'react';
import { ShowMoreButton } from './ShowMore.style';

/**
 * ShowMore class, this class allows to the developer to limit the number
 * of items on its component/table and add a "Show more" button in any place
 * of the render.
 *
 * @param {Integer} maxItems the max number of items the component is going to show.
 * @param {React.Component} children the items that are going to be limited by the "Show more" button.
 *
 * Usage:
 *
 * Add the showMore object at the begining of the component:
 * const showMore = new ShowMore(maxItems, children);
 *
 * This is another way to use the ShowMore class to add inner components programatically:
 * const showMore = new ShowMore(maxItems, [
 *  (<item>Item 1</item>),
 *  (<item>Item 2</item>),
 *  (<item>Item 3</item>),
 *  ...
 * ]);
 *
 * Inside the render(), in the place you would put the items to show you should add the following:
 * {showMore.items}
 *
 * Also you will need to include to the render() the "Show more" button, you can do that
 * adding the following:
 * {showMore.button}
 * ...
 */
class ShowMore {
  constructor(maxItems, children, titleMore, titleLess) {
    this.showMore = false;
    this.maxItems = maxItems;
    this.children = children;
    this.setShowMore = null;
    this.titleMore = titleMore ? titleMore : 'Show more';
    this.titleLess = titleLess ? titleLess : 'Show less';
    this.setStates();
  }

  setStates() {
    const [showMore, setShowMore] = useState(false);
    this.showMore = showMore;
    this.setShowMore = setShowMore;
  }

  get items() {
    return this.showMore ? this.children : React.Children.map(this.children, (child, index) => {
      let newChild = React.cloneElement(child, {});
      if (index >= this.maxItems) {
        newChild = React.cloneElement(child, {
          style: { display: 'none' }
        });
      }
      return newChild;
    });
  }

  get button() {
    return this.maxItems < this.children.length ? (
      <ShowMoreButton onClick={() => this.setShowMore(!this.showMore)}>
        {this.showMore ? this.titleLess : this.titleMore}
      </ShowMoreButton>
    ) : null;
  }
}

export default ShowMore;
