/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';

const ICONLIST = {
  checkmark: 'M22.4328585,9.37591783 C22.1064257,9.10580554 21.6825093,8.97230712 21.2543879,9.00479765 C20.8262666,9.03728819 20.4290191,9.23310553 20.150056,9.54916188 L13.1788726,17.444189 L9.42795976,14.412418 C8.80063385,13.9057215 7.86792769,13.9865474 7.34354124,14.5930484 C7.09226695,14.8841606 6.9708216,15.2600182 7.00594897,15.6378525 C7.04107635,16.0156868 7.22989574,16.3645126 7.53082676,16.6075142 L12.5570153,20.6700201 C12.8705244,20.9230316 13.2785953,21.0384524 13.6835031,20.9886415 C14.1123122,20.9567017 14.5103189,20.7607339 14.7895281,20.4440642 L22.6118202,11.5847795 C23.1926351,10.9267784 23.1125143,9.93757464 22.4328585,9.37524634',
  close: 'M21.6554051,8.34459488 C22.114865,8.80405473 22.114865,9.54898632 21.6554051,10.0084462 L16.664,15 L21.6554051,19.9915538 C22.114865,20.4510137 22.114865,21.1959453 21.6554051,21.6554051 C21.1959453,22.114865 20.4510137,22.114865 19.9915538,21.6554051 L15,16.664 L10.0084462,21.6554051 C9.54898632,22.114865 8.80405473,22.114865 8.34459488,21.6554051 C7.88513504,21.1959453 7.88513504,20.4510137 8.34459488,19.9915538 L13.336,15 L8.34459488,10.0084462 C7.88513504,9.54898632 7.88513504,8.80405473 8.34459488,8.34459488 C8.80405473,7.88513504 9.54898632,7.88513504 10.0084462,8.34459488 L15,13.336 L19.9915538,8.34459488 C20.4510137,7.88513504 21.1959453,7.88513504 21.6554051,8.34459488 Z',
  chevron: 'M11.3,8.3 L11.3,8.3 C10.8,8.8 10.8,9.5 11.3,10 L16.4,15.1 L11.3,20.2 C10.8,20.7 10.8,21.4 11.3,21.9 L11.3,21.9 C11.8,22.4 12.5,22.4 13,21.9 L19.7,15.2 L13,8.3 C12.6,7.9 11.8,7.9 11.3,8.3 Z'
};

const IconSVG = ({ name, title, size, color }) => (
  <svg width={size} height={size} viewBox="0 0 30 30">
    <title>{title}</title>
    <path d={ICONLIST[name]} fill={color ? color : '#A7A9AC'}></path>
  </svg>
);

IconSVG.propTypes = {
  name: PropTypes.string,
  title: PropTypes.string,
  size: PropTypes.string,
  color: PropTypes.string
};

export default IconSVG;
