import styled from 'styled-components';
import { colors, typography, sizes } from './../../theme_config/theme';

export const ListRankingContainer = styled.div`
  font-family: ${typography.fontFamilyBase};
  color: ${colors.text};

  div.header {
    display: flex;
    margin-bottom: 20px;
  }
    
  div.Badge {
    margin: 0 8% 0 4%;
  }

  div.title {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  h2 {
    font-family: ${typography.fontFamilyHeaderBold};
    color: ${colors.text};
    font-size: 40px;
    line-height: 52px;
    vertical-align: middle;
    margin: 0;
  }

  div.ListRankingRow:last-of-type {
    border-bottom: 1px solid ${colors.backgroundRow};
  }

  button.ShowMore {
    margin: 10px 0 0 5%;
    padding: 0;
  }

  @media (max-width: ${sizes.tabletEnd}) {
    h2 {
      font-size: 24px;
      line-height: 30px;
    }
  }

  @media (max-width: ${sizes.palmEnd}) {
    div.header {
      display: block;
      margin-bottom: 15px;
    }

    h2 {
      text-align: center;
      margin-top: 10px;
    }

    button.ShowMore {
      margin-left: 0;
    }
  }
`;

export const ListRankingRow = styled.div.attrs({ className: 'ListRankingRow' })`
  display: flex;
  flex-wrap: wrap;
  border: 1px solid ${colors.backgroundRow};
  border-style: solid none none;
  padding: 8px 5%;

  div.company {
    display: grid;
    width: 86%;
  }

  span.name {
    font-family: ${typography.fontFamilyHeaderBold};
    font-size: 24px;
  }

  span.address {
    font-size: 15px;
  }

  div.rating {
    display: grid;
    text-align: center;
    width: 14%;
  }

  span.details {
    font-size: 13px;
    margin-top: -10px;
  }

  @media (max-width: ${sizes.tabletEnd}) {
    div.company {
      width: 84%;
    }

    div.rating {
      width: 16%;
    }
  }

  @media (max-width: ${sizes.palmEnd}) {
    padding: 8px 0;

    div.company {
      width: 68%;
    }

    span.name {
      font-size: 18px;
    }

    span.address {
      font-size: 14px;
    }

    div.rating {
      width: 32%;
    }

    span.details {
      font-size: 12px;
    }
  }
`;
