import React from 'react';
import PropTypes from 'prop-types';
import { ListRankingContainer, ListRankingRow } from './ListRanking.style';
import Spacing from '../Spacing/Spacing';
import ShowMore from '../ShowMore/ShowMore';
import Stars from '../StarRanking/Stars';
import Badge from '../Badge/Badge';

const SERVICES = {
  carInsurance: 'Car Insurance'
};

const sortRankingList = (data) => (
  data.sort((a, b) => parseFloat(b.score) - parseFloat(a.score) || parseFloat(b.ratings) - parseFloat(a.ratings))
);

const getRankingList = (data) => (
  sortRankingList(data).map((item, index) => (
    <ListRankingRow key={index}>
      <div className="company">
        <span className="name">{index + 1}. {item.company}</span>
        <span className="address">{item.address}</span>
      </div>
      <div className="rating">
        <Stars ranking={item.score} />
        <span className="details">{item.ratings} ratings</span>
      </div>
    </ListRankingRow>
  ))
);

/**
 * ListRanking component.
 *
 * This component would show a list ranking container using the information on the parameter
 * and generate a list with text and a star ranking.
 */
const ListRanking = ({ city, service, year, displayCount, data, margin }) => {
  if (!city || !service || !year || !Object.keys(SERVICES).includes(service) || data.length === 0) {
    return (
      <Spacing margin={margin}>
        <ListRankingContainer>
          <h2>No information available for defined parameters</h2>
        </ListRankingContainer>
      </Spacing>
    );
  }
  const showMore = new ShowMore(displayCount, getRankingList(data));
  return (
    <Spacing margin={margin}>
      <ListRankingContainer>
        <div className="header">
          <Badge content={{
            title: 'top rated',
            firstSubtitle: `${SERVICES[service]} agents`,
            secondSubtitle: city,
            year: year
          }} />
          <div className="title">
            <h2>Top Agents Offering Affordable {SERVICES[service]} in {city}</h2>
          </div>
        </div>
        {showMore.items}
        {showMore.button}
      </ListRankingContainer>
    </Spacing>
  );
};

ListRanking.propTypes = {

  /**
   * Reference city for the list ranking.
   */
  city: PropTypes.string,

  /**
   * Reference service for the list ranking.
   */
  service: PropTypes.oneOf(Object.keys(SERVICES)),

  /**
   * Reference year for the list ranking.
   */
  year: PropTypes.string,

  /**
   * Number of companies to display on the component.
   */
  displayCount: PropTypes.number,

  /**
   * Companies data loaded in the component.
   */
  data: PropTypes.oneOfType([PropTypes.object, PropTypes.arrayOf(PropTypes.object)]),

  /**
   * Bottom margin space defined for the component (pixels).
   */
  margin: PropTypes.object
};

ListRanking.defaultProps = {
  service: 'carInsurance',
  displayCount: 5
};

export default ListRanking;
