import React from 'react';
import Modal from '../components/Modal/Modal';
import { QuinStreetContainer } from '../components/QuoteButton/QuinStreetQuoteButton.style';

export const addQuinStreetScript = () => {
  // eslint-disable-next-line no-undef
  const scriptQS2 = document.createElement('script');
  scriptQS2.src = '//www.nextinsure.com/listingdisplay/loader/sh';
  scriptQS2.crossOrigin = 'anonymous';
  scriptQS2.async = true;
  // eslint-disable-next-line no-undef
  document.body.appendChild(scriptQS2);
};

export const addQuinStreetConfig = (zipCode) => {
  /**
   * Running the configuration as a promise to execute the code asyncronously
   * so it can be catched by the QuinStreet Script.
   */
  const runConfig = async () => {
    var matchingConfiguration = {
      src: '666299',
      zipcode: zipCode
    };

    return matchingConfiguration;
  };

  runConfig().then((matchingConfiguration) => {
    // eslint-disable-next-line no-undef, camelcase
    sh.initialize(matchingConfiguration, 'qsWidgetContainer');
  });
};

export const quinStreetEvent = (event, show, setShowModal, setError, showModal) => {
  const isValidZip = (zip) => !zip || zip.length < 5 || zip.length > 5;

  event.preventDefault();

  const zip = event.target.zip.value;
  setError(false);

  // Return & Error if no zip
  if (isValidZip(zip)) {
    setError(true);
    return;
  }

  // eslint-disable-next-line no-undef, camelcase
  addQuinStreetConfig(zip);
  showModal(show, setShowModal);
};

/**
 * QuinStreetQuoteModal component.
 *
 * Helper component for the QuinStreetQuoteButton.
 */
// eslint-disable-next-line react/prop-types
export const QuinStreetQuoteModal = ({ show, showModal, setShowModal }) => (
  <Modal active={show} onClick={() => showModal(show, setShowModal)} title='Insurance Quote'>
    <QuinStreetContainer>
      <div id="qsWidgetContainer"></div>
    </QuinStreetContainer>
  </Modal>
);
