const monthlyPremiumData = {
  category: 'speeding',
  region: 'New York',
  year: '2020',
  companies: [
    {
      company: {
        id: 13,
        name: 'Allstate F&C'
      },
      premium: {
        value: 1301,
        currency: 'USD'
      }
    },
    {
      company: {
        name: 'State Farm Mutual Auto'
      },
      premium: {
        value: 829,
        currency: 'USD'
      }
    },
    {
      company: {
        name: 'Progressive Cty Mtl'
      },
      premium: {
        value: 1418,
        currency: 'USD'
      }
    },
    {
      company: {
        name: 'GEICO Govt Employees'
      },
      premium: {
        value: 1469,
        currency: 'USD'
      }
    },
    {
      company: {
        name: 'Progressive Cty Mutual ADV'
      },
      premium: {
        value: 1139,
        currency: 'USD'
      }
    },
    {
      company: {
        name: 'USAA'
      },
      premium: {
        value: 971,
        currency: 'USD'
      }
    }
  ]
};

const marketShareData = [
  { company: 'Progressive', value: 0.1118, url: 'https://www.progressive.com/auto/' },
  { company: 'Farmers', value: 0.0817 },
  { id: 13, company: 'Allstate', value: 0.1165, url: 'https://www.allstate.com/auto-insurance.aspx' },
  { company: 'State Farm', value: 0.1499, url: 'https://www.statefarm.com/insurance/auto' },
  { company: 'USAA', value: 0.0844, url: 'https://www.usaa.com/inet/wc/auto-insurance' },
  { company: 'GEICO', value: 0.1310 }
];

const financialStabilityData = [
  { id: 13, company: 'Allstate', value: 'A-', url: 'https://www.allstate.com/auto-insurance.aspx' },
  { company: 'Progressive', value: 'B', url: 'https://www.progressive.com/auto/' },
  { company: 'State Farm', value: 'A++', url: 'https://www.statefarm.com/insurance/auto' },
  { company: 'GEICO', value: 'A' }
];

const reviewPages = {
  2: '/insurance/auto/reviews/aaa-2/',
  13: '/insurance/auto/reviews/allstate/',
  106: '/insurance/auto/reviews/nationwide-insurance/'
};

export { monthlyPremiumData, marketShareData, financialStabilityData, reviewPages };
