import { Playground, Props } from "docz";
import Button from "../../../../../components/Button/Button";
import Columns from "../../../../../components/Columns/Columns";
import * as React from 'react';
export default {
  Playground,
  Props,
  Button,
  Columns,
  React
};