import { Playground, Props } from "docz";
import CompanyCrossLinking from "../../../../../components/CompanyCrossLinking/CompanyCrossLinking";
import data from "../../../../../components/CompanyCrossLinking/MockData";
import * as React from 'react';
export default {
  Playground,
  Props,
  CompanyCrossLinking,
  data,
  React
};