import styled from 'styled-components';
import { colors, typography } from './../../theme_config/theme';

export const ModalContainer = styled.div`
  background-color: ${colors.textLight};
  left: 50%;
  max-height: calc(100vh - 150px);
  max-width: 900px;
  padding: 25px;
  position: fixed !important;
  border-radius: 8px;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 90%;
  z-index: 100;
  box-sizing: border-box;
  box-shadow: 0px 0px 15px 3px rgba(0,0,0,0.2);
  overflow-y: auto;

  @media (max-width: ${'479px'}) {
    width: 100%;
    height: 100%;
    max-width: unset;
    max-height: unset;
    border-radius: 0px;
    z-index: 100;
  }

    /* width */
::-webkit-scrollbar {
  width: 0px;
}

.modal-title {
  color: ${colors.brandingElements};
  text-align: left;
  padding-left: 20px;
  font-family: ${typography.fontFamilyHeaderLight};
  font-weight: 300;
  font-size: 30px;
  margin: 0;
}`;

export const ModalCloseButton = styled.button`
  background: ${colors.textLight};
  cursor: pointer;
  display: block;
  height: 24px;
  position: absolute;
  right: 45px;
  top: 35px;
  width: 24px;
  z-index: 2;
  border: 0;
  outline: 0;
  padding: 0;
  margin: 0;
  min-width: 0 !important;

  &::before,
  &::after {
    background: ${colors.text};
    border-radius: 4px;
    box-sizing: border-box;
    display: block;
    content: "";
    height: 2px;
    left: 0;
    line-height: 1;
    margin: 0;
    padding: 0;
    position: absolute;
    right: 0;
    width: 100%;
  }

  &::before {
    transform: rotate(45deg);
    top: 50%;
  }

  &::after {
    transform: rotate(-45deg);
    top: 50%;
  }
`;

export const ModalOverlayContainer = styled.div`
  background-color: rgba(0, 0, 0, 0.8);
  bottom: 0;
  cursor: pointer;
  display: block;
  left: 0;
  position: fixed !important;
  top: 0;
  visibility: visible;
  width: 100%;
  z-index: 99;
`;

export const ModalWrapper = styled.div`
  width: 0 !important;
`;
