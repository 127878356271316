import React from 'react';
import PropTypes from 'prop-types';
import { PremiumRankingRow } from './PremiumRanking.style';
import { getShowRowsConfiguration } from '../../utils/utils';
import QuoteButton from '../QuoteButton/QuoteButton';

const transformCustomData = (data) => (
  data.map(item => ({
    company: {
      id: item.id,
      name: item.company,
      url: {
        primary: item.url
      }
    },
    amBest: {
      stability: item.value ? item.value : ''
    }
  }))
);

/**
 * Auto Insurance Financial stability component, this is the Financial stability version of the
 * Premium Ranking component. This component is going to be rendered when the
 * financial-stability datatype is specified.
 * @param {string} state the state of which the report should be filtered.
 * @param {string} year the year of which the report should be filtered.
 * @param {*} quotebutton if this parameter is set, the quotes button is going to be rendered.
 */
const FinancialStability = ({ quotebutton, data, reviewPages, rows }) => {
  const apiData = data ? transformCustomData(data) : [];

  const showMore = getShowRowsConfiguration(rows, getItems(apiData, reviewPages, quotebutton));

  // Validates if any information is loaded from API.
  const dataValidation = apiData.length > 0 ? true : false;

  // Validates if any information is loaded from API or custom data parameter.
  return (<>
    <PremiumRankingRow>
      <div className={quotebutton ?
        'company col-md gray-header' : 'company col-lg gray-header'}>
        <div className="title title-company">Company</div>
      </div>
      {getFinancialStabilityHeaders(quotebutton)}
    </PremiumRankingRow>
    {dataValidation ? showMore.items : <h2>No information available</h2>}
    {dataValidation && showMore.button}
  </>);
};

const getFinancialStabilityHeaders = (quotebutton) => {
  return quotebutton ? (
    <div className="col-rl">
      <div className="amount col-hf amount-header market-share-title">
        <div className="title title-amount">Financial Stability</div>
      </div>
      <div className="amount col-hf gray-header get-quote-title">
        <div className="title title-amount"></div>
      </div>
    </div>
  ) : (
    <div className="amount col-sm amount-header">
      <div className="title title-amount">Financial Stability</div>
    </div>
  );
};

const stabilityToNumber = stability => {
  switch (stability) {
    case 'A++':
      return 1;
    case 'A+':
      return 2;
    case 'A':
      return 3;
    case 'A-':
      return 4;
    case 'B++':
      return 5;
    case 'B+':
      return 6;
    case 'B':
      return 7;
    case 'B-':
      return 8;
    case 'C++':
      return 9;
    case 'C+':
      return 10;
    case 'C':
      return 11;
    case 'C-':
      return 12;
    case '':
      return 999;
  }
};

const getItems = (data, reviewPages, quotebutton) => {
  let index = 0;
  const columnClass = quotebutton ? 'col-md' : 'col-lg';
  const quoteButtonClass = quotebutton ? 'resp-col-sm' : '';

  return data.sort((a, b) =>
    stabilityToNumber(a.amBest.stability) - stabilityToNumber(b.amBest.stability)).map((item) => {
    const isIndexEven = index % 2 == 0;
    const companyClass = isIndexEven ? columnClass : `dark-background ${columnClass}`;
    const smallContainerClass = isIndexEven ? '' : 'dark-background';

    const reviewPage = reviewPages && reviewPages[item.company.id];
    const fullCompanyURL = `https://www.moneygeek.com${reviewPage}`;
    const companyColumn = reviewPage ? <a href={fullCompanyURL}>{item.company.name}</a> : item.company.name;

    return (
      <PremiumRankingRow key={index}>
        <div className={`company-body ${companyClass}`}>
          <div className="table full-height-per">
            <div className="vertical-center">
              <div className="company-content company-name mg-top-16 mg-bottom-16">
                {(index++) + 1}. {companyColumn}
              </div>
            </div>
          </div>
        </div>
        <div className={`amount-body col-sm ${smallContainerClass} ${quoteButtonClass}`}>
          <div className="table price-amount full-height-per full-width-per no-bold">
            <div className="vertical-center">
              {item.amBest.stability}
            </div>
          </div>
        </div>
        {
          quotebutton ? getQuoteButton(quotebutton, item.company, smallContainerClass) : null
        }
      </PremiumRankingRow>
    );
  });
};

const getQuoteButton = (quotebutton, company, smallContainerClass) => (
  <div className={`amount-body ${smallContainerClass} get-quote-panel`}>
    {company.url ? <QuoteButton quoteType="Financial Stability" type={quotebutton} displayText="GET QUOTE"
      company={{ company: company.name, insuranceUrl: company.url.primary }} /> : null}
  </div>
);

FinancialStability.propTypes = {

  /**
   * Toggles the quote button column.
   */
  quotebutton: PropTypes.string,

  /**
   * Optional data that fills the component when it's used on a Template page. Overrides state and year parameters.
   */
  data: PropTypes.oneOfType([PropTypes.object, PropTypes.arrayOf(PropTypes.object)]),

  /**
   * Optional list used to transform the company label into an anchor for the company review page.
   */
  reviewPages: PropTypes.oneOfType([PropTypes.object, PropTypes.arrayOf(PropTypes.object)]),

  /**
   * Rows configuration to display on the component: number and text.
   */
  rows: PropTypes.object
};

export default FinancialStability;
