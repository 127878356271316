import React from 'react';
import PropTypes from 'prop-types';
import IconStyle from './Icon.style.js';
import IconSVG from './IconSVG';
import 'remixicon/fonts/remixicon.css';
import './weather-icons.css';

const baseURL = 'https://res.cloudinary.com/moneygeek/image/upload/v1585171420/assets/components/icon';

const iconList = {
  bank: `${baseURL}/bank_xefjcz.svg`,
  carInsurance: `${baseURL}/car_insurance_aud02m.svg`,
  car: `${baseURL}/car_idrljy.svg`,
  car2: `${baseURL}/car2_xvl57e.svg`,
  coins: `${baseURL}/coins_udzbpv.svg`,
  coins2: `${baseURL}/coins_zcfzqj.svg`,
  female: `${baseURL}/female_alfbxk.svg`,
  financialPlanning: `${baseURL}/financial_planing_vjpo8e.svg`,
  find: `${baseURL}/find_nzkmut.svg`,
  graduationCap: `${baseURL}/graduation_cap_gnssrr.svg`,
  male: `${baseURL}/male_zrocgh.svg`,
  money: `${baseURL}/money_kmo8zz.svg`,
  rockingChair: `${baseURL}/rocking_chair_xxweby.svg`,
  veteran: `${baseURL}/veteran_jhcbqx.svg`,
  coffee: `${baseURL}/coffee_gxisnw.svg`,
  coffeeColor: `${baseURL}/coffee_lolta5.svg`,
  computer: `${baseURL}/computer_njvlhk.svg`,
  display2: `${baseURL}/display_2_n7jrhq.svg`,
  uninsured: `${baseURL}/uninsured_jck1df.svg`,
  umbrella: `${baseURL}/umbrella_nbfly0.svg`,
  rain: `${baseURL}/rain_fkozzi.svg`,
  insurance2: `${baseURL}/insurance_2_iixd7m.svg`,
  handcuffs2: `${baseURL}/handcuffs_2_mug1xe.svg`,
  accident2: `${baseURL}/accident_2_vlzgqz.svg`,
  mglogo: `${baseURL}/mg-logo_wxc8wu.svg`,
  eyeglasses: `${baseURL}/eyeglasses_q5shw0.svg`,
  bond: `${baseURL}/bond_veeftd.svg`,
  pieChart: `${baseURL}/pie-chart_ecpipl.svg`,
  grid: `${baseURL}/grid_ua8wuc.svg`,
  barChart: `${baseURL}/bar-chart_djkkvp.svg`,
  airConditioner: `${baseURL}/air-conditioner_ay4lgi.svg`,
  blender: `${baseURL}/blender_z0e81g.svg`,
  bookshelves: `${baseURL}/bookshelves_nsuojr.svg`,
  building: `${baseURL}/building_iibc2e.svg`,
  carpet: `${baseURL}/carpet_qxgs1i.svg`,
  cleanClothes: `${baseURL}/clean-clothes_zjhuly.svg`,
  creditCard: `${baseURL}/credit-card_eycgu0.svg`,
  dish: `${baseURL}/dish_k4dsis.svg`,
  ecoHouse: `${baseURL}/eco-house_ty5myo.svg`,
  engagementRing: `${baseURL}/engagement-ring_a1f10i.svg`,
  fryingPan: `${baseURL}/frying-pan_uotten.svg`,
  garage: `${baseURL}/garage_ttngpd.svg`,
  greenHouse: `${baseURL}/green_house_papers_uybh0e.svg`,
  grill: `${baseURL}/grill_uunz3a.svg`,
  hammer: `${baseURL}/hammer_yk2psa.svg`,
  homeInsurance: `${baseURL}/home-insurance_bzswje.svg`,
  house: `${baseURL}/house_f1rmru.svg`,
  housePapers: `${baseURL}/house_papers_fkiujz.svg`,
  house2: `${baseURL}/house2_xohm98.svg`,
  laptop: `${baseURL}/laptop_eiq1bz.svg`,
  mortgage: `${baseURL}/mortgage_gyrh6d.svg`,
  necklace: `${baseURL}/necklace_hwr4fb.svg`,
  paintingRoller: `${baseURL}/painting-roller_udowrt.svg`,
  photoCamera: `${baseURL}/photo-camera_tcxkfi.svg`,
  refrigerator: `${baseURL}/refrigerator_aei8qm.svg`,
  rentPapers: `${baseURL}/rent-papers_gjaffs.svg`,
  smartphone: `${baseURL}/smartphone_crsddp.svg`,
  sofa: `${baseURL}/sofa_lqlpba.svg`,
  towels: `${baseURL}/towels_e1roj3.svg`,
  tvSet: `${baseURL}/tv-set_jzvuzc.svg`,
  weight: `${baseURL}/weight_w7cdyp.svg`,
  wristwatch: `${baseURL}/wristwatch_clzqls.svg`,
  smallCalculator: `${baseURL}/small-calculator_qyubch.svg`,
  other: `${baseURL}/other_xhwleq.svg`,
  bag: `${baseURL}/bag_hzd7c9.svg`,
  swipe: `${baseURL}/swipe_grey_tlpxmm.svg`
};

/**
 * Icon component
 *
 * This component would show an Icon with adjustable size, alternative text, source and color
 *
 * @param {*} size
 * @param {*} name
 * @param {*} altText
 */
const Icon = ({ name, size, altText, color }) => {
  if (['checkmark', 'close', 'chevron'].includes(name)) {
    return <IconSVG name={name} title={altText} size={size} color={color} />;
  }
  const icon = iconList[`${name}`];

  const nameArr = name.split('-');

  return (
    <IconStyle size={size} color={color}>
      {
        nameArr.length > 1 ?
          nameArr[0] === 'ri' ?
            <i className={name}></i>
            : <i className={`wi ${name}`}></i>
          : <img width={size} src={icon} alt={altText} />
      }
    </IconStyle>
  );
};

Icon.propTypes = {

  /**
   * The shortcode related to an icon. Available shortcodes:
   *
   * bank, carInsurance, car, car2, checkmark, close, coins, female,
   * financialPlanning, find, graduationCap, male, money, rockingChair, veteran
   */
  name: PropTypes.string,

  /**
   * A size for the Icon in pixels.
   */
  size: PropTypes.string,

  /**
   * An alternative text in case the image is not accesible at the moment or the link is broken.
   */
  altText: PropTypes.string,

  /**
   * A color to fill the icon.
   */
  color: PropTypes.string

};

Icon.defaultProps = {
  size: '26px',
  altText: 'This is an icon',
  name: 'bank'
};

export default Icon;
