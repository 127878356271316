import styled from 'styled-components';
import typography from './../../theme_config/typography';

export default styled.div`
  ol {
    margin: 0 0 24px;
    padding: 0;
    counter-reset: item;
  }

  ol > li {
    margin: 0;
    padding: 0 0 0 32px;
    text-indent: -32px;
    margin-left: 40px;
    list-style-type: none;
    counter-increment: item;
    font-size: 16px;
  }

  ol > li:before {
    display: inline-block;
    width: 16px;
    padding-right: 8px;
    font-weight: 700;
    font-family: ${typography.fontFamilyBase};
    font-size: 16px;
    text-align: right;
    line-height: 28px;
    content: counter(item) ".";
  } 
`;
