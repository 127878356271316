import React from 'react';
import PropTypes from 'prop-types';
import { EvaluationContainer } from './BestRanking.style';
import QuoteButton from '../QuoteButton/QuoteButton';

const BestRankingCompany = ({
  userSatisfaction,
  claimsRatings,
  affordability,
  financialStability,
  displayStatus,
  companyData
}) => {
  return (
    <EvaluationContainer display={displayStatus}>
      <div className="evaluationRow">
        <div className="evaluationItem">
          <p className="evaluationValue">{userSatisfaction}/5</p>
          <p className="evaluationDetails">User Satisfaction</p>
        </div>
        <span className="itemDivider" />
        <div className="evaluationItem">
          <p className="evaluationValue">{claimsRatings}/5</p>
          <p className="evaluationDetails">Claims Ratings</p>
        </div>
      </div>
      <div className="evaluationRow">
        <span className="itemDivider" />
        <div className="evaluationItem">
          <p className="evaluationValue">{affordability}/5</p>
          <p className="evaluationDetails">Affordability</p>
        </div>
        <span className="itemDivider" />
        <div className="evaluationItem">
          <p className="evaluationValue">{financialStability}</p>
          <p className="evaluationDetails">Financial Stability</p>
        </div>
      </div>
      <QuoteButton quoteType="Company Score" displayText="get quote" company={companyData} />
    </EvaluationContainer>
  );
};

BestRankingCompany.propTypes = {
  userSatisfaction: PropTypes.number,
  claimsRatings: PropTypes.number,
  affordability: PropTypes.number,
  financialStability: PropTypes.string,
  displayStatus: PropTypes.string,
  companyData: PropTypes.object
};

export default BestRankingCompany;
