import styled from 'styled-components';
import typography from './../../theme_config/typography';
import colors from './../../theme_config/colors';
import sizes from './../../theme_config/sizes';

export const BestRankingContainer = styled.div`
  span.containersCollapse {
    font-family: ${typography.fontFamilyBase};
    color: ${colors.brandingElements};
    font-size: 13px;
    font-weight: 400;
    float: right;
  }

  span.containersCollapse:hover {
    color: ${colors.brandingElementsHover};
    cursor: pointer;
  }

  h2 {
    font-family: ${typography.fontFamilyHeaderBold};
    color: ${colors.text};
    font-size: 40px;
    line-height: 52px;
    margin: 0 0 30px;
  }

  div.SingleRankingContainer:last-of-type {
    margin-bottom: 0;
  }

  @media (max-width: ${sizes.tabletEnd}) {
    h2 {
      font-size: 24px;
      line-height: 30px;
      margin-bottom: 15px;
    }
  }
`;

export const SingleRankingContainer = styled.div.attrs({ className: 'SingleRankingContainer' })`
  margin-bottom: 30px;

  @media (max-width: ${sizes.tabletEnd}) {
    margin-bottom: 15px;
  }
`;

export const TitleContainer = styled.div`
  display: flex;
  position: relative;
  height: 50px;

  div.companyPosition {
    background-color: ${colors.brandingElements};
    color: ${colors.textLight};
    border-radius: 3px 0 0 0;
    position: absolute;
    height: 100%;
    width: 8%;

    & > p {
      font-family: ${typography.fontFamilyHeaderBold};
      font-size: 24px;
      line-height: 30px;
      text-align: center;
      margin: 10px 0 0 0;
    }
  }

  div.containerCollapse:hover {
    cursor: pointer;
  }

  div.titleContent {
    background-color: ${colors.branding};
    color: ${colors.textLight};
    border-radius: 0 3px 0 0;
    position: relative;
    height: 100%;
    left: 8%;
    width: 92%;

    & > div.contentRow {
      position: absolute;
      width: 90%;

      & > span.companyName {
        font-family: ${typography.fontFamilyHeaderBold};
        font-size: 24px;
        line-height: 30px;
        float: left;
        margin-top: 12px;
        margin-left: 15px;
      }

      & > span.companyScore {
        font-family: ${typography.fontFamilyHeader};
        font-size: 20px;
        line-height: 27px;
        float: right;
        margin-top: 12px;
      }

      & > span.companyScore > span {
        font-family: ${typography.fontFamilyHeaderBold};
      }
    }

    & > div.containerCollapse {
      font-family: ${typography.fontFamilyHeaderLight};
      font-size: 40px;
      float: right;
      position: relative;
      height: 100%;
      display: flex;
      align-items: center;
      margin-right: 15px;
    }
  }

  @media (max-width: ${sizes.tabletEnd}) {
    height: 55px;

    div.companyPosition > p {
      font-size: 18px;
      line-height: 24px;
      margin-top: 15px;
    }

    div.titleContent > div.contentRow {
      display: grid;
      width: 90%;

      & > span.companyName {
        font-size: 18px;
        line-height: 24px;
        margin-top: 8px;
      }

      & > span.companyScore {
        font-size: 14px;
        line-height: 14px;
        margin-top: 0;
        margin-left: 15px;
      }
    }

    div.titleContent > div.containerCollapse {
      font-size: 25px;
      margin-right: 12px;
    }
  }
`;

export const EvaluationContainer = styled.div`
  display: ${props => props.display};
  height: 80px;
  border: 1px ${colors.formElementsBorder};
  border-style: none solid solid solid;
  border-radius: 0 0 3px 3px;

  span.itemDivider {
    background-color: ${colors.formElementsBorder};
    position: absolute;
    left: 50%;
    height: 30px;
    width: 1px;
  }

  div.evaluationRow {
    display: flex;
    align-items: center;
    position: relative;
    width: 40%;

    & > div.evaluationItem {
      position: absolute;
      width: 36%;
      margin: 0 7%;

      & > p.evaluationValue {
        font-family: ${typography.fontFamilyHeaderLight};
        color: ${colors.brandingElements};
        font-size: 20px;
        line-height: 26px;
        text-align: center;
        margin: 0;
      }

      & > p.evaluationDetails {
        font-family: ${typography.fontFamilyBase};
        color: ${colors.text};
        font-size: 13px;
        line-height: 16px;
        font-weight: 700;
        text-align: center;
        margin: 0;
      }
    }

    & > div.evaluationItem:last-child {
      left: 50%;
    }

    & > span.itemDivider:first-child {
      left: 0;
    }
  }

  div.QuoteButton {
    display: flex;
    align-items: center;
    width: 18%;

    div {
      width: 100%;
      position: relative;
      margin: 0;

      button {
        font-size: 17px;
        height: 40px;
        min-width: 100%;
        padding: 0;
      }
    }
  }

  @media (max-width: ${sizes.tabletEnd}) {
    display: ${props => props.display === 'flex' ? 'grid' : 'none'};
    height: 182px;

    div.evaluationRow {
      width: 100%;
      margin-top: 20px;

      & > div.evaluationItem {
        width: 42%;
        margin: 0 4%;

        & > p.evaluationValue {
          font-size: 16px;
          line-height: 22px;
        }

        & > p.evaluationDetails {
          font-size: 12px;
          line-height: 14px;
        }
      }

      & > span.itemDivider:first-child {
        display: none;
      }
    }

    div.QuoteButton {
      width: 92%;
      margin: 0 4%;

      button {
        font-size: 15px;
        height: 30px;
      }
    }
  }
`;
