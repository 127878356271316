import styled from 'styled-components';
import sizes from '../../theme_config/sizes';

export const ColumnsWrapper = styled.div`
  @media (min-width: ${sizes.tabletStart}) {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
  }
  > * {
    margin: 20px 0 0 !important;
    width: 100% !important;
    &:first-child {
      margin-top: 0 !important;
    }
    @media (min-width: ${sizes.tabletStart}) {
      flex: 0 0 auto !important;
    }
  }
  ${props => {
    const mobile = parseInt(props.mobile);
    const tablet = parseInt(props.tablet);
    const desktop = parseInt(props.desktop);
    if (desktop === 1) {
      return `
        > * {
          margin-left: 0 !important;
          width: 100% !important;
          &:first-of-type {
            margin-top: 0 !important;
          }
        }
      `;
    } else {
      const spacingDesktop = (desktop * 20 - 20) / desktop;
      const spacingTablet = (tablet * 20 - 20) / tablet;
      const spacingMobile = (mobile * 20 - 20) / mobile;
      let cssMobile = '';
      if (mobile !== 1) {
        cssMobile = `
          @media (max-width: ${sizes.palmEnd}) {
            display: flex !important;
            flex-direction: row !important;
            flex-wrap: wrap !important;
            justify-content: center !important;
          }
        `;
      }
      return `
        ${cssMobile}
        > * {
          @media (max-width: ${sizes.palmEnd}) {
            flex: 0 0 auto !important;
            flex-basis: calc(${100 / mobile}% - ${spacingMobile}px) !important;
            margin-left: 20px !important;
            min-width: auto !important;
            width: calc(${100 / mobile}% - ${spacingMobile}px) !important;
            &:nth-child(-n + ${mobile}) {
              margin-top: 0 !important;
            }
            &:nth-child(${mobile}n + 1) {
              margin-left: 0 !important;
            }
          }
          @media (min-width: ${sizes.tabletStart}) and (max-width: ${
  sizes.tabletEnd
}) {
            flex-basis: calc(${100 / tablet}% - ${spacingTablet}px) !important;
            margin-left: 20px !important;
            min-width: auto !important;
            width: calc(${100 / tablet}% - ${spacingTablet}px) !important;
            &:nth-child(-n + ${tablet}) {
              margin-top: 0 !important;
            }
            &:nth-child(${tablet}n + 1) {
              margin-left: 0 !important;
            }
          }
          @media (min-width: ${sizes.desktopStart}) {
            flex-basis: calc(${100
              / desktop}% - ${spacingDesktop}px) !important;
            margin-left: 20px !important;
            min-width: auto !important;
            width: calc(${100 / desktop}% - ${spacingDesktop}px) !important;
            &:nth-child(-n + ${desktop}) {
              margin-top: 0 !important;
            }
            &:nth-child(${desktop}n + 1) {
              margin-left: 0 !important;
            }
          }
        }
      `;
    }
  }}
`;
