import { Playground, Props } from "docz";
import Stars from "../../../../../components/StarRanking/Stars";
import StarRanking from "../../../../../components/StarRanking/StarRanking";
import StarRankingContent from "../../../../../components/StarRanking/StarRankingContent/StarRankingContent";
import StarRankingItem from "../../../../../components/StarRanking/StarRankingContent/StarRankingItem";
import { satisfaction } from "../../../../../components/StarRanking/MockData";
import * as React from 'react';
export default {
  Playground,
  Props,
  Stars,
  StarRanking,
  StarRankingContent,
  StarRankingItem,
  satisfaction,
  React
};