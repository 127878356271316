import React from 'react';
import PropTypes from 'prop-types';
import { PremiumRankingRow } from './PremiumRanking.style';

const PremiumRankingHeader = ({ showMonthlyValue }) => {
  const columnWidth = showMonthlyValue ? 'col-hf' : 'col-lg';

  return (
    <PremiumRankingRow>
      <div className={`company ${columnWidth} gray-header`}>
        <div className="title title-company">Company</div>
      </div>
      {showMonthlyValue ? getMonthlyPremiumHeaders() : getGeneralPremiumHeaders()}
    </PremiumRankingRow>
  );
};

const getGeneralPremiumHeaders = () => (
  <div className="amount col-sm amount-header">
    <div className="title title-amount">Amount</div>
  </div>
);

const getMonthlyPremiumHeaders = () => (
  <>
    <div className="amount col-sm amount-header">
      <div className="title title-amount">Annually</div>
    </div>
    <div className="amount col-sm amount-header">
      <div className="title title-amount">Monthly</div>
    </div>
  </>
);

PremiumRankingHeader.propTypes = {

  /**
   * Optional parameter that specify if monthly column will appear for each element
   */
  showMonthlyValue: PropTypes.bool
};

export default PremiumRankingHeader;
