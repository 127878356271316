import { Playground, Props } from "docz";
import QuoteButton from "../../../../../components/QuoteButton/QuoteButton";
import data from "../../../../../components/QuoteButton/MockData";
import * as React from 'react';
export default {
  Playground,
  Props,
  QuoteButton,
  data,
  React
};