import styled from 'styled-components';
import { colors, typography, sizes } from './../../theme_config/theme';

export const CrossLinkingContainer = styled.div`
  color: ${colors.text};
  font-family: ${typography.fontFamilyHeaderBold};

  h2 {
    font-size: 40px;
    line-height: 52px;
    margin: 0 0 20px;
  }

  h6 {
    text-transform: uppercase;
    text-align: center;
    font-weight: 700;
    font-size: 18px;
    line-height: 26px;
    margin: 15px 0;
    min-height: 55px;
  }

  a {
    color: inherit;
    text-decoration: none;
    width: 22%;
  }

  a:nth-child(4n + 2) {
    margin: 0 4%;
  }

  a:nth-child(4n + 3) {
    margin-right: 4%;
  }

  div.grid {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: -30px;
  }

  div.item {
    background-color: ${colors.backgroundRow};
    border: 1px solid transparent;
    border-radius: 5px;
    height: auto;
    display: flex;
    overflow: hidden;
    flex-direction: column;
    align-items: center;
    margin-bottom: 30px;
  }

  div.CompanyLogo {
    margin-top: 25px;
  }

  div.item:hover {
    border-color: ${colors.brandingElements};
    color: ${colors.brandingElements};
    cursor: pointer;
  }

  @media (max-width: ${sizes.tabletEnd}) {
    h2 {
      font-size: 24px;
      line-height: 30px;
      margin-bottom: 10px;
    }

    h6 {
      font-size: 14px;
      line-height: 20px;
      min-height: 40px;
    }

    div.grid {
      margin-bottom: -15px;
    }

    div.item {
      margin-bottom: 15px;
    }
  }

  @media (max-width: ${sizes.palmEnd}) {
    h6 {
      margin: 10px 0;
    }

    div.CompanyLogo {
      margin-top: 15px;
    }

    a {
      width: 30%;
      margin: 0 !important;
    }

    a:nth-child(3n + 2) {
      margin: 0 5% !important;
    }

    a:nth-child(n + 7) {
      display: none;
    }
  }

  @media (max-width: ${sizes.phoneEnd}) {
    a {
      width: 47.5%;
      margin: 0 !important;
    }

    a:nth-child(3n + 2) {
      margin: 0 !important;
    }

    a:nth-child(2n + 1) {
      margin: 0 5% 0 0 !important;
    }

    a:nth-child(n + 5) {
      display: none;
    }
  }
`;
