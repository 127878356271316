import React from 'react';
import PropTypes from 'prop-types';
import StarRankingContentStyle from './StarRankingContent.style.js';

/**
 * StarRankingContent component
 *
 * This component would show an StarRankingContent component
 *
 */
const StarRankingContent = ({ children }) => {
  return (
    <StarRankingContentStyle>
      {children}
    </StarRankingContentStyle>
  );
};

StarRankingContent.propTypes = {

  /**
   * Children of the component
   */
  children: PropTypes.node,
};

export default StarRankingContent;
