import styled from 'styled-components';
import { sizes } from './../../theme_config/theme';

export const SpacingContainer = styled.div.attrs({ className: 'Spacing' })`
  margin-bottom: ${props => props.space.desktop};

  @media (max-width: ${sizes.tabletEnd}) {
    margin-bottom: ${props => props.space.tablet};
  }

  @media (max-width: ${sizes.palmEnd}) {
    margin-bottom: ${props => props.space.mobile};
  }
`;
