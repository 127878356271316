import React from 'react';
import PropTypes from 'prop-types';
import StarRankingItemStyle from './StarRankingItem.style.js';
import Stars from '../Stars.js';

/**
 * StarRankingItem component
 *
 * This component would show an StarRankingItem component
 *
 * @param {*} title
 * @param {*} ranking
 * @param {*} starsSize
 * @param {*} starsEmptyColor
 * @param {*} starsRatedColor
 */
const StarRankingItem = ({ title, ranking, starsSize, starsEmptyColor, starsRatedColor }) => {
  return (
    <StarRankingItemStyle>
      <span className="StarRankingItem-title">{title}</span>
      <div className="StarRankingItem-stars">
        <Stars
          ranking={ranking}
          starsSize= {starsSize}
          emptyColor={starsEmptyColor}
          ratedColor={starsRatedColor}>
        </Stars>
      </div>
      <span className="StarRankingItem-ranking-numbers">{ranking}/5</span>
    </StarRankingItemStyle>
  );
};

StarRankingItem.propTypes = {

  /**
   * Text of the title
   */
  title: PropTypes.string,

  /**
   * Number of the stars that are going to be shown
   */
  ranking: PropTypes.string,

  /**
   * Size of the stars expressed in pixels
   */
  starsSize: PropTypes.string,

  /**
   * Color for the stars that remain empty, you can use;
   *
   * CSS Predefined colors: https://www.w3schools.com/cssref/css_colors.asp
   *
   * RGB colors:    starsEmptyColor="rgb(170,170,170)"
   *
   * Hex colors:    starsEmptyColor="#AAAAAA"
   */
  starsEmptyColor: PropTypes.string,

  /**
   * Color for the stars that are filled
   *
   * CSS Predefined colors: https://www.w3schools.com/cssref/css_colors.asp
   *
   * RGB colors:    starsRatedColor="rgb(82,110,255)"
   *
   * Hex colors:    starsRatedColor="#526EFF"
   */
  starsRatedColor: PropTypes.string
};

StarRankingItem.defaultProps = {
  ranking: '0',
  starsSize: '24px',
  starsEmptyColor: '#AAAAAA',
  starsRatedColor: '#526EFF'
};


export default StarRankingItem;
