export const colors = {
  branding: '#1F1B50',
  brandingElements: '#526EFF',
  brandingElementsHover: '#405AE0',
  brandingLight: '#E3E8FD',
  text: '#555555',
  textLight: '#FFFFFF',
  formElementsBorder: '#CBCBCB',
  error: '#FA8072',
  salmon: '#FA8072',
  backgroundRow: '#EFEFF1',
  hover: '#E6E6E6',
  visitedLink: '#DDA0DD'
};

export const typography = {
  fontFamilyBase: '"Source Sans Pro", sans-serif',
  fontFamilyBaseItalic: '"Source Sans Pro Italic", sans-serif',
  fontFamilyHeader: '"Brandon Grotesque", sans-serif',
  fontFamilyHeaderBold: '"Brandon Grotesque Bold", sans-serif',
  fontFamilyHeaderLight: '"Brandon Grotesque Light", sans-serif',
  fontSize: '16px',
  lineHeight: '28px',
};

export const sizes = {
  // Spacing
  bodyGutter: '20px',
  desktopSeparator: '60px',
  mobileSeparator: '30px',

  // MQ Starts
  phoneStart: '415px',
  tabletStart: '768px',
  desktopStart: '1110px',

  // MQ End
  get palmEnd() {
    return `${parseInt(this.tabletStart) - 1}px`;
  },
  get tabletEnd() {
    return `${parseInt(this.desktopStart) - 1}px`;
  },
  get phoneEnd() {
    return `${parseInt(this.phoneStart) - 1}px`;
  },

  // Max Width - All MQ
  get maxWidth() {
    return this.desktopStart;
  }
};
