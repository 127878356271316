/* eslint-disable no-undef */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { StaticTableContainer } from './StaticTable.style';
import { getShowRowsConfiguration } from '../../utils/utils';
import Spacing from '../Spacing/Spacing';

/**
 * StaticTable component.
 *
 * This component should show an HTML static table with some custom attributes
 * @param {string} topHeaderColor
 * @param {string} leftHeaderColor
 * @param {array} topHeaders
 * @param {number} rows
 * @param {string} className
 * @param {array} children
 */
const StaticTable = ({ topHeaderColor, leftHeaderColor, topHeaders, children, rows, className, margin }) => {
  const [client, setClient] = useState(false);

  const mapRows = children => React.Children.map(children, (child, childIndex) => {
    const content = child.props.children;
    const row = React.Children.map(content, (column, columnIndex) => {
      const customClass = column.props.className ? `column-content ${column.props.className}` : 'column-content';

      return <div key={columnIndex} className={customClass}>{column.props.children}</div>;
    });

    return <li key={childIndex}>{row}</li>;
  });

  const showMore = getShowRowsConfiguration(rows, mapRows(children));

  useEffect(() => {
    setClient(true);
  }, []);

  return (
    <Spacing margin={margin}>
      <StaticTableContainer key={client} topHeaderColor={topHeaderColor} leftHeaderColor={leftHeaderColor}>
        <div id="table-container" className={className}>
          <ul>
            <li>
              {topHeaders.map((item, index) => (
                <div className="column-header" key={index}>{item}</div>
              ))}
            </li>
            {showMore.items}
          </ul>
        </div>
        {showMore.button}
      </StaticTableContainer>
    </Spacing>
  );
};

StaticTable.propTypes = {

  /**
   * The color of the Top StaticTable header
   */
  topHeaderColor: PropTypes.string,

  /**
   * Rows configuration to display on the component: number and text.
   */
  rows: PropTypes.object,

  /**
   * The color of the Left StaticTable header
   */
  leftHeaderColor: PropTypes.string,

  /**
   *
   *  The table headers
   */
  topHeaders: PropTypes.array,

  /**
   *
   * Adding options to the styling of the table. Use one of the following:
   *
   * product-attributes, headless-list
   */
  className: PropTypes.string,

  /**
   * Rows of the StaticTable container.
   */
  children: PropTypes.array,

  /**
   * Bottom margin space defined for the component (pixels).
   */
  margin: PropTypes.object
};

StaticTable.defaultProps = {
  topHeaderColor: '#616877',
  leftHeaderColor: '#E6E6E6',
  topHeaders: ['Column 1', 'Column 2', 'Column 3']
};


export default StaticTable;
