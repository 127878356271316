import { Playground, Props } from "docz";
import ListRanking from "../../../../../components/ListRanking/ListRanking";
import data from "../../../../../components/ListRanking/MockData";
import * as React from 'react';
export default {
  Playground,
  Props,
  ListRanking,
  data,
  React
};