import styled from 'styled-components';
import { normalize } from 'styled-normalize';
import typography from './../../theme_config/typography';
import colors from './../../theme_config/colors';
import sizes from './../../theme_config/sizes';
import '../../fonts/_fonts.css';

const PremiumRankingContainer = styled.ul`
  padding: 0;
  margin: 0;
  list-style-type: none;

  div.QuoteButton {
    display: block;

    .btn-primary {
      min-width: 0 !important;
      padding: 15px;
      font-size: 17px !important;

      @media (max-width: ${sizes.tabletEnd}) {
        font-size: 60% !important;
        padding: 10px 5px;
      }

      @media (max-width: ${sizes.phoneEnd}) {
        min-width: 200px !important;
        font-size: 14px !important;
      }
    }
  }
`;

const PremiumRankingRow = styled.li`
  ${normalize}
  display: flex;
  flex-wrap: wrap;
  line-height: inherit !important;

  h2 {
    font-family: ${typography.fontFamilyHeaderBold};
    color: ${colors.text};
    font-size: 40px;
    line-height: 52px;
    margin: 30px;

    @media (max-width: ${sizes.tabletEnd}) {
      font-size: 24px;
      line-height: 30px;
      margin: 15px;
    }
  }

  .amount {
    top: 0px;
    height: 70px;
    width: 20%;
    margin: 0;
    left: 665px;
    display: table;
  }

  .amount-body {
    top: 0px;
    height: auto;
    margin: 0;
    left: 665px;
  }

  .amount-header {
    background-color: rgba(85, 85, 85, 1.0);
  }

  .market-share-title {
    width: 50% !important;

    @media (max-width: ${sizes.phoneEnd}) {
      width: 100% !important;
    }
  }

  .resp-col-sm {
    width: 20% !important;

    @media (max-width: ${sizes.phoneEnd}) {
      width: 40% !important;
    }
  }

  .get-quote-panel {
    width: 20% !important;

    @media (max-width: ${sizes.phoneEnd}) {
      width: 100% !important;
    }

    display:flex;
    align-items:center;
    justify-content:center;
  }

  .get-quote-title {
    @media (max-width: ${sizes.phoneEnd}) {
      width: 0 !important;
    }
  }
  
  .col-sm {
    width: 25%;
  }

  .col-rl {
    width: 40%;
    display: flex;
    flex-wrap: wrap;
  }

  .col-hf {
    width: 50%;
  }

  .col-md {
    width: 60%;
  }
  
  .col-lg {
    width: 75%;
  }

  .col-fl {
    width: 100%;
  }

  .company {
    top: 0px;
    height: 70px;
    margin: 0;
    left: 0px;
    display: table;
  }

  .company-name {
    font-family: ${typography.fontFamilyHeader};
    font-size: 24px;
    @media (max-width: ${sizes.tabletEnd}) {
      font-size: 18px;
    }
    font-weight: bold; 
  }
  
  .mg-top-16 {
    margin-top: 16px;
  }

  .mg-bottom-16 {
    margin-bottom: 16px;
  }
  
  .company-body {
    top: 0px;
    height: auto;
    margin: 0;
    left: 0px;
  }
  
  .company-content {
    margin-left: 20px;
  }

  .company-description {
    font-family: ${typography.fontFamilyBase};
    font-size: 15px;
    @media (max-width: ${sizes.tabletEnd}) {
      font-size: 14px;
    }
    font-weight: 400;
    font-style: normal;
    margin-bottom: 16px;
    color: rgba(85, 85, 85, 1.0);
  }

  .no-mg-bottom{
    margin-bottom: 0px;
  }

  .gray-header {
    background-color: rgba(97, 104, 119, 1.0);
  }

  .header {
    height: 70px;
  }

  .bold {
    font-weight: 700;
  }

  .no-bold {
    font-weight: 100;
  }
  
  .center {
    text-align: center;
  }

  .table {
    display: table;
  }

  .full-height-per {
    height: 100%;
  }

  .full-width-per {
    width: 100%;
  }

  .vertical-center {
    display: table-cell;
    vertical-align: middle;
  }

  .price-amount {
    font-family: ${typography.fontFamilyBase};
    color: rgba(31, 27, 80, 1);
    @media (max-width: ${sizes.tabletEnd}) {
      font-size: 18px;
    }
    font-size: 24px;
    text-align: center;
  }

  .price-description {
    font-family: ${typography.fontFamilyBase};
    color: rgba(85, 85, 85, 1);
    font-size: 13px;
    @media (max-width: ${sizes.tabletEnd}) {
      font-size: 12px;
    }
    font-weight: 400;
    text-align: center;
    margin-top: -6px;
  }

  .title {
    font-family: ${typography.fontFamilyBase};
    color: white;
    font-weight: 700;
    font-style: normal;
    font-size: 15px;

    @media (max-width: ${sizes.tabletEnd}) {
      font-size: 14px;
    }
  }

  .title-company {
    display: table-cell;
    vertical-align: middle;
    padding-left: 20px;
  }

  .title-amount {
    display: table-cell;
    vertical-align: middle;
    text-align: center;
    
  } 

  .dark-background {
    background-color: rgba(239, 239, 241, 1.0);
  }
`;

export { PremiumRankingContainer, PremiumRankingRow };
