export const data = {
  headers: ['Suspendisse interdum', 'Ipsum dolor sit amet'],
  rows: [
    ['Suspendisse interdum, arcu sed tincidunt pharetra.', 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.'],
    ['Lorem ipsum dolor sit amet.', 'Suspendisse interdum, arcu sed tincidunt pharetra.'],
    ['Suspendisse interdum, arcu sed tincidunt pharetra.', 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.'],
    ['Lorem ipsum dolor sit amet.', 'Suspendisse interdum, arcu sed tincidunt pharetra.'],
    ['Suspendisse interdum, arcu sed tincidunt pharetra.', 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.'],
    ['Lorem ipsum dolor sit amet.', 'Suspendisse interdum, arcu sed tincidunt pharetra.'],
    ['Suspendisse interdum, arcu sed tincidunt pharetra.', 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.'],
    ['Lorem ipsum dolor sit amet.', 'Suspendisse interdum, arcu sed tincidunt pharetra.'],
    ['Suspendisse interdum, arcu sed tincidunt pharetra.', 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.'],
    ['Lorem ipsum dolor sit amet.', 'Suspendisse interdum, arcu sed tincidunt pharetra.'],
    ['Suspendisse interdum, arcu sed tincidunt pharetra.', 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.'],
    ['Lorem ipsum dolor sit amet.', 'Suspendisse interdum, arcu sed tincidunt pharetra.'],
    ['Suspendisse interdum, arcu sed tincidunt pharetra.', 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.'],
    ['Lorem ipsum dolor sit amet.', 'Suspendisse interdum, arcu sed tincidunt pharetra.'],
  ]
};
