import styled from 'styled-components';

export default styled.div`
  display: inline-block;
  color: ${props => props.color ? props.color : '#1F1B50'};
  font-size: ${props => props.size ? props.size : '17px'};
  vertical-align:top;
  height: ${props => props.size};
  text-align: center;
  
  img{
    height: ${props => props.size};
    width: ${props => props.size};
    max-width:initial;
  }

  > * {
    vertical-align:top;
    line-height: ${props => props.size};
  }
`;
