import React from 'react';
import PropTypes from 'prop-types';
import { ColumnsWrapper } from './Columns.style';


const Columns = props => {
  return (
    <ColumnsWrapper {...props} css={props.css}>
      {props.children}
    </ColumnsWrapper>
  );
};

Columns.propTypes = {
  mobile: PropTypes.string,
  tablet: PropTypes.string,
  desktop: PropTypes.string,
  css: PropTypes.object,
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
};

Columns.defaultProps = {
  mobile: '1',
  tablet: '2',
  desktop: '3',
};

export default Columns;
