import { Playground, Props } from "docz";
import BestRanking from "../../../../../components/BestRanking/BestRanking";
import data from "../../../../../components/BestRanking/MockData";
import * as React from 'react';
export default {
  Playground,
  Props,
  BestRanking,
  data,
  React
};