import styled from 'styled-components';
import typography from './../../theme_config/typography';
import colors from './../../theme_config/colors';
import sizes from './../../theme_config/sizes';

export const QuoteContainer = styled.div`
  width: ${props => props.maxWidth};
  text-align: ${props => props.orientation};

  svg {
    height: 30px;
    width: 38px;

    image {
      height: 30px;
      width: 38px;
    }
  }

  @media (max-width: ${sizes.tabletEnd}) {
    width: auto;

    svg {
      height: 23px;
      width: 29px;

      image {
        height: 23px;
        width: 29px;
      }
    }
  }
`;

export const DoubleQuoteContainer = styled.div`
  display: inline-flex;
  align-items: center;
  text-transform: uppercase;

  div.doubleQuoteText {
    font-family: ${typography.fontFamilyHeaderBold};
    color: ${colors.text};

    * {
      font-size: 40px;
      line-height: 48px;
      margin: 0;
    }
  }

  div.doubleQuoteLeftSymbol {
    margin-right: 15px;
  }

  div.doubleQuoteRightSymbol {
    margin-left: 15px;
    transform: rotate(180deg);
  }

  @media (max-width: ${sizes.tabletEnd}) {
    div.doubleQuoteText > * {
      font-size: 22px;
      line-height: 28px;
    }
  }
`;

export const SingleQuoteContainer = styled.div`
  display: inline-flex;
  align-items: baseline;

  div.singleQuoteText {
    font-family: ${typography.fontFamilyBase};
    color: ${colors.text};

    * {
      font-size: 16px;
      line-height: 28px;
      font-weight: 400;
      margin: 0;
    }
  }

  div.singleQuoteText > div.expertName {
    font-weight: 700;
  }

  div.singleQuoteText > div.expertDetails {
    font-family: ${typography.fontFamilyBaseItalic};
  }

  div.singleQuoteSymbol {
    margin-right: 30px;

    svg {
      padding-top: 10px;
      vertical-align: top;
    }
  }

  @media (max-width: ${sizes.tabletEnd}) {
    div.singleQuoteText > * {
      font-size: 14px;
      line-height: 24px;
    }

    div.singleQuoteSymbol {
      margin-right: 15px;
    }
  }
`;
