import styled from 'styled-components';
import { colors, sizes } from '../../theme_config/theme';

export const LogoContainer = styled.div.attrs({ className: 'CompanyLogo' })`
  border-radius: 50%;
  border: 1px solid ${props => props.color ? props.color : colors.formElementsBorder};
  width: ${props => props.type === 'banner' ? '200px' : '130px'};
  height: ${props => props.type === 'banner' ? '200px' : '130px'};
  display: flex;
  align-items: center;
  justify-content: center;

  .image-container {
    display: flex;
    overflow: hidden;
    background-color: ${colors.textLight};
    border-radius: 50%;
    border: 1px solid ${props => props.color ? props.color : colors.formElementsBorder};
    width: ${props => props.type === 'banner' ? '120px' : '72px'};
    height: ${props => props.type === 'banner' ? '120px' : '72px'};
    padding: ${props => props.type === 'banner' ? '30px' : '20px'};
    margin: 8px;
  }

  .logo {
    object-fit: contain;
    margin: 0 auto;
    width: 100%;
    height: 100%;
  }

  @media (max-width: ${sizes.tabletEnd}) {
    width: 130px;
    height: 130px;

    .image-container {
      width: 72px;
      height: 72px;
      padding: 20px;
    }
  }

  @media (max-width: ${sizes.palmEnd}) {
    width: 90px;
    height: auto;
    border: none;

    .image-container {
      width: 100%;
      height: 60px;
      padding: 13px;
      margin: 0;
    }
  }

  @media (max-width: ${sizes.phoneEnd}) {
    width: 100px;

    .image-container {
      height: 70px;
    }
  }
`;
