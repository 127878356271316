import React from 'react';
import PropTypes from 'prop-types';
import Providers from './traffic/providers';
import Button from '../Button/Button';
import QuinStreetQuoteButton from './QuinStreetQuoteButton';
import { QuoteButtonContainer } from './QuoteButton.style';
import { usePersistedProvider } from './traffic/persistence';
import {
  MEDIA_ALPHA_VARS,
  mediaAlphaDirectLink,
  mediaAlphaLoadElement,
  googleAnalyticsLoad,
  googleAnalyticsLoadNoLabel,
  getGAInsurance
} from '../../utils/utils';
import { QuotesWidgetModal } from '../QuotesWidget/QuotesWidget';

const AUTO_TYPE = 'auto';
const RENTERS_TYPE = 'renters';

/**
 * TargetedQuoteButton component.
 *
 * It is a subcomponent used by QuoteButton component.
 *
 * @param {object} reference
 * @param {string} buttonText
 * @param {object} companyData
 */
// eslint-disable-next-line react/prop-types
const TargetedQuoteButton = ({ type, quoteType, reference, buttonText, companyData, show, setShowModal }) => {
  const currentProvider = usePersistedProvider('currentProvider');
  const isQuinStreet = currentProvider === Providers.QUIN_STREET;
  const isAutoType = type === AUTO_TYPE;
  const isRentersType = type === RENTERS_TYPE;

  if (isQuinStreet && isAutoType) {
    return (
      <QuinStreetQuoteButton
        type={type}
        quoteType={quoteType}
        show={show}
        setShowModal={setShowModal}
        buttonText={buttonText}
        isTargeted={true}
      />
    );
  }

  const showModal = (show, setShowModal) => {
    setShowModal(!show);
  };

  const targetedQuoteEvent = (type, quoteType, reference) => {
    const zipCode = '';
    const isRentersType = type === RENTERS_TYPE;

    if (isRentersType) {
      mediaAlphaLoadElement(zipCode, MEDIA_ALPHA_VARS[type].targetedQuoteId, 'ad_unit_placeholder');
      showModal(show, setShowModal);
    } else {
      mediaAlphaDirectLink(zipCode, MEDIA_ALPHA_VARS[type].targetedQuoteId);
      reference.current.click();
    }

    googleAnalyticsLoad('Quote', 'Insurance', getGAInsurance(type, quoteType));
    googleAnalyticsLoadNoLabel('Monetization Provider', 'MediaAlpha');
  };

  const placementId = type && MEDIA_ALPHA_VARS[type] && MEDIA_ALPHA_VARS[type].targetedQuoteId;

  return (
    <QuoteButtonContainer>
      {isRentersType && <QuotesWidgetModal show={show} setShowModal={setShowModal} showModal={showModal} />}
      <Button className='btn-primary' onClick={() => targetedQuoteEvent(type, quoteType, reference)}>
        {buttonText}
      </Button>
      <a href='#'
        id='media-link'
        className='media-alpha-direct-link'
        data-ma-carrier={companyData.company}
        data-ma-default-url={companyData.insuranceUrl ? companyData.insuranceUrl : null}
        data-ma-placement-id={placementId}
        ref={(link) => (reference.current = link)}
        onClick={(event) => event.preventDefault()}>
      </a>
    </QuoteButtonContainer>
  );
};

TargetedQuoteButton.propTypes = {
  reference: PropTypes.object,
  buttonText: PropTypes.string,
  companyData: PropTypes.object,
  type: PropTypes.string,
};

export default TargetedQuoteButton;
