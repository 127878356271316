import React from 'react';
import Button from '../Button/Button';
import { QuoteButtonContainer } from './QuoteButton.style';
import { addQuinStreetConfig, quinStreetEvent, QuinStreetQuoteModal } from '../../utils/quoteutils';
import { googleAnalyticsLoad, googleAnalyticsLoadNoLabel, getGAInsurance } from '../../utils/utils';

/**
 * QuinStreetQuoteButton component.
 *
 * This is a helper component used by GeneralQuoteButton.
 *
 * @param {function} showModal
 * @param {boolean} show
 * @param {function} setShowModal
 * @param {string} buttonText
 */
// eslint-disable-next-line react/prop-types
const QuinStreetQuoteButton = ({ type, quoteType, show, setShowModal, buttonText, setError, error, isTargeted }) => {
  const showModal = (show, setShowModal) => {
    setShowModal(!show);
  };

  const quinStreetTargetedEvent = (show, setShowModal) => {
    showModal(show, setShowModal);
    googleAnalyticsLoad('Quote', 'Insurance', getGAInsurance(type, quoteType));
    googleAnalyticsLoadNoLabel('Monetization Provider', 'QuinStreet');
    addQuinStreetConfig();
  };

  if (isTargeted) {
    return (
      <QuoteButtonContainer>
        <QuinStreetQuoteModal
          show={show}
          showModal={showModal}
          setShowModal={setShowModal} />

        <Button className='btn-primary' onClick={() => quinStreetTargetedEvent(show, setShowModal)}>
          {buttonText}
        </Button>
      </QuoteButtonContainer>
    );
  }

  return (
    <QuoteButtonContainer>
      <QuinStreetQuoteModal
        show={show}
        showModal={showModal}
        setShowModal={setShowModal} />
      <form onSubmit={(event) => quinStreetEvent(event, show, setShowModal, setError, showModal)}>
        <div>
          <fieldset className='field-zip'>
            <input type='text'
              autoComplete='off'
              pattern='[0-9]*'
              name='zip'
              onChange={() => setError(false)}
              title='Five numbers zip code'
              placeholder='Your Zip Code'
            />
          </fieldset>
          <p className='error'>{error ? 'You must provide a valid zip code' : null}</p>
        </div>
        <Button className='btn-primary' type='submit'>{buttonText}</Button>
      </form>
    </QuoteButtonContainer>
  );
};

export default QuinStreetQuoteButton;
