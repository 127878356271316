import React from 'react';
import PropTypes from 'prop-types';
import { CompleteBadgeContainer, ScoreBadgeContainer } from './Badge.style';

/**
 * Badge component.
 *
 * This component would show a badge container using the parameters for title, year and first/second subtitles.
 */
const Badge = ({ type, content }) => {
  switch (type) {
    case 'complete':
      return (
        <CompleteBadgeContainer>
          <div className="badge">
            <div className="content">
              <img className="icon" alt="icon"
                src="https://res.cloudinary.com/moneygeek/image/upload/v1588889629/assets/256x256_MG_Icon.svg" />
              <img className="ribbon" alt="ribbon"
                src="https://res.cloudinary.com/moneygeek/image/upload/v1591046164/assets/ribon_o6dqja.svg" />
              <span className="ribbon text">{content.title}</span>
              <span className="label title">{content.firstSubtitle}</span>
              <span className="label">{content.secondSubtitle}</span>
              <span className="label year">{content.year}</span>
            </div>
          </div>
        </CompleteBadgeContainer>
      );
    case 'score':
      return (
        <ScoreBadgeContainer>
          <div className="badge">
            <div className="content">
              <img className="icon" alt="icon"
                src="https://res.cloudinary.com/moneygeek/image/upload/v1588889629/assets/256x256_MG_Icon.svg" />
              <img className="ribbon" alt="ribbon"
                src="https://res.cloudinary.com/moneygeek/image/upload/v1591046164/assets/ribon_o6dqja.svg" />
              <span className="ribbon text">{content.title}</span>
              <span className="score">{content.score}</span>
            </div>
          </div>
        </ScoreBadgeContainer>
      );
    default:
      return <></>;
  }
};

Badge.propTypes = {

  /**
   * Title for the component (inside the ribbon).
   */
  type: PropTypes.oneOf(['complete', 'score']),

  /**
   * Badge content: title, subtitles, score and year. This content depends on the type defined.
   */
  content: PropTypes.object
};

Badge.defaultProps = {
  type: 'complete'
};

export default Badge;
