import React from 'react';
import PropTypes from 'prop-types';

import BoxStyle from './Box.style.js';

/**
 * Box component
 *
 * This component will show a box using the styles and events provided by params and
 * children elements that are inside of it.
 *
 * @param {*} className
 * @param {*} contentAlign
 * @param {*} width
 * @param {*} children
*/
const Box = ({ className, children, fullWidth, unsetHeight, float }) => (
  <BoxStyle componentClassName={className} fullWidth={fullWidth} unsetHeight={unsetHeight} float={float}>
    <div className={className} id="inner-div">
      <div id="inner-margin-div">
        {children}
      </div>
    </div>
  </BoxStyle>
);

Box.propTypes = {

  /**
  * Classes available for the button use one of these:
  *
  * box-outlined, box-solid
  * box-primary, box-secondary, text-left, text-center, text-right
  *
  */
  className: PropTypes.string,

  /**
  * When using a Box inside a <p> tag.
  *
  * Can be: "left" or "right"
  *
  */
  float: PropTypes.string,

  /**
  * Determines if the box should be full width
  */
  fullWidth: PropTypes.bool,

  /**
  * Determines if the box should have a min-height
  */
  unsetHeight: PropTypes.bool,

  /**
  * Inner elements of the box.
  */
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
};

Box.defaultProps = {
  className: 'box-outlined box-primary',
  fullWidth: false,
  unsetHeight: false
};

export default Box;
