import { Playground, Props } from "docz";
import Box from "../../../../../components/Box/Box";
import Icon from "../../../../../components/Icon/Icon";
import BreakLine from "../../../../../components/BreakLine/BreakLine";
import Columns from "../../../../../components/Columns/Columns";
import * as React from 'react';
export default {
  Playground,
  Props,
  Box,
  Icon,
  BreakLine,
  Columns,
  React
};