import styled from 'styled-components';
import { colors, typography, sizes } from './../../theme_config/theme';

export const QuoteButtonContainer = styled.div.attrs({ className: 'QuoteButton' })`
  display: flex;
  text-align: center; 
  color: ${colors.text};
  font-family: ${typography.fontFamilyBase};
  width: 100%;

  h3 {
    font-family: ${typography.fontFamilyHeaderLight};
    font-size: 32px;
    line-height: 42px;
    margin: 10px;
  }

  form {
    display: block;
  }

  .field-zip {
    margin: 10px;
    padding: 0;
    min-width: 162px;
    position: relative;
    border-style: none;

    &::after {
      background-color: #e1e1e5;
      color: green;
      content: "";
      display: block;
      height: calc(100% - 20px);
      position: absolute;
      right: 45px;
      top: 10px;
      width: 1px;
      z-index: 1;
    }

    input {
      color: ${colors.text};
      background-image: 
      url("https://res.cloudinary.com/moneygeek/image/upload/v1582232089/assets/widget-location-pin_yhsttw.svg");
      background-position: right 12px center;
      background-repeat: no-repeat;
      background-size: 20px;
      margin: 0;
      font-style: italic;
      box-sizing: border-box;
      display: block;
      font-size: 16px;
      border-radius: 5px;
      border-width: 1px;
      border-style: solid;
      border-color: ${colors.formElementsBorder};
      padding: 10px 46px 10px 15px;
      transition: border-color 0s ease 0s;
    }
  }
  
  .error {
    color: ${colors.error};
    font-size: 16px;
    margin: 0;
  }

  div > button.btn-primary {
    padding: 10px 25px;
  }

  @media (max-width: ${sizes.tabletEnd}) {
    h3 {
      font-size: 22px;
      line-height: 28px;
    }
  }
`;
