import React from 'react';
import Providers from './traffic/providers';
import Button from '../Button/Button';
import Modal from '../Modal/Modal';
import Spinner from 'react-activity/lib/Spinner';
import 'react-activity/lib/Spinner/Spinner.css';
import QuinStreetQuoteButton from './QuinStreetQuoteButton';
import { QuoteButtonContainer } from './QuoteButton.style';
import { usePersistedProvider } from './traffic/persistence';
import {
  MEDIA_ALPHA_VARS,
  mediaAlphaLoadElement,
  googleAnalyticsLoad,
  googleAnalyticsLoadNoLabel,
  getGAInsurance
} from '../../utils/utils';

const AUTO_TYPE = 'auto';

/**
 * GeneralQuote Button Component.
 *
 * Used on QuoteButton component.
 *
 * @param {*} type
 * @param {*} buttonText
 * @param {*} error
 * @param {*} setError
 * @param {*} show
 * @param {*} setShowMod
 *
 */
// eslint-disable-next-line react/prop-types
const GeneralQuoteButton = ({ type, quoteType, buttonText, error, setError, show, setShowModal }) => {
  const currentProvider = usePersistedProvider('currentProvider');
  const isQuinStreet = currentProvider === Providers.QUIN_STREET;
  const isAutoType = type === AUTO_TYPE;

  const showModal = (show, setShowModal) => {
    setShowModal(!show);
  };

  const generalQuoteEvent = (event, type, quoteType, setError, show, setShowModal) => {
    setError(false);
    event.preventDefault();
    const zip = event.target.zip.value;

    // Return & Error if no zip
    if (!zip || zip.length < 5 || zip.length > 5) {
      setError(true);
      return;
    }

    mediaAlphaLoadElement(zip, MEDIA_ALPHA_VARS[type].generalQuoteId, 'ad_unit_placeholder');
    googleAnalyticsLoad('Quote', 'Insurance', getGAInsurance(type, quoteType));
    googleAnalyticsLoadNoLabel('Monetization Provider', 'MediaAlpha');
    showModal(show, setShowModal);
  };

  if (isQuinStreet && isAutoType) {
    return (
      <QuinStreetQuoteButton
        type={type}
        quoteType={quoteType}
        show={show}
        setShowModal={setShowModal}
        buttonText={buttonText}
        setError={setError}
        error={error}
      />
    );
  }

  return (
    <QuoteButtonContainer>
      <Modal active={show} onClick={() => showModal(show, setShowModal)} title='Insurance Quote'>
        <div id='ad_unit_placeholder'>
          <Spinner size={32} />
        </div>
      </Modal>

      <form onSubmit={(event) => generalQuoteEvent(event, type, quoteType, setError, show, setShowModal)}>
        <div>
          <fieldset className='field-zip'>
            <input type='text'
              autoComplete='off'
              pattern='[0-9]*'
              name='zip'
              onChange={() => setError(false)}
              title='Five numbers zip code'
              placeholder='Your Zip Code'
            />
          </fieldset>
          <p className='error'>{error ? 'You must provide a valid zip code' : null}</p>
        </div>
        <Button className='btn-primary' type='submit'>{buttonText}</Button>
      </form>
    </QuoteButtonContainer>
  );
};

export default GeneralQuoteButton;
