import React from 'react';
import PropTypes from 'prop-types';
import { Image } from 'cloudinary-react';
import { LogoContainer } from './CompanyLogo.style';

/**
 * CompanyLogo component.
 *
 * This component would show logo container using the image and color
 * pased as parameters and applying a border of the same color.
 */
const CompanyLogo = ({ image, displayType, borderColor }) => {
  const baseURL = 'MoneyGeek.com/Company%20Logos/';
  return (
    <LogoContainer color={borderColor} type={displayType}>
      <div className='image-container'>
        {image && <Image className="logo"
          secure="true"
          cloudName="moneygeek"
          responsive
          width="auto"
          crop="scale"
          responsiveUseBreakpoints="true"
          publicId={baseURL + image} />}
      </div>
    </LogoContainer>
  );
};

CompanyLogo.propTypes = {

  /**
   * Image name to load on the component.
   */
  image: PropTypes.string,

  /**
   * Display type for the component. Banner type gives extra size respect to regular.
   */
  displayType: PropTypes.oneOf(['regular', 'banner']),

  /**
   * Border color to apply on the component.
   */
  borderColor: PropTypes.string
};

CompanyLogo.defaultProps = {
  displayType: 'regular',
  borderColor: '#CBCBCB'
};

export default CompanyLogo;
