/* eslint-disable no-undef */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Button from '../Button/Button';
import Modal from '../Modal/Modal';
import { CompactQuotesWidget, FullWidthQuotesWidget, ColoredQuotesWidget } from './QuotesWidget.style';
import {
  MEDIA_ALPHA_VARS,
  getGAInsurance,
  mediaAlphaLoadElement,
  googleAnalyticsLoad,
  googleAnalyticsLoadNoLabel
} from '../../utils/utils';
import Spinner from 'react-activity/lib/Spinner';
import 'react-activity/lib/Spinner/Spinner.css';
import Spacing from '../Spacing/Spacing';
import Providers from '../QuoteButton/traffic/providers';
import { addQuinStreetScript, quinStreetEvent, QuinStreetQuoteModal } from '../../utils/quoteutils';
import { usePersistedProvider } from '../QuoteButton/traffic/persistence';

const AUTO_TYPE = 'auto';
const BASE_URL = 'https://res.cloudinary.com/moneygeek/image/upload';
const AUTO_COMPACT_ICON = `${BASE_URL}/v1582232104/assets/widget-auto-insurance_wtbksc.svg`;
const AUTO_FULL_ICON = `${BASE_URL}/v1585171420/assets/components/icon/car2_xvl57e.svg`;
const AUTO_COLORED_ICON = `${BASE_URL}/v1609340862/MoneyGeek.com/assets/quote_widget_car_nlsons.png`;
const HOME_COMPACT_ICON = `${BASE_URL}/v1600207943/assets/components/icon/mortgage_rates_2_white_vb9vwz.svg`;
const HOME_FULL_ICON = `${BASE_URL}/v1600207943/assets/components/icon/mortgage_rates_2_etpjye.svg`;
const HOME_COLORED_ICON = `${BASE_URL}/v1609340862/MoneyGeek.com/assets/quote_widget_house_trs6wv.png`;

const getWidgetTitle = (type, title) => {
  const capitalizedType = type.charAt(0).toUpperCase() + type.slice(1);
  const defaultTitle = `Compare ${capitalizedType} Insurance Rates`;

  return title ? title : defaultTitle;
};

const getWidgetDescription = (type, description) => {
  const defaultDescription = `Ensure you're getting the best rate for your ${type} insurance. 
  Compare quotes from the top insurance companies.`;
  return description ? description : defaultDescription;
};

const handleWidgetSubmit = (ev, type, quoteType, setError, show, setShowModal, isQuinStreetWidget) => {
  if (isQuinStreetWidget) {
    googleAnalyticsLoad('Quote', 'Insurance', getGAInsurance(type, quoteType));
    googleAnalyticsLoadNoLabel('Monetization Provider', 'QuinStreet');
    quinStreetEvent(ev, show, setShowModal, setError, showModal);
    return;
  }

  setError(false);
  ev.preventDefault();
  const zip = ev.target.zip.value;

  // Return & Error if no zip
  if (!zip || zip.length < 5 || zip.length > 5) {
    setError(true);
    return;
  }

  mediaAlphaLoadElement(zip, MEDIA_ALPHA_VARS[type].generalQuoteId, 'ad_unit_placeholder');
  googleAnalyticsLoad('Quote', 'Insurance', getGAInsurance(type, quoteType));
  googleAnalyticsLoadNoLabel('Monetization Provider', 'MediaAlpha');
  showModal(show, setShowModal);
};

const getQuotesForm = (type, quoteType, error, setError, show, setShowModal, isQuinStreetWidget, fullWidth) => {
  const btnClassName = fullWidth ? 'btn-primary' : 'btn-secondary';

  return <form onSubmit={e =>
    handleWidgetSubmit(e, type, quoteType, setError, show, setShowModal, isQuinStreetWidget)}>
    <div>
      <fieldset className="field-zip">
        <input
          type="text"
          autoComplete='off'
          pattern="[0-9]*"
          name="zip"
          onChange={() => setError(false)}
          title="Five numbers zip code"
          placeholder="Your Zip Code"
        />
      </fieldset>
      <p className="error">{error ? 'You must provide a valid zip code' : null}</p>
    </div>
    <Button className={btnClassName} type="submit">COMPARE QUOTES</Button>
  </form>;
};

const getQuotesModal = (show, setShowModal, isQuinStreetWidget) => {
  return isQuinStreetWidget ? <QuinStreetQuoteModal show={show} setShowModal={setShowModal} showModal={showModal} />
    : <QuotesWidgetModal show={show} setShowModal={setShowModal} showModal={showModal} />;
};

const compactWidget = (title, description, icon, quotesModal, quotesForm) => {
  return (
    <CompactQuotesWidget>
      {quotesModal}
      <div className="title"><h4>{title}</h4></div>
      <div className="container">
        <img src={icon} alt="Insurance Rates" width="32" height="32" className="compact-image" />
        <p>{description}</p>
        {quotesForm}
      </div>
    </CompactQuotesWidget>
  );
};

const fullWidthWidget = (title, description, icon, quotesModal, quotesForm, error, colored, margin) => {
  if (colored) {
    return <Spacing margin={margin}>
      <ColoredQuotesWidget error={error}>
        {quotesModal}
        <div className="text-container">
          <span className="title">{title}</span>
          <p>{description}</p>
        </div>
        {quotesForm}
        <img src={icon} alt="Insurance Rates" className="badge" />
      </ColoredQuotesWidget>
    </Spacing>;
  } else {
    return (
      <Spacing margin={margin}>
        <FullWidthQuotesWidget>
          {quotesModal}
          <img src={icon} alt="Insurance Rates" width="32" height="32" className="full-width-image" />
          <div className="text-container">
            <h4>{title}</h4>
            <p>{description}</p>
          </div>
          {quotesForm}
        </FullWidthQuotesWidget>
      </Spacing>
    );
  }
};

const showModal = (show, setShowModal) => {
  setShowModal(!show);
};

const QuotesWidget = ({ type, quoteType, title, description, fullWidth, colored, margin }) => {
  const currentProvider = usePersistedProvider('currentProvider');
  const [error, setError] = useState(false);
  const [show, setShowModal] = useState(false);
  const [client, setClient] = useState(false);
  const isQuinStreet = currentProvider === Providers.QUIN_STREET;
  const isAutoType = type === AUTO_TYPE;

  const getWidgetIcon = (type, fullWidth, colored) => {
    const isColored = fullWidth && colored;

    if (type === 'auto') {
      return isColored ? AUTO_COLORED_ICON : fullWidth ? AUTO_FULL_ICON : AUTO_COMPACT_ICON;
    } else {
      return isColored ? HOME_COLORED_ICON : fullWidth ? HOME_FULL_ICON : HOME_COMPACT_ICON;
    }
  };

  useEffect(() => {
    // eslint-disable-next-line no-undef
    const script = document.createElement('script');
    script.src = 'https://res.cloudinary.com/moneygeek/raw/upload/v1605630850/libraries/serve_p4mqe6.js';
    script.crossOrigin = 'anonymous';
    script.async = true;
    document.head.appendChild(script);
    setClient(true);

    /** QuinStreet */
    addQuinStreetScript();
  }, []);

  const widgetTitle = getWidgetTitle(type, title);
  const widgetDescription = getWidgetDescription(type, description);
  const widgetIcon = getWidgetIcon(type, fullWidth, colored);

  const isQuinStreetWidget = isQuinStreet && isAutoType;
  const quotesModal = getQuotesModal(show, setShowModal, isQuinStreetWidget);
  const quotesForm = getQuotesForm(type, quoteType, error, setError, show, setShowModal, isQuinStreetWidget, fullWidth);

  return (
    <div key={client}>
      {fullWidth ?
        fullWidthWidget(widgetTitle, widgetDescription, widgetIcon, quotesModal, quotesForm, error, colored, margin)
        : compactWidget(widgetTitle, widgetDescription, widgetIcon, quotesModal, quotesForm)}
    </div>
  );
};

// eslint-disable-next-line react/prop-types
const QuotesWidgetModal = ({ show, setShowModal, showModal }) => (
  <Modal active={show} onClick={() => showModal(show, setShowModal)} title='Insurance Quote'>
    <div id="ad_unit_placeholder">
      <Spinner size={32} />
    </div>
  </Modal>
);

QuotesWidget.propTypes = {

  /**
   * Insurance type to enable MediaAlpha integration.
   */
  type: PropTypes.oneOf(['auto', 'home', 'renters']),

  /**
   * Quote type for Google Analytics event.
   */
  quoteType: PropTypes.string,

  /**
   * The title of the QuotesWidget
   */
  title: PropTypes.string,

  /**
   * The description of the QuotesWidget
   */
  description: PropTypes.string,

  /**
   * True if the component should render full width.
   */
  fullWidth: PropTypes.bool,

  /**
   * True if the component should render full width.
   */
  colored: PropTypes.bool,

  /**
   * Bottom margin space defined for the component (pixels).
   */
  margin: PropTypes.object
};

QuotesWidget.defaultProps = {
  type: 'auto',
  fullWidth: false,
  colored: false
};

export { QuotesWidgetModal };
export default QuotesWidget;
