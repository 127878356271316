/* eslint-disable new-cap */
/* eslint-disable no-undef */
import ReactGA from 'react-ga';
import ShowMore from '../components/ShowMore/ShowMore';

const apiRequest = (requestMethod, endpoint, params) => ({
  method: requestMethod,
  url: `https://${process.env.API_ENV}-api.moneygeek.com/${endpoint}`,
  headers: {
    'x-api-key': process.env.X_API_KEY
  },
  params: params
});

const googleAnalyticsLoad = (eventCategory, eventAction, eventLabel) => {
  ReactGA.initialize('UA-69718893-1', { cookieDomain: 'none', siteSpeedSampleRate: 100 });
  ReactGA.ga('send', 'event', eventCategory, eventAction, eventLabel);
};

const googleAnalyticsLoadNoLabel = (eventCategory, eventAction) => {
  ReactGA.initialize('UA-69718893-1', { cookieDomain: 'none', siteSpeedSampleRate: 100 });
  ReactGA.ga('send', 'event', eventCategory, eventAction);
};

const MEDIA_ALPHA_VARS = {
  auto: {
    targetedQuoteId: 'kbYj2bOtnUUSEBB0jGvXtBQijZUjvA',
    generalQuoteId: 'RLvPsexrKK2O7liJBVsknJ3xMzNdZQ'
  },
  home: {
    targetedQuoteId: 'sdfrrB37frzinB_VIVZStqHrtzGRGw',
    generalQuoteId: 'wCVCXn8l6aynHH70QpJliEst0KDCsA'
  },
  renters: {
    targetedQuoteId: '-qetgye-aK877UVYjsSw6Tdc9aJ7zA',
    generalQuoteId: '-09rbaR3ACmfQ-r6We7-0Y8jffgaCQ'
  }
};

const mediaAlphaLoad = (zipCode, placementId) => {
  window.MediaAlphaExchange = {
    data: {
      zip: zipCode
    },
    // eslint-disable-next-line camelcase
    placement_id: placementId,
    // eslint-disable-next-line camelcase
    sub_1: 'test sub id',
    type: 'ad_unit',
    version: 17
  };
};

const mediaAlphaDirectLink = (zipCode, placementId) => {
  mediaAlphaLoad(zipCode, placementId);
  MediaAlphaExchange__enableDirectLinks();
};

const mediaAlphaLoadElement = (zipCode, placementId, elementID) => {
  mediaAlphaLoad(zipCode, placementId);
  MediaAlphaExchange__load(elementID);
};

const getGAInsurance = (vertical, quote) => {
  const capitalizedVertical = vertical.charAt(0).toUpperCase() + vertical.slice(1);

  return quote ? `${capitalizedVertical} ${quote}` : capitalizedVertical;
};

const STATES = {
  AL: 'Alabama',
  AK: 'Alaska',
  AZ: 'Arizona',
  AR: 'Arkansas',
  CA: 'California',
  CO: 'Colorado',
  CT: 'Connecticut',
  DC: 'District-Of-Columbia',
  DE: 'Delaware',
  FL: 'Florida',
  GA: 'Georgia',
  HI: 'Hawaii',
  ID: 'Idaho',
  IL: 'Illinois',
  IN: 'Indiana',
  IA: 'Iowa',
  KS: 'Kansas',
  KY: 'Kentucky',
  LA: 'Louisiana',
  ME: 'Maine',
  MD: 'Maryland',
  MA: 'Massachusetts',
  MI: 'Michigan',
  MN: 'Minnesota',
  MS: 'Mississippi',
  MO: 'Missouri',
  MT: 'Montana',
  NE: 'Nebraska',
  NV: 'Nevada',
  NH: 'New-Hampshire',
  NJ: 'New-Jersey',
  NM: 'New-Mexico',
  NY: 'New-York',
  NC: 'North-Carolina',
  ND: 'North-Dakota',
  OH: 'Ohio',
  OK: 'Oklahoma',
  OR: 'Oregon',
  PA: 'Pennsylvania',
  RI: 'Rhode-Island',
  SC: 'South-Carolina',
  SD: 'South-Dakota',
  TN: 'Tennessee',
  TX: 'Texas',
  UT: 'Utah',
  VT: 'Vermont',
  VA: 'Virginia',
  WA: 'Washington',
  WV: 'West-Virginia',
  WI: 'Wisconsin',
  WY: 'Wyoming'
};

const COMPANIES = [
  {
    name: '21st Century',
    url: '/insurance/auto/reviews/21st-century/',
    logo: '21s-century-auto_fjhdra.png'
  },
  {
    name: 'Allstate',
    url: '/insurance/auto/reviews/allstate/',
    logo: 'Allstate.jpg'
  },
  {
    name: 'Ameriprise Financial',
    url: '/insurance/auto/reviews/ameriprise-financial/',
    logo: 'ameriprise-financial.png'
  },
  {
    name: 'Amica',
    url: '/insurance/auto/reviews/amica/',
    logo: 'Amica-mutual.jpg'
  },
  {
    name: 'Esurance',
    url: '/insurance/auto/reviews/esurance/',
    logo: 'Esurance.jpg'
  },
  {
    name: 'Farmers Insurance',
    url: '/insurance/auto/reviews/farmers-insurance/',
    logo: 'Farmers-mutual-nebraska.png'
  },
  {
    name: 'GEICO',
    url: '/insurance/auto/reviews/geico/',
    logo: 'Geico.png'
  },
  {
    name: 'Liberty Mutual',
    url: '/insurance/auto/reviews/liberty-mutual/',
    logo: 'Liberty_Mutual_Insurance_mlhjsg.jpg'
  },
  {
    name: 'MetLife',
    url: '/insurance/auto/reviews/metlife/',
    logo: 'MetLife.png'
  },
  {
    name: 'Nationwide',
    url: '/insurance/auto/reviews/nationwide-insurance/',
    logo: 'nationwide-mutual-insurance-logo_b9dpxx.png'
  },
  {
    name: 'Progressive',
    url: '/insurance/auto/reviews/progressive/',
    logo: 'Progressive.svg'
  },
  {
    name: 'State Farm',
    url: '/insurance/auto/reviews/state-farm/',
    logo: 'state-farm_vxiugv.png'
  },
  {
    name: 'The General',
    url: '/insurance/auto/reviews/the-general/',
    logo: 'the-general-insurance_psrxrg.svg'
  },
  {
    name: 'The Hartford',
    url: '/insurance/auto/reviews/the-hartford/',
    logo: 'Hartford.svg'
  },
  {
    name: 'Travelers',
    url: '/insurance/auto/reviews/travelers/',
    logo: 'Travelers.svg'
  }
];

/* eslint-disable max-len */
const STATES_IMPORTANT_CITIES = {
  AK: [{ name: 'Anchorage', url: 'https://www.moneygeek.com/insurance/auto/best-cheap-car-insurance-anchorage-ak/' }],
  AL: [{ name: 'Birmingham', url: 'https://www.moneygeek.com/insurance/auto/best-cheap-car-insurance-birmingham-al/' }],
  AR: [{ name: 'Little Rock', url: 'https://www.moneygeek.com/insurance/auto/best-cheap-car-insurance-little-rock-ar/' }],
  AZ: [
    { name: 'Phoenix', url: 'https://www.moneygeek.com/insurance/auto/best-cheap-car-insurance-phoenix-az/' },
    { name: 'Tucson', url: 'https://www.moneygeek.com/insurance/auto/best-cheap-car-insurance-tucson-az/' },
    { name: 'Mesa', url: 'https://www.moneygeek.com/insurance/auto/best-cheap-car-insurance-mesa-az/' },
    { name: 'Chandler', url: 'https://www.moneygeek.com/insurance/auto/best-cheap-car-insurance-chandler-az/' },
    { name: 'Scottsdale', url: 'https://www.moneygeek.com/insurance/auto/best-cheap-car-insurance-scottsdale-az' },
    { name: 'Glendale', url: 'https://www.moneygeek.com/insurance/auto/best-cheap-car-insurance-glendale-az/' },
    { name: 'Gilbert', url: 'https://www.moneygeek.com/insurance/auto/best-cheap-car-insurance-gilbert-az/' }],
  CA: [
    { name: 'Los Angeles', url: 'https://www.moneygeek.com/insurance/auto/best-cheap-car-insurance-los-angeles-ca/' },
    { name: 'San Diego', url: 'https://www.moneygeek.com/insurance/auto/best-cheap-car-insurance-san-diego-ca/' },
    { name: 'San Jose', url: 'https://www.moneygeek.com/insurance/auto/best-cheap-car-insurance-san-jose-ca/' },
    { name: 'San Francisco', url: 'https://www.moneygeek.com/insurance/auto/best-cheap-car-insurance-san-francisco-ca/' },
    { name: 'Fresno', url: 'https://www.moneygeek.com/insurance/auto/best-cheap-car-insurance-fresno-ca/' },
    { name: 'Sacramento', url: 'https://www.moneygeek.com/insurance/auto/best-cheap-car-insurance-sacramento-ca/' },
    { name: 'Long Beach', url: 'https://www.moneygeek.com/insurance/auto/best-cheap-car-insurance-long-beach-ca/' },
    { name: 'Oakland', url: 'https://www.moneygeek.com/insurance/auto/best-cheap-car-insurance-oakland-ca/' },
    { name: 'Bakersfield', url: 'https://www.moneygeek.com/insurance/auto/best-cheap-car-insurance-bakersfield-ca ' },
    { name: 'Anaheim', url: 'https://www.moneygeek.com/insurance/auto/best-cheap-car-insurance-anaheim-ca' },
    { name: 'Santa Ana', url: 'https://www.moneygeek.com/insurance/auto/best-cheap-car-insurance-santa-ana-ca' },
    { name: 'Riverside', url: 'https://www.moneygeek.com/insurance/auto/best-cheap-car-insurance-riverside-ca' },
    { name: 'Stockton', url: 'https://www.moneygeek.com/insurance/auto/best-cheap-car-insurance-stockton-ca ' },
    { name: 'Irvine', url: 'https://www.moneygeek.com/insurance/auto/best-cheap-car-insurance-irvine-ca/' },
    { name: 'Chula Vista', url: 'https://www.moneygeek.com/insurance/auto/best-cheap-car-insurance-chula-vista-ca/' },
    { name: 'Fremont', url: 'https://www.moneygeek.com/insurance/auto/best-cheap-car-insurance-fremont-ca/' },
    { name: 'San Bernardino', url: 'https://www.moneygeek.com/insurance/auto/best-cheap-car-insurance-san-bernardino-ca/' },
    { name: 'Modesto', url: 'https://www.moneygeek.com/insurance/auto/best-cheap-car-insurance-modesto-ca/' },
    { name: 'Fontana', url: 'https://www.moneygeek.com/insurance/auto/best-cheap-car-insurance-fontana-ca/' },
    { name: 'Santa Clarita', url: 'https://www.moneygeek.com/insurance/auto/best-cheap-car-insurance-santa-clarita-ca/' },
    { name: 'Oxnard', url: 'https://www.moneygeek.com/insurance/auto/best-cheap-car-insurance-oxnard-ca/' },
    { name: 'Moreno Valley', url: 'https://www.moneygeek.com/insurance/auto/best-cheap-car-insurance-moreno-valley-ca/' },
    { name: 'Santa Rosa', url: 'https://www.moneygeek.com/insurance/auto/best-cheap-car-insurance-santa-rosa-ca/' }],
  CO: [
    { name: 'Denver', url: 'https://www.moneygeek.com/insurance/auto/best-cheap-car-insurance-denver-co/' },
    { name: 'Colorado Springs', url: 'https://www.moneygeek.com/insurance/auto/best-cheap-car-insurance-colorado-springs-co/' },
    { name: 'Aurora', url: 'https://www.moneygeek.com/insurance/auto/best-cheap-car-insurance-aurora-co ' }],
  CT: [{ name: 'Bridgeport', url: 'https://www.moneygeek.com/insurance/auto/best-cheap-car-insurance-bridgeport-ct/' }],
  DC: [{ name: 'Washington', url: 'https://www.moneygeek.com/insurance/auto/best-cheap-car-insurance-washington-dc/' }],
  DE: [{ name: 'Wilmington', url: 'https://www.moneygeek.com/insurance/auto/best-cheap-car-insurance-wilmington-de/' }],
  FL: [
    { name: 'Jacksonville', url: 'https://www.moneygeek.com/insurance/auto/best-cheap-car-insurance-jacksonville-fl/' },
    { name: 'Miami', url: 'https://www.moneygeek.com/insurance/auto/best-cheap-car-insurance-miami-fl/' },
    { name: 'Tampa', url: 'https://www.moneygeek.com/insurance/auto/best-cheap-car-insurance-tampa-fl/' },
    { name: 'Orlando', url: 'https://www.moneygeek.com/insurance/auto/best-cheap-car-insurance-orlando-fl/' },
    { name: 'St. Petersburg', url: 'https://www.moneygeek.com/insurance/auto/best-cheap-car-insurance-st.-petersburg-fl/' },
    { name: 'Hialeah', url: 'https://www.moneygeek.com/insurance/auto/best-cheap-car-insurance-hialeah-fl/' }],
  GA: [{ name: 'Atlanta', url: 'https://www.moneygeek.com/insurance/auto/best-cheap-car-insurance-atlanta-ga/' }],
  HI: [{ name: 'Honolulu', url: 'https://www.moneygeek.com/insurance/auto/best-cheap-car-insurance-honolulu-hi/' }],
  IA: [{ name: 'Des Moines', url: 'https://www.moneygeek.com/insurance/auto/best-cheap-car-insurance-des-moines-ia/' }],
  ID: [{ name: 'Boise', url: 'https://www.moneygeek.com/insurance/auto/best-cheap-car-insurance-boise-id/' }],
  IL: [{ name: 'Chicago', url: 'https://www.moneygeek.com/insurance/auto/best-cheap-car-insurance-chicago-il/' }],
  IN: [
    { name: 'Indianapolis', url: 'https://www.moneygeek.com/insurance/auto/best-cheap-car-insurance-indianapolis-in/' },
    { name: 'Fort Wayne', url: 'https://www.moneygeek.com/insurance/auto/best-cheap-car-insurance-fort-wayne-in/' }],
  KS: [{ name: 'Wichita', url: 'https://www.moneygeek.com/insurance/auto/best-cheap-car-insurance-wichita-ks ' }],
  KY: [
    { name: 'Louisville', url: 'https://www.moneygeek.com/insurance/auto/best-cheap-car-insurance-louisville-ky/' },
    { name: 'Lexington', url: 'https://www.moneygeek.com/insurance/auto/best-cheap-car-insurance-lexington-ky' }],
  LA: [
    { name: 'New Orleans', url: 'https://www.moneygeek.com/insurance/auto/best-cheap-car-insurance-new-orleans-la' },
    { name: 'Baton Rouge', url: 'https://www.moneygeek.com/insurance/auto/best-cheap-car-insurance-baton-rouge-la/' }],
  MA: [{ name: 'Boston', url: 'https://www.moneygeek.com/insurance/auto/best-cheap-car-insurance-boston-ma/' }],
  MD: [{ name: 'Baltimore', url: 'https://www.moneygeek.com/insurance/auto/best-cheap-car-insurance-baltimore-md/' }],
  ME: [{ name: 'Portland', url: 'https://www.moneygeek.com/insurance/auto/best-cheap-car-insurance-portland-me/' }],
  MI: [{ name: 'Detroit', url: 'https://www.moneygeek.com/insurance/auto/best-cheap-car-insurance-detroit-mi/' }],
  MN: [
    { name: 'Minneapolis', url: 'https://www.moneygeek.com/insurance/auto/best-cheap-car-insurance-minneapolis-mn/' },
    { name: 'St. Paul', url: 'https://www.moneygeek.com/insurance/auto/best-cheap-car-insurance-st.-paul-mn/' }],
  MO: [
    { name: 'Kansas City', url: 'https://www.moneygeek.com/insurance/auto/best-cheap-car-insurance-kansas-city-mo/' },
    { name: 'St. Louis', url: 'https://www.moneygeek.com/insurance/auto/best-cheap-car-insurance-st.-louis-mo/' }],
  MS: [{ name: 'Jackson', url: 'https://www.moneygeek.com/insurance/auto/best-cheap-car-insurance-jackson-ms/' }],
  MT: [{ name: 'Billings', url: 'https://www.moneygeek.com/insurance/auto/best-cheap-car-insurance-billings-mt/' }],
  NC: [
    { name: 'Charlotte', url: 'https://www.moneygeek.com/insurance/auto/best-cheap-car-insurance-charlotte-nc/' },
    { name: 'Raleigh', url: 'https://www.moneygeek.com/insurance/auto/best-cheap-car-insurance-raleigh-nc/' },
    { name: 'Greensboro', url: 'https://www.moneygeek.com/insurance/auto/best-cheap-car-insurance-greensboro-nc/' },
    { name: 'Durham', url: 'https://www.moneygeek.com/insurance/auto/best-cheap-car-insurance-durham-nc/' },
    { name: 'Winston-Salem', url: 'https://www.moneygeek.com/insurance/auto/best-cheap-car-insurance-winston-salem-nc/' },
    { name: 'Fayetteville', url: 'https://www.moneygeek.com/insurance/auto/best-cheap-car-insurance-fayetteville-nc/' }],
  ND: [{ name: 'Fargo', url: 'https://www.moneygeek.com/insurance/auto/best-cheap-car-insurance-fargo-nd/' }],
  NE: [
    { name: 'Omaha', url: 'https://www.moneygeek.com/insurance/auto/best-cheap-car-insurance-omaha-ne/' },
    { name: 'Lincoln', url: 'https://www.moneygeek.com/insurance/auto/best-cheap-car-insurance-lincoln-ne/' }],
  NH: [{ name: 'Manchester', url: 'https://www.moneygeek.com/insurance/auto/best-cheap-car-insurance-manchester-nh/' }],
  NJ: [
    { name: 'Newark', url: 'https://www.moneygeek.com/insurance/auto/best-cheap-car-insurance-newark-nj/' },
    { name: 'Jersey City', url: 'https://www.moneygeek.com/insurance/auto/best-cheap-car-insurance-jersey-city-nj/' }],
  NM: [{ name: 'Albuquerque', url: 'https://www.moneygeek.com/insurance/auto/best-cheap-car-insurance-albuquerque-nm/' }],
  NV: [
    { name: 'Las Vegas', url: 'https://www.moneygeek.com/insurance/auto/best-cheap-car-insurance-las-vegas-nv/' },
    { name: 'Henderson', url: 'https://www.moneygeek.com/insurance/auto/best-cheap-car-insurance-henderson-nv/' },
    { name: 'Reno', url: 'https://www.moneygeek.com/insurance/auto/best-cheap-car-insurance-reno-nv/' },
    { name: 'North Las Vegas', url: 'https://www.moneygeek.com/insurance/auto/best-cheap-car-insurance-north-las-vegas-nv/' }],
  NY: [
    { name: 'Buffalo', url: 'https://www.moneygeek.com/insurance/auto/best-cheap-car-insurance-buffalo-ny' },
    { name: 'New York', url: 'https://www.moneygeek.com/insurance/auto/best-cheap-car-insurance-new-york-ny/' }],
  OH: [
    { name: 'Columbus', url: 'https://www.moneygeek.com/insurance/auto/best-cheap-car-insurance-columbus-oh/' },
    { name: 'Cleveland', url: 'https://www.moneygeek.com/insurance/auto/best-cheap-car-insurance-cleveland-oh ' },
    { name: 'Cincinnati', url: 'https://www.moneygeek.com/insurance/auto/best-cheap-car-insurance-cincinnati-oh/' },
    { name: 'Toledo', url: 'https://www.moneygeek.com/insurance/auto/best-cheap-car-insurance-toledo-oh/' }],
  OK: [
    { name: 'Oklahoma City', url: 'https://www.moneygeek.com/insurance/auto/best-cheap-car-insurance-oklahoma-city-ok/' },
    { name: 'Tulsa', url: 'https://www.moneygeek.com/insurance/auto/best-cheap-car-insurance-tulsa-ok/' }],
  OR: [{ name: 'Portland', url: 'https://www.moneygeek.com/insurance/auto/best-cheap-car-insurance-portland-or/' }],
  PA: [
    { name: 'Philadelphia', url: 'https://www.moneygeek.com/insurance/auto/best-cheap-car-insurance-philadelphia-pa/' },
    { name: 'Pittsburgh', url: 'https://www.moneygeek.com/insurance/auto/best-cheap-car-insurance-pittsburgh-pa/' }],
  RI: [{ name: 'Providence', url: 'https://www.moneygeek.com/insurance/auto/best-cheap-car-insurance-providence-ri/' }],
  SC: [{ name: 'Charleston', url: 'https://www.moneygeek.com/insurance/auto/best-cheap-car-insurance-charleston-sc/' }],
  SD: [{ name: 'Sioux Falls', url: 'https://www.moneygeek.com/insurance/auto/best-cheap-car-insurance-sioux-falls-sd/' }],
  TN: [
    { name: 'Nashville', url: 'https://www.moneygeek.com/insurance/auto/best-cheap-car-insurance-nashville-tn/' },
    { name: 'Memphis', url: 'https://www.moneygeek.com/insurance/auto/best-cheap-car-insurance-memphis-tn/' }],
  TX: [
    { name: 'Houston', url: 'https://www.moneygeek.com/insurance/auto/best-cheap-car-insurance-houston-tx/' },
    { name: 'San Antonio', url: 'https://www.moneygeek.com/insurance/auto/best-cheap-car-insurance-san-antonio-tx/' },
    { name: 'Dallas', url: 'https://www.moneygeek.com/insurance/auto/best-cheap-car-insurance-dallas-tx/' },
    { name: 'Austin', url: 'https://www.moneygeek.com/insurance/auto/best-cheap-car-insurance-austin-tx/' },
    { name: 'Fort Worth', url: 'https://www.moneygeek.com/insurance/auto/best-cheap-car-insurance-fort-worth-tx/' },
    { name: 'El Paso', url: 'https://www.moneygeek.com/insurance/auto/best-cheap-car-insurance-el-paso-tx/' },
    { name: 'Arlington', url: 'https://www.moneygeek.com/insurance/auto/best-cheap-car-insurance-arlington-tx/' },
    { name: 'Corpus Christi', url: 'https://www.moneygeek.com/insurance/auto/best-cheap-car-insurance-corpus-christi-tx' },
    { name: 'Plano', url: 'https://www.moneygeek.com/insurance/auto/best-cheap-car-insurance-plano-tx/' },
    { name: 'Laredo', url: 'https://www.moneygeek.com/insurance/auto/best-cheap-car-insurance-laredo-tx/' },
    { name: 'Lubbock', url: 'https://www.moneygeek.com/insurance/auto/best-cheap-car-insurance-lubbock-tx/' },
    { name: 'Garland', url: 'https://www.moneygeek.com/insurance/auto/best-cheap-car-insurance-garland-tx/' },
    { name: 'Irving', url: 'https://www.moneygeek.com/insurance/auto/best-cheap-car-insurance-irving-tx/' }],
  UT: [{ name: 'Salt Lake City', url: 'https://www.moneygeek.com/insurance/auto/best-cheap-car-insurance-salt-lake-city-ut/' }],
  VA: [
    { name: 'Virginia Beach', url: 'https://www.moneygeek.com/insurance/auto/best-cheap-car-insurance-virginia-beach-va/' },
    { name: 'Norfolk', url: 'https://www.moneygeek.com/insurance/auto/best-cheap-car-insurance-norfolk-va/' },
    { name: 'Chesapeake', url: 'https://www.moneygeek.com/insurance/auto/best-cheap-car-insurance-chesapeake-va/' },
    { name: 'Richmond', url: 'https://www.moneygeek.com/insurance/auto/best-cheap-car-insurance-richmond-va/' }],
  VT: [{ name: 'Burlington', url: 'https://www.moneygeek.com/insurance/auto/best-cheap-car-insurance-burlington-vt/' }],
  WA: [
    { name: 'Seattle', url: 'https://www.moneygeek.com/insurance/auto/best-cheap-car-insurance-seattle-wa/' },
    { name: 'Spokane', url: 'https://www.moneygeek.com/insurance/auto/best-cheap-car-insurance-spokane-wa/' },
    { name: 'Tacoma', url: 'https://www.moneygeek.com/insurance/auto/best-cheap-car-insurance-tacoma-wa/' }],
  WI: [
    { name: 'Milwaukee', url: 'https://www.moneygeek.com/insurance/auto/best-cheap-car-insurance-milwaukee-wi/' },
    { name: 'Madison', url: 'https://www.moneygeek.com/insurance/auto/best-cheap-car-insurance-madison-wi/' }],
  WV: [{ name: 'Charleston', url: 'https://www.moneygeek.com/insurance/auto/best-cheap-car-insurance-charleston-wv/' }],
  WY: [{ name: 'Cheyenne', url: 'https://www.moneygeek.com/insurance/auto/best-cheap-car-insurance-cheyenne-wy/' }]
};
/* eslint-enable max-len */

/**
 * Function that formats a number (23) to ordinal number (23rd)
 *
 * @param {number} number The number that needs to be formatted
 * @returns {string} Number already formated as a String
 */
const formatToOrdinalNumber = (number) => {
  // number % 100 gives the tens.  Examples: 13425 % 100 -> 25, 5671 % 100 -> 71
  // number % 10 gives the units. Examples: 13425 % 10 -> 5, 5671 % 10 -> 1
  if (number % 100 >= 11 && number % 100 <= 13) {
    return number + 'th';
  }

  switch (number % 10) {
    case 1: return number + 'st';
    case 2: return number + 'nd';
    case 3: return number + 'rd';
  }

  return number + 'th';
};

/**
 * Function that given a currency(USD) formats a number (2300) to
 * a currency reading ($2,300)
 *
 * @param {number} currency The currency to format
 * @param {number} amount The number that needs to be formatted
 * @returns {string} Curency already formated as a String
 */
const formatToCurrency = (currency, amount) => {
  const formatter = new Intl.NumberFormat('en-US', { style: 'currency', currency: currency });
  return formatter.format(amount).split('.')[0];
};

const getShowRowsConfiguration = (rows, data) => {
  if (rows) {
    const loadCount = rows.loadCount ? data.slice(0, rows.loadCount) : data;
    const displayCount = rows.displayCount ? rows.displayCount : data.length;
    return new ShowMore(displayCount, loadCount, rows.titleMore, rows.titleLess);
  } else {
    return new ShowMore(data.length, data);
  }
};

const formatURL = (url, type) => {
  const cleanURL = url.trim().replace(/^\/|\/$/g, '');

  switch (type) {
    case 'end':
      return `${cleanURL}/`;
    default:
      return `/${cleanURL}/`;
  }
};

export {
  apiRequest,
  MEDIA_ALPHA_VARS,
  mediaAlphaDirectLink,
  mediaAlphaLoadElement,
  googleAnalyticsLoad,
  googleAnalyticsLoadNoLabel,
  STATES,
  COMPANIES,
  formatToOrdinalNumber,
  formatToCurrency,
  STATES_IMPORTANT_CITIES,
  getShowRowsConfiguration,
  formatURL,
  getGAInsurance
};

