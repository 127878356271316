const data = [
  {
    name: 'MetLife',
    url: '/insurance/auto/reviews/metlife/',
    logo: 'MetLife.png'
  },
  {
    name: 'Amica',
    url: '/insurance/auto/reviews/amica/',
    logo: 'Amica-mutual.jpg'
  },
  {
    name: 'Progressive',
    url: '/insurance/auto/reviews/progressive/',
    logo: 'Progressive.svg'
  },
  {
    name: 'State Farm',
    url: '/insurance/auto/reviews/state-farm/',
    logo: 'state-farm_vxiugv.png'
  }
];

export default data;
