const Providers = {
  MEDIA_ALPHA: 'MA',
  QUIN_STREET: 'QS'
};

/**
 * Selects the provider according to the traffic percentage.
 *
 * @param trafficPercentage config.trafficPercentage
 */
const selectProvider = (trafficPercentage) => {
  return Math.random() <= trafficPercentage ? Providers.MEDIA_ALPHA : Providers.QUIN_STREET;
};

export default Providers;
export { selectProvider };
