import styled from 'styled-components';
import { colors, typography, sizes } from './../../theme_config/theme';

export const ShowMoreButton = styled.button.attrs({ className: 'ShowMore' })`
  background-color: transparent;
  border: none;
  font-family: ${typography.fontFamilyBase};
  color: ${colors.brandingElements};
  text-decoration: none;
  font-size: 17px;

  &:hover {
    color: ${colors.brandingElementsHover};
    cursor: pointer;
  }

  @media (max-width: ${sizes.palmEnd}) {
    font-size: 14px;
  }
`;
