import config from './config';
import { selectProvider } from './providers';

/**
 * Selects a provider with the chance define on config.trafficPercentage.
 *
 * @param {*} key
 */
const usePersistedProvider = (key) => {
  // eslint-disable-next-line no-undef
  if (typeof localStorage === 'undefined') {
    return null;
  }

  // eslint-disable-next-line no-undef
  const currentProvider = localStorage.getItem(key);
  const selectedProvider = selectProvider(config.trafficPercentage);

  if (!currentProvider) {
    // eslint-disable-next-line no-undef
    localStorage.setItem(key, selectedProvider);
    return selectedProvider;
  }

  return currentProvider;
};

const clearPersistedProvider = (key) => {
  // eslint-disable-next-line no-undef
  localStorage.removeItem(key);

  return true;
};


export { usePersistedProvider, clearPersistedProvider };
