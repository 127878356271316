import styled from 'styled-components';
import { typography } from '../../../theme_config/theme';
import '../../../fonts/_fonts.css';

export default styled.div`
    display: flex;
    flex-direction: row;
    justify-content:space-around;
    font-family: ${typography.fontFamilyBase};
    font-size: 14px;
    margin-bottom:3px;

    .StarRankingItem-title{
        flex: 1;
    }

    .StarRankingItem-stars{
        margin: 0px 10px 0px 5px;
    }

    .StarRankingItem-ranking-numbers{
        display:initial;
        flex-basis:28px;
        text-align:center;
    }
`;
