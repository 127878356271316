import React from 'react';
import OrderedListStyles from './OrderedList.style';
import PropTypes from 'prop-types';

/**
 * OrderedList component.
 *
 * This component would show an HTML ordered list using the children elements
 * that are inside of it.
 * @param {*} children
 */
const OrderedList = ({ children }) => (
  <OrderedListStyles>
    <ol>
      { React.Children.map(children, children => <li>{ children }</li>) }
    </ol>
  </OrderedListStyles>
);

OrderedList.propTypes = {

  /**
   * Inner elements of the ordered list.
   */
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.object),
    PropTypes.object,
    PropTypes.string
  ])
};

export default OrderedList;
