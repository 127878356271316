import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { StaticTableContainer } from './style';
import ShowMore from '../ShowMore/ShowMore';
import Spacing from '../Spacing/Spacing';

/**
 * Static Premium Table component.
 *
 * This component should show an HTML dynamic table with some custom attributes.
 */
const StaticPremiumTable = ({ data, displayCount, topHeaderColor, leftHeaderColor, verticalLine, margin }) => {
  const [client, setClient] = useState(false);

  useEffect(() => {
    setClient(true);
  }, []);

  const showMore = new ShowMore(displayCount, mapRows(data.rows));

  return (
    <Spacing margin={margin}>
      <StaticTableContainer
        key={client}
        topHeaderColor={topHeaderColor}
        leftHeaderColor={leftHeaderColor}
        verticalLine={verticalLine}>
        <li>{data.headers.map((item, index) => getTableHead(item, index))}</li>
        {showMore.items}
      </StaticTableContainer>
      {showMore.button}
    </Spacing>
  );
};

const mapRows = rows => rows.map((row, index) => (<li key={index}>{getCells(row)}</li>));

const headerClass = index => `column-header ${index === 0 ? 'row-header' : ''}`;

const getTableHead = (item, index) => (<div className={headerClass(index)} key={index}>{item}</div>);

const getValue = cell => (<span className='value'>{cell}</span>);

const getCells = row => row.map((cell, index) => (<div className="column-content" key={index}>{getValue(cell)}</div>));

StaticPremiumTable.propTypes = {

  /**
   * Data that fills the component when it's used on a Template page.
   */
  data: PropTypes.object,

  /**
   * Number of rows to display on the component before clicking "show all".
   */
  displayCount: PropTypes.number,

  /**
   * Color for the Top StaticTable header.
   */
  topHeaderColor: PropTypes.string,

  /**
   * Vertical line option, the user can choose to add it or remove it.
   */
  verticalLine: PropTypes.bool,

  /**
   * Color for the Left StaticTable header.
   */
  leftHeaderColor: PropTypes.string,

  /**
   * Bottom margin space defined for the component (pixels).
   */
  margin: PropTypes.object
};

StaticPremiumTable.defaultProps = {
  displayCount: 5,
  topHeaderColor: '#616877',
  leftHeaderColor: '#616877',
  verticalLine: false
};

export default StaticPremiumTable;
