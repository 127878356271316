import React from 'react';
import PropTypes from 'prop-types';

import ButtonStyle from './Button.style.js';

/**
 * Button component
 *
 * This component would show an HTML button using the styles and events provided by params and
 * children elements that are inside of it.
 *
 * @param {*} className
 * @param {*} onClick
 * @param {*} children
 * @param {*} link
 * @param {*} target
 */
const Button = ({ className, onClick, children, link, target }) =>{
  const onClickFn = () => {
    if (link && !className.includes('btn-inactive')) {
      // eslint-disable-next-line no-undef
      window.open(link, '_' + target, 'noopener', 'noreferer');
    }
    if (onClick) {
      onClick();
    }
  };

  return (
    <ButtonStyle>
      <button className={className} onClick={onClickFn}>
        {children}
      </button>
    </ButtonStyle>
  );
};

Button.propTypes = {

  /**
   * Classes available for the button use one of these:
   *
   * btn-primary, btn-secondary, btn-anchor.
   *
   * Additionaly btn-inactive can be added
   */
  className: PropTypes.string,

  /**
   * An event to happen on a button click
   */
  onClick: PropTypes.func,

  /**
   * Inner elements of the button.
   */
  children: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string,
  ]),

  /**
   * A string that contains a URL, when the button is clicked a new page is opened with that URL
   */
  link: PropTypes.string,

  /**
   * A string that defines how the new page should be oppened
   */
  target: PropTypes.oneOf(['top', 'blank'])
};

Button.defaultProps = {
  className: 'btn-primary',
  target: 'top'
};

export default Button;
