import { Playground, Props } from "docz";
import PremiumRanking from "../../../../../components/PremiumRanking/PremiumRanking";
import OrderedList from "../../../../../components/OrderedList/OrderedList";
import { monthlyPremiumData, marketShareData, financialStabilityData, reviewPages } from "../../../../../components/PremiumRanking/MockData";
import * as React from 'react';
export default {
  Playground,
  Props,
  PremiumRanking,
  OrderedList,
  monthlyPremiumData,
  marketShareData,
  financialStabilityData,
  reviewPages,
  React
};