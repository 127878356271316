import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { CrossLinkingContainer } from './CompanyCrossLinking.style';
import CompanyLogo from '../CompanyLogo/CompanyLogo';
import Spacing from '../Spacing/Spacing';
import { COMPANIES } from '../../utils/utils';

const sortCompanies = (a, b) => {
  if (a.name < b.name) {
    return -1;
  }
  if (a.name > b.name) {
    return 1;
  }
  return 0;
};

const getCompaniesList = (data) => {
  const sortedData = data.sort(sortCompanies);
  return sortedData.map((company, index) => {
    const companyInsuranceURL = `https://www.moneygeek.com${company.url}`;
    return (
      <a key={index} href={companyInsuranceURL}>
        <div className="item">
          <CompanyLogo image={company.logo} displayType="regular" />
          <h6>{company.name}</h6>
        </div>
      </a>
    );
  });
};

const transformData = (companies, indexes, listLimit) => indexes.slice(0, listLimit).map(item => companies[item]);

function shuffle(array) {
  let i = array.length, j = 0, temp;
  while (i--) {
    j = Math.floor(Math.random() * (i + 1));
    temp = array[i];
    array[i] = array[j];
    array[j] = temp;
  }
  return array;
}

/**
 * CompanyCrossLinking component.
 *
 * This component would show a company cross linking container using the information
 * of a default or custom companies list and redirect to the defined insurance pages.
 */
const CompanyCrossLinking = ({ companies, displayCount, title, margin }) => {
  const [client, setClient] = useState(false);
  const companiesList = companies ? companies : COMPANIES;
  const indexes = Array.apply(0, Array(companiesList.length)).map((item, index) => index);

  useEffect(() => {
    setClient(true);
  }, []);

  return (
    <Spacing margin={margin}>
      <CrossLinkingContainer key={client}>
        <h2>{title}</h2>
        <div className="grid">
          {getCompaniesList(transformData(companiesList, shuffle(indexes), displayCount))}
        </div>
      </CrossLinkingContainer>
    </Spacing>
  );
};

CompanyCrossLinking.propTypes = {

  /**
   * Companies list to generate on the component. Overrides default companies list.
   */
  companies: PropTypes.arrayOf(PropTypes.object),

  /**
   * Number of companies to display on the component.
   */
  displayCount: PropTypes.number,

  /**
   * Title for the component.
   */
  title: PropTypes.string,

  /**
   * Bottom margin space defined for the component (pixels).
   */
  margin: PropTypes.object
};

CompanyCrossLinking.defaultProps = {
  displayCount: 8,
  title: 'Other Cheap Car Insurance Providers'
};

export default CompanyCrossLinking;
