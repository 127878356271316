import styled from 'styled-components';
import { colors, typography, sizes } from './../../theme_config/theme';

export const CompleteBadgeContainer = styled.div.attrs({ className: 'Badge' })`
  font-family: ${typography.fontFamilyBase};
  color: ${colors.text};

  div.badge {
    border-radius: 50%;
    border: 4px solid ${colors.branding};
    width: 136px;
    height: 136px;
  }

  div.content {
    display: grid;
    overflow: visible;
    background-color: ${colors.textLight};
    border-radius: 50%;
    border: 1px solid ${colors.brandingElements};
    width: 130px;
    height: 130px;
    margin: 2px;
  }

  img.icon {
    height: 30px;
    width: 30px;
    margin: auto auto auto 50px;
  }

  img.ribbon {
    border: 4px solid ${colors.textLight};
    border-style: solid none;
    margin: -2px 0 0 -24px;
    max-width: none;
  }

  span.ribbon.text {
    font-family: ${typography.fontFamilyHeaderBold};
    color: ${colors.textLight};
    text-transform: uppercase;
    font-size: 18px;
    line-height: 0;
    text-align: center;
    z-index: 98;
    margin-top: -26px;
    margin-left: -18px;
  }

  span.label {
    font-family: ${typography.fontFamilyHeaderBold};
    color: ${colors.branding};
    font-size: 12px;
    line-height: 0;
    height: 0;
    text-align: center;
    margin-left: -25px;

    &.title {
      text-transform: uppercase;
      font-size: 10px;
      margin-top: -8px;
      flex-wrap: wrap;
    }

    &.year {
      font-size: 9px;
      margin-top: 10px;
    }
  }

  @media (max-width: ${sizes.palmEnd}) {
    div.badge {
      margin: auto;
    }
  }
`;

export const ScoreBadgeContainer = styled.div.attrs({ className: 'Badge' })`
  font-family: ${typography.fontFamilyBase};
  color: ${colors.text};

  div.badge {
    position: relative;
    border-radius: 50%;
    border: 4px solid ${colors.branding};
    width: 110px;
    height: 110px;
  }

  div.content {
    position: absolute;
    display: grid;
    background-color: ${colors.textLight};
    border-radius: 50%;
    border: 1px solid ${colors.brandingElements};
    height: 104px;
    width: 104px;
    margin: 2px;
  }

  img.icon {
    height: 30px;
    width: 30px;
    margin-top: 4px;
    margin-left: 38px;
  }

  img.ribbon {
    border: 4px solid ${colors.textLight};
    border-style: solid none;
    margin: -8px 0 0 -24px;
    width: 152px;
    max-width: none;
  }

  span.ribbon.text {
    font-family: ${typography.fontFamilyHeaderBold};
    color: ${colors.textLight};
    text-transform: uppercase;
    font-size: 16px;
    line-height: 0;
    text-align: center;
    z-index: 98;
    margin: -23px 0 0 -22px;
  }

  span.score {
    color: ${colors.branding};
    font-size: 40px;
    line-height: 32px;
    text-align: center;
    margin: -15px 0 0 -22px;
  }
`;
