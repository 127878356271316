export default {
  // Spacing
  bodyGutter: '20px',

  // MQ Starts
  phoneStart: '415px',
  tabletStart: '768px',
  desktopStart: '1110px',

  // MQ End
  get palmEnd() {
    return `${parseInt(this.tabletStart) - 1}px`;
  },
  get tabletEnd() {
    return `${parseInt(this.desktopStart) - 1}px`;
  },
  get phoneEnd() {
    return `${parseInt(this.phoneStart) - 1}px`;
  },

  // Max Width - All MQ
  get maxWidth() {
    return this.desktopStart;
  },
};
