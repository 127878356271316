import styled from 'styled-components';
import { colors } from '../../../theme_config/theme';
import '../../../fonts/_fonts.css';

export default styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    color: ${colors.text};
`;
