import React from 'react';
import PropTypes from 'prop-types';
import Ratings from 'react-ratings-declarative';

/**
 * Stars component
 *
 * This component would show an Stars component
 *
 * @param {*} ranking
 * @param {*} starsSize
 * @param {*} emptyColor
 * @param {*} ratedColor
 */
const Stars = ({ ranking, starsSize, emptyColor, ratedColor }) => {
  return (
    <Ratings
      rating={parseFloat(ranking)}
      widgetDimensions= {starsSize}
      widgetSpacings = '2px'
      widgetEmptyColors={emptyColor}
      widgetRatedColors={ratedColor}>
      <Ratings.Widget />
      <Ratings.Widget />
      <Ratings.Widget />
      <Ratings.Widget />
      <Ratings.Widget />
    </Ratings>
  );
};

Stars.propTypes = {

  /**
   * Number of the stars that are going to be shown
   */
  ranking: PropTypes.string,

  /**
   * Size of the stars expressed in pixels
   */
  starsSize: PropTypes.string,

  /**
   * Color for the stars that remain empty, you can use;
   *
   * CSS Predefined colors: https://www.w3schools.com/cssref/css_colors.asp
   *
   * RGB colors: starsEmptyColor="rgb(170,170,170)"
   *
   * Hex colors: starsEmptyColor="#AAAAAA"
   */
  emptyColor: PropTypes.string,

  /**
   * Color for the stars that are filled
   *
   * CSS Predefined colors: https://www.w3schools.com/cssref/css_colors.asp
   *
   * RGB colors: starsRatedColor="rgb(82,110,255)"
   *
   * Hex colors: starsRatedColor="#526EFF"
   */
  ratedColor: PropTypes.string
};

Stars.defaultProps = {
  ranking: '0',
  starsSize: '16px',
  emptyColor: '#AAAAAA',
  ratedColor: '#526EFF'
};

export default Stars;
