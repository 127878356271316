const data = [
  {
    company: 'Allstate',
    url: 'https://www.allstate.com/auto-insurance.aspx',
    score: '75',
    satisfaction: '3',
    claims: '2',
    affordability: '3',
    stability: 'A+'
  },
  {
    company: 'GEICO',
    url: 'https://www.geico.com/',
    score: '85',
    satisfaction: '3',
    claims: '3',
    affordability: '3',
    stability: 'A++'
  },
  {
    company: 'Progressive',
    score: '80',
    satisfaction: '2',
    claims: '3',
    affordability: '3',
    stability: 'A+'
  },
  {
    company: 'State Farm',
    url: 'https://www.statefarm.com/insurance/auto',
    score: '90',
    satisfaction: '3',
    claims: '3',
    affordability: '3',
    stability: 'A++'
  },
  {
    company: 'USAA',
    score: '100',
    satisfaction: '5',
    claims: '5',
    affordability: '5',
    stability: 'A++'
  }
];

export default data;
