export default {
  branding: '#1F1B50',
  brandingElements: '#526EFF',
  brandingElementsHover: '#405AE0',
  text: '#555555',
  textLight: '#FFFFFF',
  formElementsBorder: '#CBCBCB',
  error: '#FA8072',
  // Color Pallete
  blue: '#35b6d0',
  blueDark: '#526eff',
  gray: '#efeff1',
  grayDark: '#616877',
  green: '#96d134',
  navy: '#1F1B50',
  purple: '#a22ec8',
  white: '#ffffff',
  yellow: '#ffd35a',
  salmon: '#fa8072',
  pink: '#dda0dd',
  states: '#D3D4D6',
  lightGray: '#F7F7F9',

  // Defaults
  base: '#555555',
  border: '#EFEFF1',
  elementsBorder: '#CBCBCB',
  baseHover: '#405AE0',
};
