import React from 'react';
import PropTypes from 'prop-types';
import {
  ModalOverlayContainer,
  ModalContainer,
  ModalCloseButton,
  ModalWrapper
} from './Modal.style';

const Modal = ({ active, children, onClick, title }) => {
  if (!active) {
    return null;
  }

  return (
    <ModalWrapper>
      <ModalOverlayContainer onClick={onClick} />
      <ModalContainer>
        <ModalCloseButton onClick={onClick} />
        <h3 className="modal-title">{title}</h3>
        {children}
      </ModalContainer>
    </ModalWrapper>
  );
};

Modal.propTypes = {

  /**
   * The title of the Modal
   */
  title: PropTypes.string,

  /**
   * A boolean to show or hide the Modal
   */
  active: PropTypes.bool,

  /**
   * Inner elements of the modal.
   */
  children: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string,
  ]),

  /**
   * Function to toggle the Modal.
   */
  onClick: PropTypes.func
};

Modal.defaultProps = {
  title: 'Insurance Quote',
  show: false,
  children: 'This is a modal'
};


export default Modal;
