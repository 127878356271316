import styled from 'styled-components';
import { normalize } from 'styled-normalize';
import sizes from '../../theme_config/sizes';
import colors from '../../theme_config/colors';
import typography from '../../theme_config/typography';

export const StaticTableContainer = styled.ul`
  ${normalize}
  text-align: center;
  display: table;
  padding: 0;
  margin: 0;
  list-style-type: none;
  width: 100%;

  li {
    display: table-row;
    vertical-align: middle;
  }
  
  .column-header {
    display: table-cell;
    line-height: 1.3;
    padding: 20px 0px;
    width: auto;
    font-size: 16px;
    font-family: ${typography.fontFamilyBase};
    font-weight: bold;
    background-color: ${props => props.topHeaderColor};
    color: white;
    z-index: 2;
    position: sticky;
    top:0;

    @media(max-width: ${sizes.palmEnd}){
        font-size: 14px;
        min-width: 74px;
    }
  }
  
  .row-header {
    display: table-cell;
    padding: 16px;
    font-size: 16px;
    font-family: ${typography.fontFamilyBase};
    font-weight: bold;
    background-color: ${props => props.leftHeaderColor} !important;
    position: sticky;
    left:0;
  }
  
  li span {
    display: block;
    font-size: 13px;
    font-weight: 400;
  }
  
  li:nth-child(odd) {
    background-color: ${colors.gray};
  }
  
  .column-content {
    display: table-cell;
    padding: 6px;
    font-size: 16px;
    font-family: ${typography.fontFamilyBase};
    font-weight: normal;
    color: ${colors.text};
  }
  
  .value {
    font-family: ${typography.fontFamilyBase};
    color: ${colors.navy};
    font-size: 15px;

    @media(max-width: ${sizes.palmEnd}){
      font-size: 16px;
      font-weight: 400;
    }
  }
  
  .column-content:first-of-type {
    text-align: left;
    padding-left: 18px;
    font-family: "Brandon Grotesque", sans-serif;
    color: rgb(85, 85, 85);
    font-size: 24px;
    line-height: 1.3;
    font-weight: bold;
    border-right:  ${props => props.verticalLine ? '1px solid rgb(211, 212, 214)' : 'none'};

    @media(max-width: ${sizes.palmEnd}){
      font-size: 16px;
      font-weight: 400;
      padding-left: 12px;
    }
  }
`;
