import styled from 'styled-components';
import { normalize } from 'styled-normalize';
import { colors, typography, sizes } from './../../theme_config/theme';
import '../../fonts/_fonts.css';

export const StaticTableContainer = styled.div`
    ${normalize}

    ul {
        display: table;
        padding: 0;
        margin: 0;
        list-style-type: none;
        width: 100%;
    }

    li {
        display: table-row;
    }

    #table-container {
        overflow: auto;
        max-height: 440px;
        text-align: center;

        ::-webkit-scrollbar {
            width: 0px;
            height: 6px;
            border-radius: 8px;
        }

        ::-webkit-scrollbar-thumb {
            background: #d9d9d9; 
            border-radius: 10px;
        }

        ::-webkit-scrollbar-thumb:hover {
            background: #cccccc; 
        }
    }

    .column-header {
        display: table-cell;
        vertical-align: middle;
        padding: 18px;
        max-width: 400px;
        font-size: 16px;
        font-family: ${typography.fontFamilyBase};
        font-weight: bold;
        background-color: ${props => props.topHeaderColor}!important;
        color: white;
        z-index: 2;
        position: sticky;
        top:0;
    }

    .row-header {
        display: table-cell;
        vertical-align: middle;
        padding: 16px;
        font-size: 16px;
        font-family: ${typography.fontFamilyBase};
        font-weight: bold;
        background-color: ${props => props.leftHeaderColor}!important;
        color: ${colors.brandingElements};
        position: sticky;
        left:0;
    }

    li:nth-child(odd) {
        background-color: ${colors.backgroundRow};
    }

    li > div {
        display: table-cell;
        vertical-align: middle;
        padding: 6px;
        font-size: 17px;
        font-family: ${typography.fontFamilyBase};
        font-weight: normal;
        color: ${colors.text};
        min-width:130px;
    }

    button {
        font-size: 17px;
    }
    
    .product-attributes {
        text-align: left !important;
        max-height: none !important;

        .column-header:first-child {
            border-right: solid 1px;
            border-color: ${colors.formElementsBorder}
        }
        
        .column-content {
            padding: 10px 18px;
        }

        .column-content:first-child {
            border-right: solid 1px;
            font-weight: 700;
        }

        @media(max-width: ${sizes.tabletStart}) {  
            li {
                background-color: transparent;

                div:nth-child(1) {
                    padding: 15px 0px 0px 0px;
                    border: none !important;
                    display: flex;
                }

                div:nth-child(2) {
                    padding: 0px 0px 15px 0px;
                    border-bottom: solid 1px;
                    border-color: ${colors.formElementsBorder};
                    display: flex;
                } 
            }

            li:first-child {
                display:none;
            }                       
        }
    }

    .headless-list {
        text-align: left !important;
        max-height: none !important;

        li {
            background-color: transparent;
        }

        li:first-child {
            display:none;
        }

        /* stylelint-disable-next-line no-descending-specificity */
        .column-content {
            border-bottom: solid 1px;
            border-color: ${colors.formElementsBorder};
            padding: 10px 18px;
        }

        .column-content:first-child {
            font-weight: 700;
        }

        @media(max-width: ${sizes.tabletStart}) {
            div {
                display: flex;
            }

            li {
                display: block;

                div:nth-child(1) {
                    border: none !important;
                }
            }            
        }
    }
`;
