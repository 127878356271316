/* eslint-disable max-len */

const autoStatesList = [
  { state: 'AL', url: '/insurance/auto/cheapest-car-insurance-alabama/' },
  { state: 'AK', url: '/insurance/auto/cheapest-car-insurance-alaska/' },
  { state: 'AZ', url: '/insurance/auto/cheapest-car-insurance-arizona/' },
  { state: 'AR', url: '/insurance/auto/cheapest-car-insurance-arkansas/' },
  { state: 'CA', url: '/insurance/auto/cheapest-car-insurance-california/' },
  { state: 'CO', url: '/insurance/auto/cheapest-car-insurance-colorado/' },
  { state: 'CT', url: '/insurance/auto/cheapest-car-insurance-connecticut/' },
  { state: 'DE', url: '/insurance/auto/cheapest-car-insurance-delaware/' },
  { state: 'FL', url: '/insurance/auto/cheapest-car-insurance-florida/' },
  { state: 'GA', url: '/insurance/auto/cheapest-car-insurance-georgia/' },
  { state: 'HI', url: '/insurance/auto/cheapest-car-insurance-hawaii/' },
  { state: 'ID', url: '/insurance/auto/cheapest-car-insurance-idaho/' },
  { state: 'IL', url: '/insurance/auto/cheapest-car-insurance-illinois/' },
  { state: 'IN', url: '/insurance/auto/cheapest-car-insurance-indiana/' },
  { state: 'IA', url: '/insurance/auto/cheapest-car-insurance-iowa/' },
  { state: 'KS', url: '/insurance/auto/cheapest-car-insurance-kansas/' },
  { state: 'KY', url: '/insurance/auto/cheapest-car-insurance-kentucky/' },
  { state: 'LA', url: '/insurance/auto/cheapest-car-insurance-louisiana/' },
  { state: 'ME', url: '/insurance/auto/cheapest-car-insurance-maine/' },
  { state: 'MD', url: '/insurance/auto/cheapest-car-insurance-maryland/' },
  { state: 'MA', url: '/insurance/auto/cheapest-car-insurance-massachusetts/' },
  { state: 'MI', url: '/insurance/auto/cheapest-car-insurance-michigan/' },
  { state: 'MN', url: '/insurance/auto/cheapest-car-insurance-minnesota/' },
  { state: 'MS', url: '/insurance/auto/cheapest-car-insurance-mississippi/' },
  { state: 'MO', url: '/insurance/auto/cheapest-car-insurance-missouri/' },
  { state: 'MT', url: '/insurance/auto/cheapest-car-insurance-montana/' },
  { state: 'NE', url: '/insurance/auto/cheapest-car-insurance-nebraska/' },
  { state: 'NV', url: '/insurance/auto/cheapest-car-insurance-nevada/' },
  { state: 'NH', url: '/insurance/auto/cheapest-car-insurance-new-hampshire/' },
  { state: 'NJ', url: '/insurance/auto/cheapest-car-insurance-new-jersey/' },
  { state: 'NM', url: '/insurance/auto/cheapest-car-insurance-new-mexico/' },
  { state: 'NY', url: '/insurance/auto/cheapest-car-insurance-new-york/' },
  { state: 'NC', url: '/insurance/auto/cheapest-car-insurance-north-carolina/' },
  { state: 'ND', url: '/insurance/auto/cheapest-car-insurance-north-dakota/' },
  { state: 'OH', url: '/insurance/auto/cheapest-car-insurance-ohio/' },
  { state: 'OK', url: '/insurance/auto/cheapest-car-insurance-oklahoma/' },
  { state: 'OR', url: '/insurance/auto/cheapest-car-insurance-oregon/' },
  { state: 'PA', url: '/insurance/auto/cheapest-car-insurance-pennsylvania/' },
  { state: 'RI', url: '/insurance/auto/cheapest-car-insurance-rhode-Island/' },
  { state: 'SC', url: '/insurance/auto/cheapest-car-insurance-south-carolina/' },
  { state: 'SD', url: '/insurance/auto/cheapest-car-insurance-south-dakota/' },
  { state: 'TN', url: '/insurance/auto/cheapest-car-insurance-tennessee/' },
  { state: 'TX', url: '/insurance/auto/cheapest-car-insurance-texas/' },
  { state: 'UT', url: '/insurance/auto/cheapest-car-insurance-utah/' },
  { state: 'VT', url: '/insurance/auto/cheapest-car-insurance-vermont/' },
  { state: 'VA', url: '/insurance/auto/cheapest-car-insurance-virginia/' },
  { state: 'WA', url: '/insurance/auto/cheapest-car-insurance-washington/' },
  { state: 'WV', url: '/insurance/auto/cheapest-car-insurance-west-virginia/' },
  { state: 'WI', url: '/insurance/auto/cheapest-car-insurance-wisconsin/' },
  { state: 'WY', url: '/insurance/auto/cheapest-car-insurance-wyoming/' }
];

const homeStatesList = [
  { state: 'AL', url: '/insurance/homeowners/best-cheap-homeowners-insurance-alabama/' },
  { state: 'AK', url: '/insurance/homeowners/best-cheap-homeowners-insurance-alaska/' },
  { state: 'AZ', url: '/insurance/homeowners/best-cheap-homeowners-insurance-arizona/' },
  { state: 'AR', url: '/insurance/homeowners/best-cheap-homeowners-insurance-arkansas/' },
  { state: 'CA', url: '/insurance/homeowners/best-cheap-homeowners-insurance-california/' },
  { state: 'CO', url: '/insurance/homeowners/best-cheap-homeowners-insurance-colorado/' },
  { state: 'CT', url: '/insurance/homeowners/best-cheap-homeowners-insurance-connecticut/' },
  { state: 'DE', url: '/insurance/homeowners/best-cheap-homeowners-insurance-delaware/' },
  { state: 'FL', url: '/insurance/homeowners/best-cheap-homeowners-insurance-florida/' },
  { state: 'GA', url: '/insurance/homeowners/best-cheap-homeowners-insurance-georgia/' },
  { state: 'HI', url: '/insurance/homeowners/best-cheap-homeowners-insurance-hawaii/' },
  { state: 'ID', url: '/insurance/homeowners/best-cheap-homeowners-insurance-idaho/' },
  { state: 'IL', url: '/insurance/homeowners/best-cheap-homeowners-insurance-illinois/' },
  { state: 'IN', url: '/insurance/homeowners/best-cheap-homeowners-insurance-indiana/' },
  { state: 'IA', url: '/insurance/homeowners/best-cheap-homeowners-insurance-iowa/' },
  { state: 'KS', url: '/insurance/homeowners/best-cheap-homeowners-insurance-kansas/' },
  { state: 'KY', url: '/insurance/homeowners/best-cheap-homeowners-insurance-kentucky/' },
  { state: 'LA', url: '/insurance/homeowners/best-cheap-homeowners-insurance-louisiana/' },
  { state: 'ME', url: '/insurance/homeowners/best-cheap-homeowners-insurance-maine/' },
  { state: 'MD', url: '/insurance/homeowners/best-cheap-homeowners-insurance-maryland/' },
  { state: 'MA', url: '/insurance/homeowners/best-cheap-homeowners-insurance-massachusetts/' },
  { state: 'MI', url: '/insurance/homeowners/best-cheap-homeowners-insurance-michigan/' },
  { state: 'MN', url: '/insurance/homeowners/best-cheap-homeowners-insurance-minnesota/' },
  { state: 'MS', url: '/insurance/homeowners/best-cheap-homeowners-insurance-mississippi/' },
  { state: 'MO', url: '/insurance/homeowners/best-cheap-homeowners-insurance-missouri/' },
  { state: 'MT', url: '/insurance/homeowners/best-cheap-homeowners-insurance-montana/' },
  { state: 'NE', url: '/insurance/homeowners/best-cheap-homeowners-insurance-nebraska/' },
  { state: 'NV', url: '/insurance/homeowners/best-cheap-homeowners-insurance-nevada/' },
  { state: 'NH', url: '/insurance/homeowners/best-cheap-homeowners-insurance-new-hampshire/' },
  { state: 'NJ', url: '/insurance/homeowners/best-cheap-homeowners-insurance-new-jersey/' },
  { state: 'NM', url: '/insurance/homeowners/best-cheap-homeowners-insurance-new-mexico/' },
  { state: 'NY', url: '/insurance/homeowners/best-cheap-homeowners-insurance-new-york/' },
  { state: 'NC', url: '/insurance/homeowners/best-cheap-homeowners-insurance-north-carolina/' },
  { state: 'ND', url: '/insurance/homeowners/best-cheap-homeowners-insurance-north-dakota/' },
  { state: 'OH', url: '/insurance/homeowners/best-cheap-homeowners-insurance-ohio/' },
  { state: 'OK', url: '/insurance/homeowners/best-cheap-homeowners-insurance-oklahoma/' },
  { state: 'OR', url: '/insurance/homeowners/best-cheap-homeowners-insurance-oregon/' },
  { state: 'PA', url: '/insurance/homeowners/best-cheap-homeowners-insurance-pennsylvania/' },
  { state: 'RI', url: '/insurance/homeowners/best-cheap-homeowners-insurance-rhode-Island/' },
  { state: 'SC', url: '/insurance/homeowners/best-cheap-homeowners-insurance-south-carolina/' },
  { state: 'SD', url: '/insurance/homeowners/best-cheap-homeowners-insurance-south-dakota/' },
  { state: 'TN', url: '/insurance/homeowners/best-cheap-homeowners-insurance-tennessee/' },
  { state: 'TX', url: '/insurance/homeowners/best-cheap-homeowners-insurance-texas/' },
  { state: 'UT', url: '/insurance/homeowners/best-cheap-homeowners-insurance-utah/' },
  { state: 'VT', url: '/insurance/homeowners/best-cheap-homeowners-insurance-vermont/' },
  { state: 'VA', url: '/insurance/homeowners/best-cheap-homeowners-insurance-virginia/' },
  { state: 'WA', url: '/insurance/homeowners/best-cheap-homeowners-insurance-washington/' },
  { state: 'WV', url: '/insurance/homeowners/best-cheap-homeowners-insurance-west-virginia/' },
  { state: 'WI', url: '/insurance/homeowners/best-cheap-homeowners-insurance-wisconsin/' },
  { state: 'WY', url: '/insurance/homeowners/best-cheap-homeowners-insurance-wyoming/' }
];

const rentersStatesList = [
  { state: 'AL', url: '/insurance/renters/best-cheap-renters-insurance-in-alabama/' },
  { state: 'AK', url: '/insurance/renters/best-cheap-renters-insurance-in-alaska/' },
  { state: 'AZ', url: '/insurance/renters/best-cheap-renters-insurance-in-arizona/' },
  { state: 'AR', url: '/insurance/renters/best-cheap-renters-insurance-in-arkansas/' },
  { state: 'CA', url: '/insurance/renters/best-cheap-renters-insurance-in-california/' },
  { state: 'CO', url: '/insurance/renters/best-cheap-renters-insurance-in-colorado/' },
  { state: 'CT', url: '/insurance/renters/best-cheap-renters-insurance-in-connecticut/' },
  { state: 'DC', url: '/insurance/renters/best-cheap-renters-insurance-in-district-of-columbia/' },
  { state: 'DE', url: '/insurance/renters/best-cheap-renters-insurance-in-delaware/' },
  { state: 'FL', url: '/insurance/renters/best-cheap-renters-insurance-in-florida/' },
  { state: 'GA', url: '/insurance/renters/best-cheap-renters-insurance-in-georgia/' },
  { state: 'HI', url: '/insurance/renters/best-cheap-renters-insurance-in-hawaii/' },
  { state: 'ID', url: '/insurance/renters/best-cheap-renters-insurance-in-idaho/' },
  { state: 'IL', url: '/insurance/renters/best-cheap-renters-insurance-in-illinois/' },
  { state: 'IN', url: '/insurance/renters/best-cheap-renters-insurance-in-indiana/' },
  { state: 'IA', url: '/insurance/renters/best-cheap-renters-insurance-in-iowa/' },
  { state: 'KS', url: '/insurance/renters/best-cheap-renters-insurance-in-kansas/' },
  { state: 'KY', url: '/insurance/renters/best-cheap-renters-insurance-in-kentucky/' },
  { state: 'LA', url: '/insurance/renters/best-cheap-renters-insurance-in-louisiana/' },
  { state: 'ME', url: '/insurance/renters/best-cheap-renters-insurance-in-maine/' },
  { state: 'MD', url: '/insurance/renters/best-cheap-renters-insurance-in-maryland/' },
  { state: 'MA', url: '/insurance/renters/best-cheap-renters-insurance-in-massachusetts/' },
  { state: 'MI', url: '/insurance/renters/best-cheap-renters-insurance-in-michigan/' },
  { state: 'MN', url: '/insurance/renters/best-cheap-renters-insurance-in-minnesota/' },
  { state: 'MS', url: '/insurance/renters/best-cheap-renters-insurance-in-mississippi/' },
  { state: 'MO', url: '/insurance/renters/best-cheap-renters-insurance-in-missouri/' },
  { state: 'MT', url: '/insurance/renters/best-cheap-renters-insurance-in-montana/' },
  { state: 'NE', url: '/insurance/renters/best-cheap-renters-insurance-in-nebraska/' },
  { state: 'NV', url: '/insurance/renters/best-cheap-renters-insurance-in-nevada/' },
  { state: 'NH', url: '/insurance/renters/best-cheap-renters-insurance-in-new-hampshire/' },
  { state: 'NJ', url: '/insurance/renters/best-cheap-renters-insurance-in-new-jersey/' },
  { state: 'NM', url: '/insurance/renters/best-cheap-renters-insurance-in-new-mexico/' },
  { state: 'NY', url: '/insurance/renters/best-cheap-renters-insurance-in-new-york/' },
  { state: 'NC', url: '/insurance/renters/best-cheap-renters-insurance-in-north-carolina/' },
  { state: 'ND', url: '/insurance/renters/best-cheap-renters-insurance-in-north-dakota/' },
  { state: 'OH', url: '/insurance/renters/best-cheap-renters-insurance-in-ohio/' },
  { state: 'OK', url: '/insurance/renters/best-cheap-renters-insurance-in-oklahoma/' },
  { state: 'OR', url: '/insurance/renters/best-cheap-renters-insurance-in-oregon/' },
  { state: 'PA', url: '/insurance/renters/best-cheap-renters-insurance-in-pennsylvania/' },
  { state: 'RI', url: '/insurance/renters/best-cheap-renters-insurance-in-rhode-Island/' },
  { state: 'SC', url: '/insurance/renters/best-cheap-renters-insurance-in-south-carolina/' },
  { state: 'SD', url: '/insurance/renters/best-cheap-renters-insurance-in-south-dakota/' },
  { state: 'TN', url: '/insurance/renters/best-cheap-renters-insurance-in-tennessee/' },
  { state: 'TX', url: '/insurance/renters/best-cheap-renters-insurance-in-texas/' },
  { state: 'UT', url: '/insurance/renters/best-cheap-renters-insurance-in-utah/' },
  { state: 'VT', url: '/insurance/renters/best-cheap-renters-insurance-in-vermont/' },
  { state: 'VA', url: '/insurance/renters/best-cheap-renters-insurance-in-virginia/' },
  { state: 'WA', url: '/insurance/renters/best-cheap-renters-insurance-in-washington/' },
  { state: 'WV', url: '/insurance/renters/best-cheap-renters-insurance-in-west-virginia/' },
  { state: 'WI', url: '/insurance/renters/best-cheap-renters-insurance-in-wisconsin/' },
  { state: 'WY', url: '/insurance/renters/best-cheap-renters-insurance-in-wyoming/' }
];

const stateCrossLinkingExtendedData = [
  { state: 'AL', url: '/insurance/auto/cheapest-car-insurance-alabama/', company: 'State Farm', avgPremium: '1200' },
  { state: 'AK', url: '/insurance/auto/cheapest-car-insurance-alaska/', company: 'State Farm', avgPremium: '1200' },
  { state: 'AZ', url: '/insurance/auto/cheapest-car-insurance-arizona/', company: 'State Farm', avgPremium: '1200' },
  { state: 'AR', url: '/insurance/auto/cheapest-car-insurance-arkansas/', company: 'State Farm', avgPremium: '1200' },
  { state: 'CA', url: '/insurance/auto/cheapest-car-insurance-california/', company: 'State Farm', avgPremium: '1200' },
  { state: 'CO', url: '/insurance/auto/cheapest-car-insurance-colorado/', company: 'State Farm', avgPremium: '1200' },
  { state: 'CT', url: '/insurance/auto/cheapest-car-insurance-connecticut/', company: 'State Farm', avgPremium: '1200' },
  { state: 'DC', url: '', company: 'State Farm', avgPremium: '1200' },
  { state: 'DE', url: '/insurance/auto/cheapest-car-insurance-delaware/', company: 'State Farm', avgPremium: '1200' },
  { state: 'FL', url: '/insurance/auto/cheapest-car-insurance-florida/', company: 'State Farm', avgPremium: '1200' },
  { state: 'GA', url: '/insurance/auto/cheapest-car-insurance-georgia/', company: 'State Farm', avgPremium: '1200' },
  { state: 'HI', url: '/insurance/auto/cheapest-car-insurance-hawaii/', company: 'State Farm', avgPremium: '1200' },
  { state: 'ID', url: '/insurance/auto/cheapest-car-insurance-idaho/', company: 'State Farm', avgPremium: '1200' },
  { state: 'IL', url: '/insurance/auto/cheapest-car-insurance-illinois/', company: 'State Farm', avgPremium: '1200' },
  { state: 'IN', url: '/insurance/auto/cheapest-car-insurance-indiana/', company: 'State Farm', avgPremium: '1200' },
  { state: 'IA', url: '/insurance/auto/cheapest-car-insurance-iowa/', company: 'State Farm', avgPremium: '1200' },
  { state: 'KS', url: '/insurance/auto/cheapest-car-insurance-kansas/', company: 'State Farm', avgPremium: '1200' },
  { state: 'KY', url: '/insurance/auto/cheapest-car-insurance-kentucky/', company: 'State Farm', avgPremium: '1200' },
  { state: 'LA', url: '/insurance/auto/cheapest-car-insurance-louisiana/', company: 'State Farm', avgPremium: '1200' },
  { state: 'ME', url: '/insurance/auto/cheapest-car-insurance-maine/', company: 'State Farm', avgPremium: '1200' },
  { state: 'MD', url: '/insurance/auto/cheapest-car-insurance-maryland/', company: 'State Farm', avgPremium: '1200' },
  { state: 'MA', url: '/insurance/auto/cheapest-car-insurance-massachusetts/', company: 'State Farm', avgPremium: '1200' },
  { state: 'MI', url: '/insurance/auto/cheapest-car-insurance-michigan/', company: 'State Farm', avgPremium: '1200' },
  { state: 'MN', url: '/insurance/auto/cheapest-car-insurance-minnesota/', company: 'State Farm', avgPremium: '1200' },
  { state: 'MS', url: '/insurance/auto/cheapest-car-insurance-mississippi/', company: 'State Farm', avgPremium: '1200' },
  { state: 'MO', url: '/insurance/auto/cheapest-car-insurance-missouri/', company: 'State Farm', avgPremium: '1200' },
  { state: 'MT', url: '/insurance/auto/cheapest-car-insurance-montana/', company: 'State Farm', avgPremium: '1200' },
  { state: 'NE', url: '/insurance/auto/cheapest-car-insurance-nebraska/', company: 'State Farm', avgPremium: '1200' },
  { state: 'NV', url: '', company: 'State Farm', avgPremium: '1200' },
  { state: 'NH', url: '/insurance/auto/cheapest-car-insurance-new-hampshire/', company: 'State Farm', avgPremium: '1200' },
  { state: 'NJ', url: '/insurance/auto/cheapest-car-insurance-new-jersey/', company: 'State Farm', avgPremium: '1200' },
  { state: 'NM', url: '/insurance/auto/cheapest-car-insurance-new-mexico/', company: 'State Farm', avgPremium: '1200' },
  { state: 'NY', url: '/insurance/auto/cheapest-car-insurance-new-york/', company: 'State Farm', avgPremium: '1200' },
  { state: 'NC', url: '/insurance/auto/cheapest-car-insurance-north-carolina/', company: 'State Farm', avgPremium: '1200' },
  { state: 'ND', url: '/insurance/auto/cheapest-car-insurance-north-dakota/', company: 'State Farm', avgPremium: '1200' },
  { state: 'OH', url: '/insurance/auto/cheapest-car-insurance-ohio/', company: 'State Farm', avgPremium: '1200' },
  { state: 'OK', url: '/insurance/auto/cheapest-car-insurance-oklahoma/', company: 'State Farm', avgPremium: '1200' },
  { state: 'OR', url: '/insurance/auto/cheapest-car-insurance-oregon/', company: 'State Farm', avgPremium: '1200' },
  { state: 'PA', url: '/insurance/auto/cheapest-car-insurance-pennsylvania/', company: 'State Farm', avgPremium: '1200' },
  { state: 'RI', url: '/insurance/auto/cheapest-car-insurance-rhode-Island/', company: 'State Farm', avgPremium: '1200' },
  { state: 'SC', url: '/insurance/auto/cheapest-car-insurance-south-carolina/', company: 'State Farm', avgPremium: '1200' },
  { state: 'SD', url: '/insurance/auto/cheapest-car-insurance-south-dakota/', company: 'State Farm', avgPremium: '1200' },
  { state: 'TN', url: '/insurance/auto/cheapest-car-insurance-tennessee/', company: 'State Farm', avgPremium: '1200' },
  { state: 'TX', url: '/insurance/auto/cheapest-car-insurance-texas/', company: 'State Farm', avgPremium: '1200' },
  { state: 'UT', url: '/insurance/auto/cheapest-car-insurance-utah/', company: 'State Farm', avgPremium: '1200' },
  { state: 'VT', url: '/insurance/auto/cheapest-car-insurance-vermont/', company: 'State Farm', avgPremium: '1200' },
  { state: 'VA', url: '/insurance/auto/cheapest-car-insurance-virginia/', company: 'State Farm', avgPremium: '1200' },
  { state: 'WA', url: '/insurance/auto/cheapest-car-insurance-washington/', company: 'State Farm', avgPremium: '1200' },
  { state: 'WV', url: '/insurance/auto/cheapest-car-insurance-west-virginia/', company: 'State Farm', avgPremium: '1200' },
  { state: 'WI', url: '/insurance/auto/cheapest-car-insurance-wisconsin/', company: 'State Farm', avgPremium: '1200' },
  { state: 'WY', url: '/insurance/auto/cheapest-car-insurance-wyoming/', company: 'State Farm', avgPremium: '1200' }
];

export { autoStatesList, homeStatesList, rentersStatesList, stateCrossLinkingExtendedData };
