import React from 'react';
import PropTypes from 'prop-types';
import { QuoteContainer, DoubleQuoteContainer, SingleQuoteContainer } from './Quote.style';

const DOUBLE_QUOTE_TAGS = ['h1', 'h2', 'h3', 'h4', 'h5', 'h6'];

const ORIENTATIONS = ['left', 'center', 'right'];

const DoubleQuote = ({ content }) => (
  <DoubleQuoteContainer>
    <div className="doubleQuoteLeftSymbol">
      <svg>
        {/* eslint-disable-next-line max-len */}
        <image xlinkHref="https://res.cloudinary.com/moneygeek/image/upload/v1578342095/assets/double_quotes_pqdvpm.svg" />
      </svg>
    </div>
    <div className="doubleQuoteText">
      {content}
    </div>
    <div className="doubleQuoteRightSymbol">
      <svg>
        {/* eslint-disable-next-line max-len */}
        <image xlinkHref="https://res.cloudinary.com/moneygeek/image/upload/v1578342095/assets/double_quotes_pqdvpm.svg" />
      </svg>
    </div>
  </DoubleQuoteContainer>
);

DoubleQuote.propTypes = { content: PropTypes.element };

const SingleQuote = ({ expertName, expertDetails, content }) => (
  <SingleQuoteContainer>
    <div className="singleQuoteSymbol">
      <svg>
        {/* eslint-disable-next-line max-len */}
        <image xlinkHref="https://res.cloudinary.com/moneygeek/image/upload/v1578342095/assets/double_quotes_pqdvpm.svg" />
      </svg>
    </div>
    <div className="singleQuoteText">
      {content}
      <div className="expertName">
        {expertName}
      </div>
      <div className="expertDetails">
        {expertDetails}
      </div>
    </div>
  </SingleQuoteContainer>
);

SingleQuote.propTypes = {
  expertName: PropTypes.string,
  expertDetails: PropTypes.string,
  content: PropTypes.oneOfType([PropTypes.element, PropTypes.string])
};

/**
 * Quote component.
 *
 * This component would show a quote container using the children elements that are inside of it.
 * If the children element includes a heading tag (<hx>), the container must show two quote symbols.
 * For the other cases, the component must show one quote symbol adding information about the author and other details.
 * @param {*} children
 */
const Quote = ({ float, width, author, details, children }) => (
  <QuoteContainer orientation={float} maxWidth={width}>
    {React.Children.map(children, child => {
      if (DOUBLE_QUOTE_TAGS.includes(child.type)) {
        return (<DoubleQuote content={child} />);
      } else {
        return (<SingleQuote content={child} expertName={author} expertDetails={details} />);
      }
    })}
  </QuoteContainer>
);

Quote.propTypes = {

  /**
   * Orientations available for the quote.
   * When an orientation is not defined, the quote container aligns to the left.
   */
  float: PropTypes.oneOf(ORIENTATIONS),

  /**
   * Custom max width. Works only for desktop view.
   */
  width: PropTypes.string,

  /**
   * Quote author name. Applies for single quote component.
   */
  author: PropTypes.string,

  /**
   * Additional details for the quote. Applies for single quote component.
   */
  details: PropTypes.string,

  /**
   * Inner element of the quote container.
   */
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.string])
};

export default Quote;
