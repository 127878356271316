import styled from 'styled-components';
import { colors, typography } from './../../theme_config/theme';
import sizes from '../../theme_config/sizes';
import '../../fonts/_fonts.css';

const btnClassColors = {
  boxOutlinedAnchorColor: colors.brandingElements, // Blue
  boxOutlinedAnchorHoverColor: colors.brandingElementsHover,
  boxOutlinedAnchorVisitedColor: colors.visitedLink,
  boxPrimaryOutlinedBgColor: '',
  boxPrimaryOutlinedTextColor: colors.text, // Gray
  boxPrimaryOutlinedBorderColor: colors.brandingElements, // Blue
  boxPrimarySolidBgColor: colors.brandingElements, // Blue
  boxPrimarySolidTextColor: colors.textLight, // White
  boxSecondaryOutlinedBgColor: '',
  boxSecondaryOutlinedTextColor: colors.text, // Gray
  boxSecondaryOutlinedBorderColor: colors.salmon, // Salmon
  boxSecondarySolidBgColor: colors.salmon, // Salmon
  boxSecondarySolidTextColor: colors.textLight // White
};

const boxIsSolid = props => props.componentClassName.includes('box-solid');
const boxIsOutlined = props => !props.componentClassName.includes('box-solid');
// boxIsPrimary has to be done this way, because className could be "box-solid", but still be a box-primary
const boxIsPrimary = props => !props.componentClassName.includes('box-secondary');
const boxIsSecondary = props => props.componentClassName.includes('box-secondary');

const calculateTextColor = props => {
  if (boxIsOutlined(props) && boxIsPrimary(props)) { // Outlined and primary
    return btnClassColors.boxPrimaryOutlinedBorderColor;
  }
  if (boxIsOutlined(props) && boxIsSecondary(props)) { // Outlined and secondary
    return btnClassColors.boxSecondaryOutlinedBorderColor;
  }
  if (boxIsSolid(props) && boxIsPrimary(props)) { // Solid and primary
    return btnClassColors.boxPrimarySolidTextColor;
  }
  if (boxIsSolid(props) && boxIsSecondary(props)) { // Solid and secondary
    return btnClassColors.boxSecondarySolidTextColor;
  }
};

const calculateBackgroundColor = props => {
  if (boxIsOutlined(props) && boxIsPrimary(props)) { // Outlined and primary
    return btnClassColors.boxPrimaryOutlinedBgColor;
  }
  if (boxIsOutlined(props) && boxIsSecondary(props)) { // Outlined and secondary
    return btnClassColors.boxSecondaryOutlinedBgColor;
  }
  if (boxIsSolid(props) && boxIsPrimary(props)) { // Solid and primary
    return btnClassColors.boxPrimarySolidBgColor;
  }
  if (boxIsSolid(props) && boxIsSecondary(props)) { // Solid and secondary
    return btnClassColors.boxSecondarySolidBgColor;
  }
};

const BoxStyle = styled.div`
  display: flex;
  align-items: center;
  max-width: ${props => props.fullWidth ? '100%' : '256px'};
  float: ${props => props.float};
  margin: ${props => props.float ? '0 14px' : 'inherit'};
  @media (max-width: ${sizes.palmEnd}) {
      max-width: ${props => props.float ? '256px' : 'unset'};
      margin: ${props => props.float ? '0 auto 10px auto' : 'inherit'};
      float: ${props => props.float = 'unset'};
    }

  .box-colored-text{
    color: ${props => calculateTextColor(props)};
  }

  .title-full-width {
    margin: 36px 0 30px 16px;
    @media (max-width: ${sizes.palmEnd}) {
      margin: 10px 0 30px 0;
    }
  }

  div#inner-div{
    width:${props => props.fullWidth ? '100%' : 'auto'};
    min-height: ${props => props.fullWidth || props.unsetHeight ? 'unset' : '324px'};
    background-image: ${props => boxIsSolid(props) ?
    'url("https://res.cloudinary.com/moneygeek/image/upload/v1578345642/assets/maze_xzo7rq.png")'
    : 'none'};
    background-position: center center;
    border-radius: 4px;
    border-color: ${btnClassColors.boxPrimaryOutlinedBorderColor};
    background-color: ${props => boxIsSolid(props) ? btnClassColors.boxPrimarySolidBgColor : undefined};
    color: ${props => boxIsSolid(props) ? btnClassColors.boxPrimarySolidTextColor : undefined};
    border-style: solid;
    border-width: 1px;
    font-family: ${typography.fontFamilyBase};
    font-size: ${typography.fontSize};
    text-align: center;
    line-height: 28px;
    @media (max-width: ${sizes.palmEnd}) {
      min-height: unset;
      height: auto;
      display: flex;
      justify-content: center;
    }
  }

  .columned {
    display: inline-block;
    @media (max-width: ${sizes.palmEnd}) {
      display: flex;
      flex-direction: column;
    }
  }

  #inner-margin-div{
    margin: 20px;
  }

  .fullwidth-columned-height {
    height: 100%;
  }

  .box-primary{
    border-color: ${btnClassColors.boxPrimaryOutlinedBorderColor} !important;
    background-color: ${props => boxIsSolid(props) ? btnClassColors.boxPrimarySolidBgColor : undefined} !important;
    color: ${props => boxIsSolid(props) ? btnClassColors.boxPrimarySolidTextColor : undefined} !important;
  }

  .box-secondary{
    border-color: ${btnClassColors.boxSecondaryOutlinedBorderColor} !important;
    background-color: ${props => boxIsSolid(props) ? btnClassColors.boxSecondarySolidBgColor : undefined}!important;
    color: ${props => boxIsSolid(props) ? btnClassColors.boxSecondarySolidTextColor : undefined}!important;
  }

  .box-outlined{
    border-style: solid !important;
    border-width: 1px !important;
  }

  h1,h2,h3,h4,h5,h6{
    text-transform: uppercase;
    font-family: ${typography.fontFamilyHeaderBold};
    font-weight: 700;
  }

  h3{
    font-size:20px;
    line-height: 26px;
  }

  .box-emphasize{
    text-transform: uppercase;
    font-family: ${typography.fontFamilyHeaderLight};
    font-weight: 400;
    font-size: 24px;
    line-height: 30px;
    @media (max-width: ${sizes.palmEnd}) {
      margin-top: 0px !important;
    }
  }

  p {
    margin: 0;
    font-family: ${typography.fontFamilyBase};
    font-weight: normal;
  }

  .margin-paragraph {
    margin: 8px 0;
  }

  .text-left {
    text-align: left;
  }

  .text-center {
    text-align: center;
  }
  
  .text-right {
    text-align: right;
  }

  .break-line > .text-left, .text-right {
    @media (max-width: ${sizes.palmEnd}) {
      text-align: center;
      margin-top: 0px !important;
    }
  }

  .box-column-item {
    margin-left: 30px;
  }

  .center-content {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .break-line {
    padding-top: 20px;
    margin: 20px auto 0px auto;
    width: 96%;
    border-top: thin solid white;
    align-items: center;
    @media (max-width: ${sizes.palmEnd}) {
      flex-direction: column;
      display: flex;
    }
  }

  .box-icon-description{
    position:relative;
    align-items: center;
    display: flex;
    flex-direction: column;
  }

  a{
    color: ${props => boxIsOutlined(props) ? btnClassColors.boxOutlinedAnchorColor : calculateTextColor(props)};
    text-decoration: ${props => boxIsOutlined(props) ? 'none' : 'underline'};
    background-color: ${props => boxIsOutlined(props) ? undefined : calculateBackgroundColor(props)};

    &:hover{
      color: ${props => boxIsOutlined(props) ? btnClassColors.boxOutlinedAnchorHoverColor : undefined};
      background-color: ${props => boxIsOutlined(props) ?
    undefined : (boxIsPrimary(props) ? 'rgb(98,124,255)' : 'rgb(251,141,128)')};
    }

    &:active {
      color: ${props => boxIsOutlined(props) ? btnClassColors.boxOutlinedAnchorColor : undefined};
      background-color: ${props => boxIsOutlined(props) ?
    undefined : (boxIsPrimary(props) ? 'rgb(98,124,255)' : 'rgb(251,141,128)')};
    }

    &:visited {
      color: ${props => boxIsOutlined(props) ? btnClassColors.boxOutlinedAnchorVisitedColor : undefined};
      background-color: ${props => boxIsOutlined(props) ?
    undefined : (boxIsPrimary(props) ? 'rgb(109,120,248)' : 'rgb(244,134,135)')};
    }

  }
`;
export default BoxStyle;
