import React from 'react';
import PropTypes from 'prop-types';
import { ProsConsContainer, SingleContainer } from './ProsCons.style';
import { colors } from './../../theme_config/theme';
import Icon from '../Icon/Icon';

const setIconColor = (type, color) => {
  if (color) {
    return color;
  } else {
    return type === 'pros' ? colors.brandingElements : colors.error;
  }
};

const ItemsContainer = ({ type, background, children }) => (
  <SingleContainer color={background} type={type}>
    <h4>{type}</h4>
    {React.Children.map(children, child => (
      <div className="item">
        <Icon name={type === 'pros' ? 'checkmark' : 'close'} size="30px" color={setIconColor(type, background)} />
        <span>{child.props.children}</span>
      </div>
    ))}
  </SingleContainer>
);

ItemsContainer.propTypes = {
  type: PropTypes.string,
  background: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf(PropTypes.element)])
};

/**
 * ProsCons component.
 *
 * This component would show a pros/cons container using the inner
 * children information defined and background color parameters provided.
 */
const ProsCons = ({ children }) => {
  return (
    <ProsConsContainer>
      {React.Children.map(children, child => (
        <ItemsContainer type={child.props.type} background={child.props.backgroundColor}>
          {child.props.children}
        </ItemsContainer>
      ))}
    </ProsConsContainer>
  );
};

ProsCons.propTypes = {

  /**
   * Inner element of the pros/cons container.
   */
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf(PropTypes.element)])
};

export default ProsCons;
