import React from 'react';
import PropTypes from 'prop-types';

import BreakLineContainer from './BreakLine.style.js';

/**
 * BreakLine component
 *
 * This component would show a BreakLine with adjustable color, type and thickness.
 *
 * @param {*} size
 * @param {*} name
 * @param {*} altText
 */
const BreakLine = ({ color, type, thickness }) => {
  return (
    <BreakLineContainer color={color} type={type} thickness={thickness}>
      <hr/>
    </BreakLineContainer>
  );
};

BreakLine.propTypes = {

  /**
   * The BreakLine color. Gray by default.
   */
  color: PropTypes.string,

  /**
   * The type used. Can be one of the following: solid (default), dotted, dashed or double.
   */
  type: PropTypes.string,

  /**
   * The thickness of the line. Use only numbers (1, 2, 3, 45...)
   */
  thickness: PropTypes.number

};

BreakLine.defaultProps = {
  color: '#CBCBCB',
  type: 'solid',
  thickness: 1
};

export default BreakLine;
