import React from 'react';
import PropTypes from 'prop-types';
import { CrossLinkingContainer } from './StateCrossLinking.style';
import { STATES, STATES_IMPORTANT_CITIES, formatURL, formatToCurrency } from '../../utils/utils';
import ShowMore from '../ShowMore/ShowMore';
import BreakeLine from '../BreakLine/BreakLine';
import Spacing from '../Spacing/Spacing';

const DESKTOP_END_FIRST_COLUMN = 17;
const DESKTOP_END_SECOND_COLUMN = 35;
const DESKTOP_END_THIRD_COLUMN = 53;

const TABLET_END_FIRST_COLUMN = 26;
const TABLET_END_SECOND_COLUMN = 53;

const BASE_URL = 'https://www.moneygeek.com';
const INSURANCE_URLS = {
  auto: '/insurance/auto/cheapest-car-insurance-',
  home: '/insurance/homeowners/best-cheap-homeowners-insurance-',
  renters: '/insurance/renters/best-cheap-renters-insurance-in-'
};

const WASHINGTON_CODE = 'DC';
const WASHINGTON_URL = '/insurance/auto/most-affordable-car-insurance-washington-dc-report/';

const getStateURL = (type, state) => `${INSURANCE_URLS[type]}${state.toLowerCase()}/`;

const getInsuranceURL = (url) => {
  const insuranceURL = url && formatURL(url);

  return `${BASE_URL}${insuranceURL}`;
};

const getStatesList = (insurance) => {
  const isHomeInsurance = insurance === 'home';

  if (isHomeInsurance) {
    return Object.keys(STATES).filter(item => item !== 'DC');
  } else {
    return Object.keys(STATES);
  }
};

const getStatesURL = (insurance) => {
  const statesList = getStatesList(insurance);
  const isAutoInsurance = insurance === 'auto';

  return statesList.map((state, index) => {
    const isWashingtonState = state === WASHINGTON_CODE;
    const stateURL = isAutoInsurance && isWashingtonState ? WASHINGTON_URL : getStateURL(insurance, STATES[state]);
    const autoInsuranceURL = getInsuranceURL(stateURL);

    return <a key={index} href={autoInsuranceURL}>{state}</a>;
  });
};

const getCustomStatesURL = (states, insurance) => {
  const isAutoInsurance = insurance === 'auto';
  let customStates = states;

  if (isAutoInsurance) {
    customStates = states.concat({
      state: WASHINGTON_CODE,
      url: WASHINGTON_URL
    });
  }

  return customStates.sort((a, b) => a.state.localeCompare(b.state)).map((item, index) => {
    const insuranceURL = getInsuranceURL(item.url);

    return <a key={index} href={insuranceURL}>{item.state}</a>;
  });
};

/**
 * StateCrossLinking component.
 *
 * This component would show a state cross linking container using the information
 * of all fifty states to list the auto insurance page url's and redirect them.
 */
const StateCrossLinking = ({ title, states, extended, data, margin, insurance, type }) => {
  const isExtended = extended === 'true';
  const statesList = states ? getCustomStatesURL(states, insurance) : getStatesURL(insurance);

  const shortVersion = (
    <>
      <h2>{title}</h2>
      <div className='links'>
        {statesList}
      </div>
    </>
  );

  const extendedVersion = () => {
    const showMore = new ShowMore(10, extendedVersionStatesMobile());
    return (
      <>
        <h2>{title}</h2>
        <div className='extendedContentDesktop'>
          <div className='extendedContent'>
            {extendedVersionStatesDesktop()}
          </div>
        </div>
        <div className='extendedContentTablet'>
          <div className='extendedContent'>
            {extendedVersionStatesTablet()}
          </div>
        </div>
        <div className='extendedContentMobile'>
          <div className='extendedContent'>
            {showMore.items}
            {showMore.button}
          </div>
        </div>
      </>
    );
  };

  // No Hyphen 'New-york', 'North-Carolina' => 'New York', 'North Carolina'
  const formatStateName = (stateName) => stateName.replace(/-/g, ' ');

  const dataSort = (a, b) => STATES[a.state] < STATES[b.state] ? -1 : Number(STATES[a.state] > STATES[b.state]);

  const extendedStateInfo = (dataState, index) => {
    const isAutoInsurance = insurance === 'auto';
    const isHomeInsurance = insurance === 'home';
    const isDCState = dataState.state === WASHINGTON_CODE;
    const stateURL = getStateURL(insurance, STATES[dataState.state]);
    const hasCustomURL = dataState.url ? true : false;
    const insuranceURL = isAutoInsurance && isDCState ? WASHINGTON_URL : hasCustomURL ? dataState.url : stateURL;
    const stateClassName = isHomeInsurance && isDCState ? 'extendedStateTitle no-url' : 'extendedStateTitle';

    return (
      <div className='extendedStateInfo' key={index}>
        {index !== 0 ? <BreakeLine color='#cbcbcb' thickness={1} /> : <></>}
        <div className='extendedStateInfoContainer'>
          <a className={stateClassName} href={getInsuranceURL(insuranceURL)}>
            {formatStateName(STATES[dataState.state])}
          </a>
          <span className='extendedStateDescription'>
            {type === 'averages' ?
              `Average annual cost of ${insurance} insurance in ${dataState.state
              } is ${formatToCurrency('USD', dataState.avgPremium)}`
              : `${dataState.company} is the ${type === 'cheapest' ? 'cheapest' : 'largest'
              } in ${dataState.state}`
            }
          </span>
          {isAutoInsurance && <span className='extendedStateTopCities'>{getImportantCities(dataState.state)}</span>}
        </div>
      </div>
    );
  };

  const extendedVersionStatesDesktop = () => {
    const statesData = data.sort(dataSort);
    const statesFirstColumn = statesData.slice(0, DESKTOP_END_FIRST_COLUMN);
    const statesSecondColumn = statesData.slice(DESKTOP_END_FIRST_COLUMN, DESKTOP_END_SECOND_COLUMN);
    const statesThirdColumn = statesData.slice(DESKTOP_END_SECOND_COLUMN, DESKTOP_END_THIRD_COLUMN);
    return data ?
      <>
        <div className='extendedContentFirstColumn'>
          {statesFirstColumn.map((dataState, index) => extendedStateInfo(dataState, index))}
        </div>
        <div className='extendedContentSecondColumn'>
          {statesSecondColumn.map((dataState, index) => extendedStateInfo(dataState, index))}
        </div>
        <div className='extendedContentThirdColumn'>
          {statesThirdColumn.map((dataState, index) => extendedStateInfo(dataState, index))}
        </div>
      </>
      : null;
  };

  const extendedVersionStatesTablet = () => {
    const statesData = data.sort(dataSort);
    const statesFirstColumn = statesData.slice(0, TABLET_END_FIRST_COLUMN);
    const statesSecondColumn = statesData.slice(TABLET_END_FIRST_COLUMN, TABLET_END_SECOND_COLUMN);
    return data ?
      <>
        <div className='extendedContentFirstColumn'>
          {statesFirstColumn.map((dataState, index) => extendedStateInfo(dataState, index))}
        </div>
        <div className='extendedContentSecondColumn'>
          {statesSecondColumn.map((dataState, index) => extendedStateInfo(dataState, index))}
        </div>
      </>
      : null;
  };

  const extendedVersionStatesMobile = () => {
    return data ? data.sort(dataSort).map((dataState, index) => extendedStateInfo(dataState, index)) : null;
  };

  const getImportantCities = (stateName) => {
    if (stateName != WASHINGTON_CODE) {
      const importantCities = STATES_IMPORTANT_CITIES[stateName];
      const importantCitiesLength = importantCities.length;
      return importantCities.map((city, index) => {
        const nextSeparator = index + 1 === importantCitiesLength ? <></> : <>, </>;
        return city.url ?
          <React.Fragment key={index}>
            <a href={formatURL(city.url, 'end')}>{city.name}</a>{nextSeparator}
          </React.Fragment>
          : <React.Fragment key={index}>{city.name}{nextSeparator}</React.Fragment>;
      });
    }
  };

  return (
    <Spacing margin={margin}>
      <CrossLinkingContainer>
        {isExtended && data ? extendedVersion() : shortVersion}
      </CrossLinkingContainer>
    </Spacing>
  );
};

StateCrossLinking.propTypes = {

  /**
   * Title for the component.
   */
  title: PropTypes.string,

  /**
   * States list to dynamically generate the component.
   */
  states: PropTypes.arrayOf(PropTypes.object),

  /**
   * Insurance type.
   */
  insurance: PropTypes.oneOf(['auto', 'home', 'renters']),

  /**
   * Can be 'cheapest' or 'largest'.
   */
  type: PropTypes.string,

  /**
   * Specifies if the component should display with extended information, data is needed
   */
  extended: PropTypes.string,

  /**
   * Data for the extended information
   */
  data: PropTypes.arrayOf(PropTypes.object),

  /**
   * Bottom margin space defined for the component (pixels).
   */
  margin: PropTypes.object
};

StateCrossLinking.defaultProps = {
  title: 'Find Cheap Insurance by State',
  extended: 'false',
  insurance: 'auto',
  states: null
};

export default StateCrossLinking;
