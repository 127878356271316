import React from 'react';
import PropTypes from 'prop-types';

import StarRankingStyle from './StarRanking.style.js';
import StarRankingContent from './StarRankingContent/StarRankingContent.js';
import StarRankingItem from './StarRankingContent/StarRankingItem.js';

/**
 * Star ranking component
 *
 * This component would show an Star ranking component with manual/database source or and database can be specified
 *
 * @param {*} source
 * @param {*} data
 * @param {*} children
 * @param {*} template
 */

// eslint-disable-next-line no-unused-vars
const StarRanking = ({ source, data, children, template }) => {
  let innerStarRankingHtml;

  if (source === 'manual') {
    innerStarRankingHtml = children;
  } else if (Object.keys(data).length) { // non-empty data THEN keys >= 1 THEN true
    if (template === 'customer-satisfaction') {
      innerStarRankingHtml =
        <StarRankingContent>
          <StarRankingItem title='First Notice of Loss:' ranking={data.firstNoticeLoss} starsSize="14px"/>
          <StarRankingItem title='Estimation of Process:' ranking={data.processEstimation} starsSize="14px"/>
          <StarRankingItem title='Repair Process:' ranking={data.repairProcess} starsSize="14px"/>
          <StarRankingItem title='Claims Servicing:' ranking= {data.claimsServicing} starsSize="14px"/>
          <StarRankingItem title='Rental Experience:' ranking={data.rentalExperience} starsSize="14px"/>
          <StarRankingItem title='Settlement:' ranking={data.settlement} starsSize="14px"/>
        </StarRankingContent>;
    }
  }

  return (<StarRankingStyle>{innerStarRankingHtml}</StarRankingStyle>);
};

StarRanking.propTypes = {

  /**
   * Determines where do the data come from
   */
  source: PropTypes.oneOf(['manual', 'data']),

  /**
   * Route to the data
   */
  data: PropTypes.object,

  /**
   * Children of the component
   */
  children: PropTypes.node,

  /**
   * Template for the component when data comes from different places
   *
   * Possible templates:
   *
   * customer-satisfaction
   */
  template: PropTypes.oneOf(['customer-satisfaction'])
};

StarRanking.defaultProps = {
  source: 'manual',
  data: {},
  useTitle: false,
  useContent: true
};

export default StarRanking;
