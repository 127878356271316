import { Playground, Props } from "docz";
import StaticPremiumTable from "../../../../../components/StaticPremiumTable/StaticPremiumTable";
import { data } from "../../../../../components/StaticPremiumTable/mock";
import * as React from 'react';
export default {
  Playground,
  Props,
  StaticPremiumTable,
  data,
  React
};