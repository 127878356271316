import styled from 'styled-components';
import typography from './../../theme_config/typography';
import colors from './../../theme_config/colors';
import sizes from '../../theme_config/sizes';
import '../../fonts/_fonts.css';

const btnClassColors = {
  primaryBgColor: colors.brandingElements, // Blue
  primaryTextColor: colors.textLight, // White
  primaryBorderColor: '',
  primaryHoverBgColor: '#3c5ae0', // Darkened Blue
  primaryHoverTextColor: '',
  secondaryBgColor: '#efeff1', // Light gray
  secondaryTextColor: colors.text, // Light black
  secondaryBorderColor: '',
  secondaryHoverBgColor: '#e5e5e5', // Lighter gray
  secondaryHoverTextColor: colors.text, // Light black
  anchorBgColor: colors.textLight, // White
  anchorTextColor: colors.brandingElements, // Blue
  anchorBorderColor: colors.brandingElements, // Blue
  anchorHoverBgColor: colors.brandingElements, // Blue
  anchorHoverTextColor: colors.textLight // White
};

export default styled.div`

    margin: 10px;

    button, a{
        -webkit-font-smoothing: antialiased;
        appearance: none;

        background-color: ${btnClassColors.primaryBgColor};
        margin: 0;
        border-radius: 3px;
        border: 0;
        box-shadow: none;
        color: ${btnClassColors.primaryTextColor};
        cursor: pointer;
        display: inline-block;
        transition: background-color 0.2s;
        outline: 0;
        padding: 10px 20px;

        font-family: ${typography.fontFamilyBase};
        font-size: 15px;
        font-style:normal;
        font-weight: 700;
        line-height: 22px;
        text-align: center;
        text-decoration: none;
        text-transform: uppercase;

        @media (min-width: ${sizes.tabletStart}) {
          min-width: 200px;
          padding: 15px;
        }

        &:hover {
          background-color: ${btnClassColors.primaryHoverBgColor};
        }

        &:active {
          background-color: ${btnClassColors.primaryBgColor};
        }

        &:disabled {
          cursor: not-allowed;
          background-color: #EFEFF1;
          color: #CCCCCC;
        }
    }

    .btn-primary{
        background-color: ${btnClassColors.primaryBgColor};
        color: ${btnClassColors.primaryTextColor};

        &:hover {
          background-color: ${btnClassColors.primaryHoverBgColor};
        }

        &:active {
          background-color: ${btnClassColors.primaryBgColor};
        }
    }

    .btn-secondary{
        background-color: ${btnClassColors.secondaryBgColor};
        color: ${btnClassColors.secondaryTextColor};

        &:hover {
          background-color: ${btnClassColors.secondaryHoverBgColor};
        }

        &:active {
          background-color: ${btnClassColors.secondaryBgColor};
        }
    }

    .btn-anchor{
        background-color: ${btnClassColors.anchorBgColor};
        border: solid;
        color: ${btnClassColors.anchorTextColor};
        border-width: 1px;
        border-color: ${btnClassColors.anchorBorderColor};
        padding: 10px 20px !important;

        &:hover {
          background-color: ${btnClassColors.anchorHoverBgColor};
          color: ${btnClassColors.anchorHoverTextColor};
        }

        &:active {
          background-color: ${btnClassColors.anchorBgColor};
          color: ${btnClassColors.anchorTextColor};
        }
    }
    
    .btn-inactive{
        cursor: not-allowed !important;
        background-color: #EFEFF1 !important;
        color: #CCCCCC !important;
    }
`;
