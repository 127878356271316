import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { PremiumRankingRow } from './PremiumRanking.style';
import { apiRequest, getShowRowsConfiguration } from '../../utils/utils';
import QuoteButton from '../QuoteButton/QuoteButton';

const transformCustomData = (data) => (
  data.map(item => ({
    company: {
      id: item.id,
      name: item.company,
      url: {
        primary: item.url
      }
    },
    marketShare: item.value
  }))
);

/**
 * Auto Insurance Market Share component, this is the Market Share version of the
 * Premium Ranking component. This component is going to be rendered when the
 * market-share datatype is specified.
 * @param {string} state the state of which the report should be filtered.
 * @param {string} year the year of which the report should be filtered.
 * @param {*} quotebutton if this parameter is set, the quotes button is going to be rendered.
 * @param {*} data Optional data that fills the component when it's used on a Template page.
 *  Overrides state and year parameters.
 * @param {*} showDescription if true each element will have a description.
 *  It uses regionLevel, state and year to create the description
 * @param {*} regionLevel specify the region level of the data
 */
const MarketShare = ({ state, year, quotebutton, data, reviewPages, showDescription, regionLevel, rows, type }) => {
  const [apiData, setApiData] = useState(data ? transformCustomData(data) : []);
  const requestParams = {
    year: year
  };

  useEffect(() => {
    // eslint-disable-next-line no-undef
    const script = document.createElement('script');
    script.src = 'https://res.cloudinary.com/moneygeek/raw/upload/v1605630850/libraries/serve_p4mqe6.js';
    script.crossOrigin = 'anonymous';
    script.async = true;
    // eslint-disable-next-line no-undef
    document.head.appendChild(script);
    !data && axios(apiRequest('get', `auto-state/${state}/other`, requestParams)).then(request => {
      setApiData(request.data.marketShare.filter(item => item.marketShare));
    });
  }, []);

  const items = getItems(apiData, reviewPages, state, year, quotebutton, showDescription, regionLevel, type);
  const showMore = getShowRowsConfiguration(rows, items);

  // Validates if any information is loaded from API or custom data parameter.
  const dataValidation = apiData.length > 0 ? true : false;

  return (<>
    <PremiumRankingRow>
      <div className={quotebutton ? 'company col-md gray-header' : 'company col-lg gray-header'}>
        <div className="title title-company">Company</div>
      </div>
      {getMarketShareHeaders(quotebutton)}
    </PremiumRankingRow>
    {dataValidation ? showMore.items : <h2>No information available</h2>}
    {dataValidation && showMore.button}
  </>
  );
};

const getMarketShareHeaders = (quotebutton) => {
  return quotebutton ? (
    <div className="col-rl">
      <div className="amount col-hf amount-header market-share-title">
        <div className="title title-amount">Market Share</div>
      </div>
      <div className="amount col-hf gray-header get-quote-title">
        <div className="title title-amount"></div>
      </div>
    </div>
  ) : (
    <div className="amount col-sm amount-header">
      <div className="title title-amount">Market Share</div>
    </div>
  );
};

const getItems = (data, reviewPages, state, year, quotebutton, showDescription, regionLevel, type) => {
  let index = 0;
  const isShowDescription = showDescription === 'true';
  const columnClass = quotebutton ? 'col-md' : 'col-lg';
  const quoteButtonClass = quotebutton ? 'resp-col-sm' : '';

  return data.sort((a, b) => b.marketShare - a.marketShare).map((item) => {
    const isIndexEven = index % 2 == 0;
    const companyClass = isIndexEven ? columnClass : `dark-background ${columnClass}`;
    const smallContainerClass = isIndexEven ? '' : 'dark-background';

    const reviewPage = reviewPages && reviewPages[item.company.id];
    const fullCompanyURL = `https://www.moneygeek.com${reviewPage}`;
    const companyColumn = reviewPage ? <a href={fullCompanyURL}>{item.company.name}</a> : item.company.name;

    return (
      <PremiumRankingRow key={index}>
        <div className={`company-body ${companyClass}`}>
          <div className="table full-height-per">
            <div className="vertical-center">
              <div className={`company-content company-name mg-top-16 ${isShowDescription ? '' : 'mg-bottom-16'}`}>
                {(index++) + 1}. {companyColumn}
              </div>
              {isShowDescription && getCompanyDescription(item.company.name, regionLevel, state, year, index, type)}
            </div>
          </div>
        </div>
        <div className={`amount-body col-sm ${smallContainerClass} ${quoteButtonClass}`}>
          <div className="table price-amount full-height-per full-width-per no-bold">
            <div className="vertical-center">
              {(Number(item.marketShare) * 100).toFixed(1) + '%'}
            </div>
          </div>
        </div>
        {
          quotebutton ? getQuoteButton(quotebutton, item.company, smallContainerClass) : null
        }
      </PremiumRankingRow>
    );
  });
};

const getQuoteButton = (quotebutton, company, smallContainerClass) => (
  <div className={`amount-body ${smallContainerClass} get-quote-panel`}>
    {company.url ? <QuoteButton quoteType="Market Share" type={quotebutton} displayText="GET QUOTE"
      company={{ company: company.name, insuranceUrl: company.url.primary }} /> : null}
  </div>
);

const getCompanyDescription = (company, regionlevel, state, year, index, type) => {
  /* eslint-disable camelcase */
  const vertical = {
    auto_insurance: 'auto',
    home_insurance: 'homeowners',
    renters_insurance: 'renters'
  };

  return (
    <div className="company-content company-description">
      {`In ${year}, ${company} ranked #${index} in ${regionlevel === 'national' ? 'the U.S. ' : state} 
      for ${type ? vertical[type] : 'auto'} insurance market share.`}
    </div>
  );
};

MarketShare.propTypes = {

  /**
   * Brings the insurance type to the market share description.
   */
  type: PropTypes.string,

  /**
   * All states available to load component information.
   * Full state names are used in component: Alabama, Alaska, California.
   */
  state: PropTypes.string,

  /**
   * Four digit year to load component information.
   */
  year: PropTypes.string,

  /**
   * Toggles the quote button column.
   */
  quotebutton: PropTypes.string,

  /**
   * Optional data that fills the component when it's used on a Template page. Overrides state and year parameters.
   */
  data: PropTypes.oneOfType([PropTypes.object, PropTypes.arrayOf(PropTypes.object)]),

  /**
   * Optional list used to transform the company label into an anchor for the company review page.
   */
  reviewPages: PropTypes.oneOfType([PropTypes.object, PropTypes.arrayOf(PropTypes.object)]),

  /**
   * Optional parameter that specify if a description is going to appear for each element
   */
  showDescription: PropTypes.oneOf(['true', 'false']),

  /**
   * Specify the region level of the data
   */
  regionLevel: PropTypes.oneOf(['national', 'state']),

  /**
   * Rows configuration to display on the component: number and text.
   */
  rows: PropTypes.object
};

export default MarketShare;
