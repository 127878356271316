import { Playground, Props } from "docz";
import Button from "../../../../../components/Button/Button";
import Quote from "../../../../../components/Quote/Quote";
import OrderedList from "../../../../../components/OrderedList/OrderedList";
import Icon from "../../../../../components/Icon/Icon";
import BestRanking from "../../../../../components/BestRanking/BestRanking";
import PremiumRanking from "../../../../../components/PremiumRanking/PremiumRanking";
import Box from "../../../../../components/Box/Box";
import QuotesWidget from "../../../../../components/QuotesWidget/QuotesWidget";
import StaticTable from "../../../../../components/StaticTable/StaticTable";
import Columns from "../../../../../components/Columns/Columns";
import ExpandCollapse from "../../../../../components/ExpandCollapse/ExpandCollapse";
import Stars from "../../../../../components/StarRanking/Stars";
import ColumnRangeChart from "../../../../../components/ColumnRangeChart/ColumnRangeChart";
import BreakLine from "../../../../../components/BreakLine/BreakLine";
import StateCrossLinking from "../../../../../components/StateCrossLinking/StateCrossLinking";
import QuoteButton from "../../../../../components/QuoteButton/QuoteButton";
import ProsCons from "../../../../../components/ProsCons/ProsCons";
import CompanyLogo from "../../../../../components/CompanyLogo/CompanyLogo";
import CompanyCrossLinking from "../../../../../components/CompanyCrossLinking/CompanyCrossLinking";
import ListRanking from "../../../../../components/ListRanking/ListRanking";
import Spacing from "../../../../../components/Spacing/Spacing";
import StaticPremiumTable from "../../../../../components/StaticPremiumTable/StaticPremiumTable";
import Badge from "../../../../../components/Badge/Badge";
import * as React from 'react';
export default {
  Playground,
  Props,
  Button,
  Quote,
  OrderedList,
  Icon,
  BestRanking,
  PremiumRanking,
  Box,
  QuotesWidget,
  StaticTable,
  Columns,
  ExpandCollapse,
  Stars,
  ColumnRangeChart,
  BreakLine,
  StateCrossLinking,
  QuoteButton,
  ProsCons,
  CompanyLogo,
  CompanyCrossLinking,
  ListRanking,
  Spacing,
  StaticPremiumTable,
  Badge,
  React
};