import React from 'react';
import { PremiumRankingContainer } from './PremiumRanking.style';
import MarketShare from './MarketShare';
import FinancialStability from './FinancialStability';
import PremiumRankingBody from './PremiumRankingBody';
import PremiumRankingHeader from './PremiumRankingHeader';
import PropTypes from 'prop-types';
import Spacing from '../Spacing/Spacing';

const getQuoteVertical = (quote) => {
  const isTrue = quote === 'true';
  const isFalse = quote === 'false';

  if (isTrue) {
    return 'auto';
  } else if (isFalse || !quote) {
    return null;
  } else {
    return quote;
  }
};

/**
 * PremiumRanking component.
 *
 * This component would show an HTML tables loaded through a dataset
 * @param {string} category the type of report the publisher wants to gather data from.
 * @param {string} state the state of which the report should be filtered.
 * @param {string} year the year of which the report should be filtered.
 * @param {string} datatype the state of which the type of report the publisher wants to be rendered.
 * @param {string} quotebutton String that defines if quotebutton should appear or not in the table
 * @param {object} data Object with custom data for rendering the table
 * @param {object} reviewPages object list with URL's for all published company review pages.
 * @param {boolean} showDescription if true each element will have a description, market share only
 * @param {*} regionLevel specify the region level of the data, market share only
 */
const PremiumRanking = ({
  category,
  state,
  year,
  datatype,
  quotebutton,
  data,
  reviewPages,
  showMonthlyValue,
  showDescription,
  regionLevel,
  rows,
  margin,
  type
}) => {
  const isMonthlyPremium = datatype === 'monthly-premiums';
  const isMarketShare = datatype === 'market-share';
  const isFinancialStability = datatype === 'financial-stability';
  const quoteVertical = getQuoteVertical(quotebutton);

  if (isMonthlyPremium) {
    return (
      <Spacing margin={margin}>
        <PremiumRankingContainer>
          <PremiumRankingHeader showMonthlyValue={showMonthlyValue} />
          <PremiumRankingBody
            category={category}
            state={state}
            year={year}
            data={data}
            reviewPages={reviewPages}
            showMonthlyValue={showMonthlyValue}
            showDescription={showDescription}
            rows={rows}
          />
        </PremiumRankingContainer>
      </Spacing>
    );
  } else if (isMarketShare) {
    return (
      <Spacing margin={margin}>
        <PremiumRankingContainer>
          <MarketShare
            state={state}
            year={year}
            type={type}
            quotebutton={quoteVertical}
            data={data}
            reviewPages={reviewPages}
            showDescription={showDescription}
            regionLevel={regionLevel}
            rows={rows} />
        </PremiumRankingContainer>
      </Spacing>
    );
  } else if (isFinancialStability) {
    return (
      <Spacing margin={margin}>
        <PremiumRankingContainer>
          <FinancialStability quotebutton={quoteVertical} data={data} reviewPages={reviewPages} rows={rows} />
        </PremiumRankingContainer>
      </Spacing>
    );
  }
};


PremiumRanking.propTypes = {

  /**
   * Brings the insurance type to the market share description.
   */
  type: PropTypes.string,

  /**
   * The selected category the publisher wants the data from.
   */
  category: PropTypes.oneOf(['average', 'seniors', 'students', 'speeding', 'dui', 'badCredit', 'liability']),

  /**
   * All states available to load component information.
   * Full state names are used in component: Alabama, Alaska, California.
   */
  state: PropTypes.string,

  /**
   * Four digit year to load component information.
   */
  year: PropTypes.string,

  /**
   * The selected datatype the publisher wants the data from.
   */
  datatype: PropTypes.oneOf(['monthly-premiums', 'market-share', 'financial-stability']),

  /**
   * Toggles the quote button column.
   */
  quotebutton: PropTypes.string,

  /**
   * Optional data that fills the component when it's used on a Template page. Overrides state and year parameters.
   */
  data: PropTypes.oneOfType([PropTypes.object, PropTypes.arrayOf(PropTypes.object)]),

  /**
   * Optional list used to transform the company label into an anchor for the company review page.
   */
  reviewPages: PropTypes.oneOfType([PropTypes.object, PropTypes.arrayOf(PropTypes.object)]),

  /**
   * Optional parameter that specify if the monthly column should appear.
   */
  showMonthlyValue: PropTypes.bool,

  /**
   * Optional parameter that specify if a description is going to appear for each element, only works for market-share
   */
  showDescription: PropTypes.oneOf(['true', 'false']),

  /**
   * Specify the region level of the data, market share only
   */
  regionLevel: PropTypes.oneOf(['national', 'state']),

  /**
   * Rows configuration to display on the component: number and text.
   */
  rows: PropTypes.object,

  /**
   * Bottom margin space defined for the component (pixels).
   */
  margin: PropTypes.object
};

PremiumRanking.defaultProps = {
  showMonthlyValue: false,
  showDescription: 'false'
};

export default PremiumRanking;
