import React from 'react';
import PropTypes from 'prop-types';
import { SpacingContainer } from './Spacing.style';

/**
 * Spacing component.
 *
 * This component would show a spacing container using the children element
 * Adding margin space to have a consistent design across different pages.
 */
const Spacing = ({ margin, children }) => (
  <SpacingContainer space={margin}>
    {children}
  </SpacingContainer>
);

Spacing.propTypes = {

  /**
   * Bottom margin space defined for the component (pixels).
   */
  margin: PropTypes.object,

  /**
   * Inner element of the spacing container.
   */
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.arrayOf(PropTypes.object)])
};

Spacing.defaultProps = {
  margin: {
    desktop: '40px',
    tablet: '20px',
    mobile: '20px'
  }
};

export default Spacing;
