import styled from 'styled-components';
import typography from './../../theme_config/typography';
import colors from './../../theme_config/colors';
import sizes from './../../theme_config/sizes';

export const ExpandCollapseContainer = styled.div`
  color: ${colors.text};

  div.container:last-child {
    border-style: solid none solid none;
  }
`;

export const SingleContainer = styled.div`
  border: 1px ${colors.formElementsBorder};
  border-style: solid none none none;
  padding: 15px 0;

  div.titleContent {
    display: flex;
    align-items: center;

    span {
      font-family: ${typography.fontFamilyHeaderBold};
      font-size: 20px;
      line-height: 26px;
      width: 96%;
    }

    svg {
      transform: ${props => props.displayStatus === 'none' ? 'rotate(90deg)' : 'rotate(270deg)'};
    }
  }

  div.titleContent:hover {
    cursor: pointer;

    svg > path {
      fill: ${colors.brandingElements};
    }
  }

  div.contentRow {
    display: ${props => props.displayStatus};
    font-family: ${typography.fontFamilyBase};
    font-size: 16px;
    line-height: 28px;
  }

  @media (max-width: ${sizes.tabletEnd}) {
    padding: 10px 0;

    div.titleContent > span {
      font-size: 16px;
      line-height: 22px;
    }

    div.titleContent > svg {
      height: 20px;
      width: 20px;
    }

    div.contentRow {
      font-size: 14px;
      line-height: 24px;
    }

  }
`;
