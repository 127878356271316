import { Playground, Props } from "docz";
import StaticTable from "../../../../../components/StaticTable/StaticTable";
import Icon from "../../../../../components/Icon/Icon.js";
import * as React from 'react';
export default {
  Playground,
  Props,
  StaticTable,
  Icon,
  React
};